export default `一yī
丁dīng
丂kǎo
七qī
丄shàng
丅xià
丆hǎn
万wàn
丈zhàng
三sān
上shàng
下xià
丌jī
不bù
与yǔ
丏miǎn
丐gài
丑chǒu
丒chǒu
专zhuān
且qiě
丕pī
世shì
丗shì
丘qiū
丙bǐng
业yè
丛cóng
东dōng
丝sī
丞chéng
丟diū
丠qiū
両liǎng
丢diū
丣yǒu
两liǎng
严yán
並bìng
丧sàng
丨gǔn
丩jiū
个gè
丫yā
丬qiáng
中zhōng
丮jǐ
丯jiè
丰fēng
丱guàn
串chuàn
丳chǎn
临lín
丵zhuó
丶zhǔ
丷bā
丸wán
丹dān
为wèi
主zhǔ
丼jǐng
丽lì
举jǔ
丿piě
乀fú
乁yí
乂yì
乃nǎi
乄wǔ
久jiǔ
乆jiǔ
乇tuō
么me
义yì
乊yī
之zhī
乌wū
乍zhà
乎hū
乏fá
乐lè
乑yín
乒pīng
乓pāng
乔qiáo
乕hǔ
乖guāi
乗chéng
乘chéng
乙yǐ
乚yǐn
乛ya
乜miē
九jiǔ
乞qǐ
也yě
习xí
乡xiāng
乢gài
乣jiǔ
乤xià
乥hù
书shū
乧dǒu
乨shǐ
乩jī
乪náng
乫jiā
乬jù
乭shí
乮mǎo
乯hū
买mǎi
乱luàn
乲zī
乳rǔ
乴xué
乵yǎn
乶fǔ
乷shā
乸nǎ
乹gān
乺suǒ
乻yú
乼cui
乽zhě
乾qián
乿zhì
亀guī
亁gān
亂luàn
亃lǐn
亄yì
亅jué
了le
亇ma
予yǔ
争zhēng
亊shì
事shì
二èr
亍chù
于yú
亏kuī
亐yú
云yún
互hù
亓qí
五wǔ
井jǐng
亖sì
亗suì
亘gèn
亙gèn
亚yà
些xiē
亜yà
亝qí
亞yà
亟jí
亠tóu
亡wáng
亢kàng
亣dà
交jiāo
亥hài
亦yì
产chǎn
亨hēng
亩mǔ
亪ye
享xiǎng
京jīng
亭tíng
亮liàng
亯xiǎng
亰jīng
亱yè
亲qīn
亳bó
亴yòu
亵xiè
亶dǎn
亷lián
亸duǒ
亹wěi
人rén
亻rén
亼jí
亽jí
亾wáng
亿yì
什shén
仁rén
仂lè
仃dīng
仄zè
仅jǐn
仆pū
仇chóu
仈bā
仉zhǎng
今jīn
介jiè
仌bīng
仍réng
从cóng
仏fó
仐sǎn
仑lún
仒bīng
仓cāng
仔zǎi
仕shì
他tā
仗zhàng
付fù
仙xiān
仚xiān
仛tuō
仜hóng
仝tóng
仞rèn
仟qiān
仠gǎn
仡gē
仢bó
代dài
令lìng
以yǐ
仦chào
仧cháng
仨sā
仩cháng
仪yí
仫mù
们men
仭rèn
仮fǎn
仯chào
仰yǎng
仱qián
仲zhòng
仳pǐ
仴wò
仵wǔ
件jiàn
价jià
仸yǎo
仹fēng
仺cāng
任rèn
仼wáng
份fèn
仾dī
仿fǎng
伀zhōng
企qǐ
伂pèi
伃yú
伄diào
伅dùn
伆wù
伇yì
伈xǐn
伉kàng
伊yī
伋jí
伌ài
伍wǔ
伎jì
伏fú
伐fá
休xiū
伒jìn
伓pī
伔dǎn
伕fū
伖tǎng
众zhòng
优yōu
伙huǒ
会huì
伛yǔ
伜cuì
伝yún
伞sǎn
伟wěi
传chuán
伡chē
伢yá
伣qiàn
伤shāng
伥chāng
伦lún
伧cāng
伨xùn
伩xìn
伪wěi
伫zhù
伬ze
伭xián
伮nǔ
伯bó
估gū
伱nǐ
伲nì
伳xiè
伴bàn
伵xù
伶líng
伷zhòu
伸shēn
伹qū
伺cì
伻bēng
似shì
伽gā
伾pī
伿yì
佀sì
佁yǐ
佂zhēng
佃diàn
佄hān
佅mài
但dàn
佇zhù
佈bù
佉qū
佊bǐ
佋zhāo
佌cǐ
位wèi
低dī
住zhù
佐zuǒ
佑yòu
佒yǎng
体tǐ
佔zhàn
何hé
佖bì
佗tuó
佘shé
余yú
佚yì
佛fú
作zuò
佝gōu
佞nìng
佟tóng
你nǐ
佡xiān
佢qú
佣yōng
佤wǎ
佥qiān
佦shi
佧kǎ
佨bāo
佩pèi
佪huí
佫hè
佬lǎo
佭xiáng
佮gé
佯yáng
佰bǎi
佱fǎ
佲mǐng
佳jiā
佴èr
併bìng
佶jí
佷hěn
佸huó
佹guǐ
佺quán
佻tiāo
佼jiǎo
佽cì
佾yì
使shǐ
侀xíng
侁shēn
侂tuō
侃kǎn
侄zhí
侅gāi
來lái
侇yí
侈chǐ
侉kuǎ
侊guāng
例lì
侌yīn
侍shì
侎mǐ
侏zhū
侐xù
侑yòu
侒ān
侓lù
侔móu
侕ér
侖lún
侗dòng
侘chà
侙chī
侚xùn
供gōng
侜zhōu
依yī
侞rú
侟cún
侠xiá
価sì
侢dài
侣lǚ
侤ta
侥jiǎo
侦zhēn
侧cè
侨qiáo
侩kuài
侪chái
侫nìng
侬nóng
侭jǐn
侮wǔ
侯hóu
侰jiǒng
侱chěng
侲zhèn
侳zuò
侴chǒu
侵qīn
侶lǚ
侷jú
侸shù
侹tǐng
侺shèn
侻tuì
侼bó
侽nán
侾xiāo
便biàn
俀tuǐ
俁yǔ
係xì
促cù
俄é
俅qiú
俆xú
俇guàng
俈kù
俉wǔ
俊jùn
俋yì
俌fǔ
俍liáng
俎zǔ
俏qiào
俐lì
俑yǒng
俒hùn
俓jìng
俔qiàn
俕sàn
俖pěi
俗sú
俘fú
俙xī
俚lǐ
俛fǔ
俜pīng
保bǎo
俞yú
俟qí
俠xiá
信xìn
俢xiū
俣yǔ
俤dì
俥chē
俦chóu
俧zhì
俨yǎn
俩liǎ
俪lì
俫lái
俬sī
俭jiǎn
修xiū
俯fǔ
俰huò
俱jù
俲xiào
俳pái
俴jiàn
俵biào
俶chù
俷fèi
俸fèng
俹yà
俺ǎn
俻bèi
俼yù
俽xīn
俾bǐ
俿hǔ
倀chāng
倁zhī
倂bìng
倃jiù
倄yáo
倅cuì
倆liǎ
倇wǎn
倈lái
倉cāng
倊zòng
個gè
倌guān
倍bèi
倎tiǎn
倏shū
倐shū
們men
倒dào
倓tán
倔jué
倕chuí
倖xìng
倗péng
倘tǎng
候hòu
倚yǐ
倛qī
倜tì
倝gàn
倞jìng
借jiè
倠suī
倡chàng
倢jié
倣fǎng
値zhí
倥kōng
倦juàn
倧zōng
倨jù
倩qiàn
倪ní
倫lún
倬zhuō
倭wō
倮luǒ
倯sōng
倰lèng
倱hùn
倲dōng
倳zì
倴bèn
倵wǔ
倶jù
倷nǎi
倸cǎi
倹jiǎn
债zhài
倻yē
值zhí
倽shà
倾qīng
倿nìng
偀yīng
偁chēng
偂qián
偃yǎn
偄ruǎn
偅zhòng
偆chǔn
假jiǎ
偈jì
偉wěi
偊yǔ
偋bìng
偌ruò
偍tí
偎wēi
偏piān
偐yàn
偑fēng
偒tǎng
偓wò
偔è
偕xié
偖chě
偗shěng
偘kǎn
偙dì
做zuò
偛chā
停tíng
偝bèi
偞xiè
偟huáng
偠yǎo
偡zhàn
偢chǒu
偣yān
偤yóu
健jiàn
偦xǔ
偧zhā
偨cī
偩fù
偪bī
偫zhì
偬zǒng
偭miǎn
偮jí
偯yǐ
偰xiè
偱xún
偲cāi
偳duān
側cè
偵zhēn
偶ǒu
偷tōu
偸tōu
偹bèi
偺zá
偻lóu
偼jié
偽wěi
偾fèn
偿cháng
傀guī
傁sǒu
傂zhì
傃sù
傄xiā
傅fù
傆yuàn
傇rǒng
傈lì
傉nù
傊yùn
傋jiǎng
傌mà
傍bàng
傎diān
傏táng
傐hào
傑jié
傒xī
傓shàn
傔qiàn
傕jué
傖cāng
傗chù
傘sǎn
備bèi
傚xiào
傛yǒng
傜yáo
傝tàn
傞suō
傟yǎng
傠fá
傡bìng
傢jiā
傣dǎi
傤zài
傥tǎng
傦gǔ
傧bīn
储chǔ
傩nuó
傪cān
傫lěi
催cuī
傭yōng
傮zāo
傯zǒng
傰bēng
傱sǒng
傲ào
傳chuán
傴yǔ
債zhài
傶zú
傷shāng
傸chuǎng
傹jìng
傺chì
傻shǎ
傼hàn
傽zhāng
傾qīng
傿yàn
僀dì
僁xiè
僂lóu
僃bèi
僄piào
僅jǐn
僆liàn
僇lù
僈mán
僉qiān
僊xiān
僋tàn
僌yíng
働dòng
僎zhuàn
像xiàng
僐shàn
僑qiáo
僒jiǒng
僓tuǐ
僔zǔn
僕pú
僖xī
僗láo
僘chǎng
僙guāng
僚liáo
僛qī
僜chēng
僝chán
僞wěi
僟jī
僠bō
僡huì
僢chuǎn
僣tiě
僤dàn
僥jiǎo
僦jiù
僧sēng
僨fèn
僩xiàn
僪jú
僫è
僬jiāo
僭jiàn
僮tóng
僯lìn
僰bó
僱gù
僲xiān
僳sù
僴xiàn
僵jiāng
僶mǐn
僷yè
僸jìn
價jià
僺qiào
僻pì
僼fēng
僽zhòu
僾ài
僿sài
儀yí
儁jùn
儂nóng
儃chán
億yì
儅dàng
儆jǐng
儇xuān
儈kuài
儉jiǎn
儊chù
儋dān
儌jiǎo
儍shǎ
儎zài
儏càn
儐bīn
儑án
儒rú
儓tái
儔chóu
儕chái
儖lán
儗nǐ
儘jǐn
儙qiàn
儚méng
儛wǔ
儜níng
儝qióng
儞nǐ
償cháng
儠liè
儡lěi
儢lǚ
儣kuǎng
儤bào
儥yù
儦biāo
儧zǎn
儨zhì
儩sì
優yōu
儫háo
儬qìng
儭chèn
儮lì
儯téng
儰wěi
儱lǒng
儲chǔ
儳chán
儴ráng
儵shū
儶huì
儷lì
儸luó
儹zǎn
儺nuó
儻tǎng
儼yǎn
儽léi
儾nàng
儿ér
兀wù
允yǔn
兂zān
元yuán
兄xiōng
充chōng
兆zhào
兇xiōng
先xiān
光guāng
兊duì
克kè
兌duì
免miǎn
兎tù
兏cháng
児ér
兑duì
兒ér
兓jīn
兔tù
兕sì
兖yǎn
兗yǎn
兘shǐ
党dǎng
兛qiān
兜dōu
兝fēn
兞máo
兟shēn
兠dōu
兢jīng
兣lǐ
兤huǎng
入rù
兦wáng
內nèi
全quán
兩liǎng
兪yú
八bā
公gōng
六liù
兮xī
兯han
兰lán
共gòng
兲tiān
关guān
兴xīng
兵bīng
其qí
具jù
典diǎn
兹zī
兺fēn
养yǎng
兼jiān
兽shòu
兾jì
兿yì
冀jì
冁chǎn
冂jiōng
冃mào
冄rǎn
内nèi
円yuán
冇mǎo
冈gāng
冉rǎn
冊cè
冋jiōng
册cè
再zài
冎guǎ
冏jiǒng
冐mào
冑zhòu
冒mào
冓gòu
冔xǔ
冕miǎn
冖mì
冗rǒng
冘yín
写xiě
冚kǎn
军jūn
农nóng
冝yí
冞mí
冟shì
冠guān
冡méng
冢zhǒng
冣jù
冤yuān
冥míng
冦kòu
冧lín
冨fù
冩xiě
冪mì
冫bīng
冬dōng
冭tài
冮gāng
冯féng
冰bīng
冱hù
冲chōng
决jué
冴hù
况kuàng
冶yě
冷lěng
冸pàn
冹fú
冺mǐn
冻dòng
冼xiǎn
冽liè
冾qià
冿jiān
净jìng
凁sōu
凂měi
凃tú
凄qī
凅gù
准zhǔn
凇sōng
凈jìng
凉liáng
凊qìng
凋diāo
凌líng
凍dòng
凎gàn
减jiǎn
凐yīn
凑còu
凒ái
凓lì
凔chuàng
凕mǐng
凖zhǔn
凗cuī
凘sī
凙duó
凚jìn
凛lǐn
凜lǐn
凝níng
凞xī
凟dú
几jǐ
凡fán
凢fán
凣fán
凤fèng
凥jū
処chǔ
凧zhēng
凨fēng
凩mù
凪zhǐ
凫fú
凬fēng
凭píng
凮fēng
凯kǎi
凰huáng
凱kǎi
凲gān
凳dèng
凴píng
凵qiǎn
凶xiōng
凷kuài
凸tū
凹āo
出chū
击jī
凼dàng
函hán
凾hán
凿záo
刀dāo
刁diāo
刂dāo
刃rèn
刄rèn
刅chuāng
分fēn
切qiè
刈yì
刉jī
刊kān
刋qiàn
刌cǔn
刍chú
刎wěn
刏jī
刐dǎn
刑xíng
划huà
刓wán
刔jué
刕lí
刖yuè
列liè
刘liú
则zé
刚gāng
创chuàng
刜fú
初chū
刞qù
刟diāo
删shān
刡mǐn
刢líng
刣zhōng
判pàn
別bié
刦jié
刧jié
刨páo
利lì
刪shān
别bié
刬chǎn
刭jǐng
刮guā
刯gēng
到dào
刱chuàng
刲kuī
刳kū
刴duò
刵èr
制zhì
刷shuā
券quàn
刹shā
刺cì
刻kè
刼jié
刽guì
刾cì
刿guì
剀kǎi
剁duò
剂jì
剃tì
剄jǐng
剅lóu
剆luǒ
則zé
剈yuān
剉cuò
削xuē
剋kè
剌lá
前qián
剎shā
剏chuàng
剐guǎ
剑jiàn
剒cuò
剓lí
剔tī
剕fèi
剖pōu
剗chǎn
剘qí
剙chuàng
剚zì
剛gāng
剜wān
剝bō
剞jī
剟duō
剠qíng
剡shàn
剢dū
剣jiàn
剤jì
剥bō
剦yān
剧jù
剨huō
剩shèng
剪jiǎn
剫duó
剬duān
剭wū
剮guǎ
副fù
剰shèng
剱jiàn
割gē
剳dá
剴kǎi
創chuàng
剶chuān
剷chǎn
剸tuán
剹lù
剺lí
剻pěng
剼shān
剽piāo
剾kōu
剿jiǎo
劀guā
劁qiāo
劂jué
劃huà
劄zhā
劅zhuó
劆lián
劇jù
劈pī
劉liú
劊guì
劋jiǎo
劌guì
劍jiàn
劎jiàn
劏tāng
劐huō
劑jì
劒jiàn
劓yì
劔jiàn
劕zhì
劖chán
劗jiǎn
劘mó
劙lí
劚zhú
力lì
劜yà
劝quàn
办bàn
功gōng
加jiā
务wù
劢mài
劣liè
劤jìn
劥kēng
劦xié
劧zhǐ
动dòng
助zhù
努nǔ
劫jié
劬qú
劭shào
劮yì
劯zhū
劰mò
励lì
劲jìn
劳láo
労láo
劵juàn
劶kǒu
劷yáng
劸wā
効xiào
劺móu
劻kuāng
劼jié
劽liè
劾hé
势shì
勀kè
勁jìn
勂gào
勃bó
勄mǐn
勅chì
勆láng
勇yǒng
勈yǒng
勉miǎn
勊kè
勋xūn
勌juàn
勍qíng
勎lù
勏bù
勐měng
勑chì
勒lēi
勓kài
勔miǎn
動dòng
勖xù
勗xù
勘kān
務wù
勚yì
勛xūn
勜wěng
勝shèng
勞láo
募mù
勠lù
勡piào
勢shì
勣jī
勤qín
勥jiàng
勦chāo
勧quàn
勨xiàng
勩yì
勪jué
勫fān
勬juān
勭tóng
勮jù
勯dān
勰xié
勱mài
勲xūn
勳xūn
勴lǜ
勵lì
勶chè
勷ráng
勸quàn
勹bāo
勺sháo
勻yún
勼jiū
勽bào
勾gōu
勿wù
匀yún
匁wén
匂xiōng
匃gài
匄gài
包bāo
匆cōng
匇yì
匈xiōng
匉pēng
匊jū
匋táo
匌gé
匍pú
匎è
匏páo
匐fú
匑gōng
匒dá
匓jiù
匔gōng
匕bǐ
化huà
北běi
匘nǎo
匙shi
匚fāng
匛jiù
匜yí
匝zā
匞jiàng
匟kàng
匠jiàng
匡kuāng
匢hū
匣xiá
匤qū
匥fán
匦guǐ
匧qiè
匨zāng
匩kuāng
匪fěi
匫hū
匬yǔ
匭guǐ
匮kuì
匯huì
匰dān
匱guì
匲lián
匳lián
匴suǎn
匵dú
匶jiù
匷jué
匸xì
匹pǐ
区qū
医yī
匼kē
匽yǎn
匾biǎn
匿nì
區qū
十shí
卂xùn
千qiān
卄niàn
卅sà
卆zú
升shēng
午wǔ
卉huì
半bàn
卋shì
卌xì
卍wàn
华huá
协xié
卐wàn
卑bēi
卒zú
卓zhuó
協xié
单dān
卖mài
南nán
単dān
卙jí
博bó
卛shuài
卜bo
卝kuàng
卞biàn
卟bǔ
占zhàn
卡kǎ
卢lú
卣yǒu
卤lǔ
卥xī
卦guà
卧wò
卨xiè
卩jié
卪jié
卫wèi
卬áng
卭qióng
卮zhī
卯mǎo
印yìn
危wēi
卲shào
即jí
却què
卵luǎn
卶chǐ
卷juǎn
卸xiè
卹xù
卺jǐn
卻què
卼wù
卽jí
卾è
卿qīng
厀xī
厁sān
厂chǎng
厃wěi
厄è
厅tīng
历lì
厇zhé
厈hǎn
厉lì
厊yǎ
压yā
厌yàn
厍shè
厎dǐ
厏zhǎ
厐páng
厑yá
厒qiè
厓yá
厔zhì
厕cè
厖páng
厗tí
厘lí
厙shè
厚hòu
厛tīng
厜zuī
厝cuò
厞fèi
原yuán
厠cè
厡yuán
厢xiāng
厣yǎn
厤lì
厥jué
厦shà
厧diān
厨chú
厩jiù
厪jǐn
厫áo
厬guǐ
厭yàn
厮sī
厯lì
厰chǎng
厱lán
厲lì
厳yán
厴yǎn
厵yuán
厶sī
厷gōng
厸lín
厹róu
厺qù
去qù
厼ěr
厽lěi
厾dū
县xiàn
叀zhuān
叁sān
参cān
參cān
叄cān
叅cān
叆ài
叇dài
又yòu
叉chā
及jí
友yǒu
双shuāng
反fǎn
収shōu
叏guài
叐bá
发fā
叒ruò
叓shì
叔shū
叕zhuó
取qǔ
受shòu
变biàn
叙xù
叚jiǎ
叛pàn
叜sǒu
叝jí
叞wèi
叟sǒu
叠dié
叡ruì
叢cóng
口kǒu
古gǔ
句jù
另lìng
叧guǎ
叨dāo
叩kòu
只zhǐ
叫jiào
召zhào
叭bā
叮dīng
可kě
台tái
叱chì
史shǐ
右yòu
叴qiú
叵pǒ
叶yè
号hào
司sī
叹tàn
叺chǐ
叻lè
叼diāo
叽jī
叾liǎo
叿hōng
吀miē
吁xū
吂máng
吃chī
各gè
吅xuān
吆yāo
吇zǐ
合hé
吉jí
吊diào
吋cùn
同tóng
名míng
后hòu
吏lì
吐tǔ
向xiàng
吒zhā
吓xià
吔yě
吕lǚ
吖yā
吗ma
吘ǒu
吙huō
吚yī
君jūn
吜chǒu
吝lìn
吞tūn
吟yín
吠fèi
吡bǐ
吢qìn
吣qìn
吤jiè
吥bù
否fǒu
吧ba
吨dūn
吩fēn
吪é
含hán
听tīng
吭kēng
吮shǔn
启qǐ
吰hóng
吱zhī
吲yǐn
吳wú
吴wú
吵chǎo
吶nà
吷xuè
吸xī
吹chuī
吺dōu
吻wěn
吼hǒu
吽hōng
吾wú
吿gào
呀ya
呁jùn
呂lǚ
呃è
呄gé
呅méi
呆dāi
呇qǐ
呈chéng
呉wú
告gào
呋fū
呌jiào
呍hōng
呎chǐ
呏shēng
呐nà
呑tūn
呒wǔ
呓yì
呔dāi
呕ǒu
呖lì
呗bei
员yuán
呙guō
呚wen
呛qiāng
呜wū
呝è
呞shī
呟juǎn
呠pěn
呡wěn
呢ne
呣ḿ
呤lìng
呥rán
呦yōu
呧dǐ
周zhōu
呩shì
呪zhòu
呫tiè
呬xì
呭yì
呮qì
呯píng
呰zǐ
呱gū
呲cī
味wèi
呴xǔ
呵hē
呶náo
呷gā
呸pēi
呹yì
呺xiāo
呻shēn
呼hū
命mìng
呾dá
呿qù
咀jǔ
咁hán
咂zā
咃tuō
咄duō
咅pǒu
咆páo
咇bié
咈fú
咉yāng
咊hé
咋zǎ
和hé
咍hāi
咎jiù
咏yǒng
咐fù
咑dā
咒zhòu
咓wǎ
咔kā
咕gū
咖kā
咗zuo
咘bù
咙lóng
咚dōng
咛níng
咜ta
咝sī
咞xiàn
咟huò
咠qì
咡èr
咢è
咣guāng
咤zhà
咥xì
咦yí
咧liě
咨zī
咩miē
咪mī
咫zhǐ
咬yǎo
咭jī
咮zhòu
咯gē
咰shù
咱zán
咲xiào
咳ké
咴huī
咵kuǎ
咶huài
咷táo
咸xián
咹è
咺xuǎn
咻xiū
咼guō
咽yàn
咾lǎo
咿yī
哀āi
品pǐn
哂shěn
哃tóng
哄hǒng
哅xiōng
哆duō
哇wa
哈hā
哉zāi
哊yòu
哋diè
哌pài
响xiǎng
哎āi
哏gén
哐kuāng
哑yǎ
哒dá
哓xiāo
哔bì
哕huì
哖nián
哗huā
哘xing
哙kuài
哚duǒ
哛fēn
哜jì
哝nóng
哞mōu
哟yō
哠hào
員yuán
哢lòng
哣pǒu
哤máng
哥gē
哦ó
哧chī
哨shào
哩lī
哪nǎ
哫zú
哬hé
哭kū
哮xiāo
哯xiàn
哰láo
哱bō
哲zhé
哳zhā
哴liàng
哵bā
哶miē
哷liè
哸suī
哹fú
哺bǔ
哻hān
哼hēng
哽gěng
哾shuō
哿gě
唀yòu
唁yàn
唂gū
唃gǔ
唄bei
唅hán
唆suō
唇chún
唈yì
唉āi
唊jiá
唋tū
唌xián
唍wǎn
唎lì
唏xī
唐táng
唑zuò
唒qiú
唓chē
唔wú
唕zào
唖yǎ
唗dōu
唘qǐ
唙dí
唚qìn
唛mà
唜mò
唝gòng
唞dǒu
唟qù
唠láo
唡liǎng
唢suǒ
唣zào
唤huàn
唥lang
唦shā
唧jī
唨zǔ
唩wō
唪fěng
唫jìn
唬hǔ
唭qì
售shòu
唯wéi
唰shuā
唱chàng
唲ér
唳lì
唴qiàng
唵ǎn
唶zé
唷yō
唸niàn
唹yū
唺tiǎn
唻lài
唼shà
唽xī
唾tuò
唿hū
啀ái
啁zhāo
啂nǒu
啃kěn
啄zhuó
啅zhuó
商shāng
啇dì
啈hēng
啉lín
啊a
啋cǎi
啌xiāng
啍tūn
啎wǔ
問wèn
啐cuì
啑shà
啒gǔ
啓qǐ
啔qǐ
啕táo
啖dàn
啗dàn
啘yè
啙zǐ
啚bǐ
啛cuì
啜chuài
啝hé
啞yǎ
啟qǐ
啠zhé
啡fēi
啢liǎng
啣xián
啤pí
啥shá
啦la
啧zé
啨yīng
啩guà
啪pā
啫zhě
啬sè
啭zhuàn
啮niè
啯guō
啰luō
啱yán
啲dī
啳quán
啴chǎn
啵bō
啶dìng
啷lāng
啸xiào
啹jú
啺táng
啻chì
啼tí
啽án
啾jiū
啿dàn
喀kā
喁yóng
喂wèi
喃nán
善shàn
喅yù
喆zhé
喇lǎ
喈jiē
喉hóu
喊hǎn
喋dié
喌zhōu
喍chái
喎wāi
喏nuò
喐yù
喑yīn
喒zá
喓yāo
喔ō
喕miǎn
喖hú
喗yǔn
喘chuǎn
喙huì
喚huàn
喛huàn
喜xǐ
喝hē
喞jī
喟kuì
喠zhǒng
喡wéi
喢shà
喣xǔ
喤huáng
喥duó
喦niè
喧xuān
喨liàng
喩yù
喪sàng
喫chī
喬qiáo
喭yàn
單dān
喯pèn
喰cān
喱lí
喲yō
喳zhā
喴wēi
喵miāo
営yíng
喷pēn
喸bǔ
喹kuí
喺xí
喻yù
喼jiē
喽lóu
喾kù
喿zào
嗀hù
嗁tí
嗂yáo
嗃hè
嗄á
嗅xiù
嗆qiāng
嗇sè
嗈yōng
嗉sù
嗊hǒng
嗋xié
嗌ài
嗍suō
嗎ma
嗏chā
嗐hài
嗑kē
嗒dā
嗓sǎng
嗔chēn
嗕rù
嗖sōu
嗗wā
嗘jī
嗙pǎng
嗚wū
嗛qiǎn
嗜shì
嗝gé
嗞zī
嗟jiē
嗠lào
嗡wēng
嗢wà
嗣sì
嗤chī
嗥háo
嗦suo
嗨hāi
嗩suǒ
嗪qín
嗫niè
嗬hē
嗭zhí
嗮sài
嗯ń
嗰gě
嗱ná
嗲diē
嗳āi
嗴qiāng
嗵tōng
嗶bì
嗷áo
嗸áo
嗹lián
嗺zuī
嗻zhē
嗼mò
嗽sòu
嗾sǒu
嗿tǎn
嘀dí
嘁qī
嘂jiào
嘃chōng
嘄jiāo
嘅kǎi
嘆tàn
嘇shān
嘈cáo
嘉jiā
嘊ái
嘋xiào
嘌piào
嘍lóu
嘎gā
嘏gǔ
嘐xiāo
嘑hū
嘒huì
嘓guō
嘔ǒu
嘕xiān
嘖zé
嘗cháng
嘘xū
嘙pó
嘚dē
嘛ma
嘜mà
嘝hú
嘞lei
嘟dū
嘠gā
嘡tāng
嘢yě
嘣bēng
嘤yīng
嘥sāi
嘦jiào
嘧mì
嘨xiào
嘩huā
嘪mǎi
嘫rán
嘬chuài
嘭pēng
嘮láo
嘯xiào
嘰jī
嘱zhǔ
嘲cháo
嘳kuì
嘴zuǐ
嘵xiāo
嘶sī
嘷háo
嘸fǔ
嘹liáo
嘺qiáo
嘻xī
嘼chù
嘽chǎn
嘾dàn
嘿hēi
噀xùn
噁ě
噂zǔn
噃fān
噄chī
噅huī
噆zǎn
噇chuáng
噈cù
噉dàn
噊yù
噋tūn
噌cēng
噍jiào
噎yē
噏xī
噐qì
噑háo
噒lián
噓xū
噔dēng
噕huī
噖yín
噗pū
噘juē
噙qín
噚xún
噛niè
噜lū
噝sī
噞yǎn
噟yìng
噠dā
噡zhān
噢ō
噣zhòu
噤jìn
噥nóng
噦yuě
噧xiè
器qì
噩è
噪zào
噫yī
噬shì
噭jiào
噮yuàn
噯āi
噰yōng
噱jué
噲kuài
噳yǔ
噴pēn
噵dào
噶gá
噷hm
噸dūn
噹dāng
噺xīn
噻sāi
噼pī
噽pǐ
噾yīn
噿zuǐ
嚀níng
嚁dí
嚂làn
嚃tā
嚄huō
嚅rú
嚆hāo
嚇xià
嚈yè
嚉duō
嚊pì
嚋chóu
嚌jì
嚍jìn
嚎háo
嚏tì
嚐cháng
嚑xūn
嚒mē
嚓cā
嚔tì
嚕lǔ
嚖huì
嚗bó
嚘yōu
嚙niè
嚚yín
嚛hù
嚜me
嚝hōng
嚞zhé
嚟lí
嚠liú
嚡hai
嚢náng
嚣xiāo
嚤mó
嚥yàn
嚦lì
嚧lú
嚨lóng
嚩mó
嚪dàn
嚫chèn
嚬pín
嚭pǐ
嚮xiàng
嚯huò
嚰mó
嚱xì
嚲duǒ
嚳kù
嚴yán
嚵chán
嚶yīng
嚷rǎng
嚸diǎn
嚹lá
嚺tà
嚻xiāo
嚼jué
嚽chuò
嚾huān
嚿huò
囀zhuàn
囁niè
囂xiāo
囃cà
囄lí
囅chǎn
囆chài
囇lì
囈yì
囉luō
囊náng
囋zá
囌sū
囍xǐ
囎zen
囏jiān
囐zá
囑zhǔ
囒lán
囓niè
囔nāng
囕lǎn
囖lo
囗wéi
囘huí
囙yīn
囚qiú
四sì
囜nín
囝jiǎn
回huí
囟xìn
因yīn
囡nān
团tuán
団tuán
囤dùn
囥kàng
囦yuān
囧jiǒng
囨piān
囩yún
囪cōng
囫hú
囬huí
园yuán
囮é
囯guó
困kùn
囱cōng
囲tōng
図tú
围wéi
囵lún
囶guó
囷qūn
囸rì
囹líng
固gù
囻guó
囼tāi
国guó
图tú
囿yòu
圀guó
圁yín
圂hùn
圃pǔ
圄yǔ
圅hán
圆yuán
圇lún
圈quān
圉yǔ
圊qīng
國guó
圌chuán
圍wéi
圎yuán
圏quān
圐kū
圑pǔ
園yuán
圓yuán
圔yà
圕tú
圖tú
圗tú
團tuán
圙lüè
圚huì
圛yì
圜huán
圝luán
圞luán
土tǔ
圠yà
圡tǔ
圢tǐng
圣shèng
圤pú
圥lù
圦kuài
圧yā
在zài
圩wéi
圪gē
圫yù
圬wū
圭guī
圮pǐ
圯yí
地dì
圱qiān
圲qiān
圳zhèn
圴zhuó
圵dàng
圶qià
圷xià
圸shān
圹kuàng
场chǎng
圻qí
圼niè
圽mò
圾jī
圿jiá
址zhǐ
坁zhǐ
坂bǎn
坃xūn
坄yì
坅qǐn
坆méi
均jūn
坈rǒng
坉tún
坊fāng
坋bèn
坌bèn
坍tān
坎kǎn
坏huài
坐zuò
坑kēng
坒bì
坓jǐng
坔dì
坕jīng
坖jì
块kuài
坘dǐ
坙jīng
坚jiān
坛tán
坜lì
坝bà
坞wù
坟fén
坠zhuì
坡pō
坢bàn
坣táng
坤kūn
坥qū
坦tǎn
坧zhī
坨tuó
坩gān
坪píng
坫diàn
坬guà
坭ní
坮tái
坯pī
坰jiōng
坱yǎng
坲fó
坳ào
坴lù
坵qiū
坶mǔ
坷kě
坸gòu
坹xuè
坺bá
坻chí
坼chè
坽líng
坾zhù
坿fù
垀hū
垁zhì
垂chuí
垃lā
垄lǒng
垅lǒng
垆lú
垇ào
垈dài
垉páo
垊min
型xíng
垌dòng
垍jì
垎hè
垏lǜ
垐cí
垑chǐ
垒lěi
垓gāi
垔yīn
垕hòu
垖duī
垗zhào
垘fú
垙guāng
垚yáo
垛duǒ
垜duǒ
垝guǐ
垞chá
垟yáng
垠yín
垡fá
垢gòu
垣yuán
垤dié
垥xié
垦kěn
垧shǎng
垨shǒu
垩è
垪bìng
垫diàn
垬hóng
垭yā
垮kuǎ
垯da
垰kǎ
垱dàng
垲kǎi
垳háng
垴nǎo
垵ǎn
垶xīng
垷xiàn
垸yuàn
垹bāng
垺fū
垻bà
垼yì
垽yìn
垾hàn
垿xù
埀chuí
埁qín
埂gěng
埃āi
埄běng
埅fáng
埆què
埇yǒng
埈jùn
埉jiā
埊dì
埋mái
埌làng
埍juǎn
城chéng
埏shān
埐jīn
埑zhé
埒liè
埓liè
埔pǔ
埕chéng
埖huā
埗bù
埘shí
埙xūn
埚guō
埛jiōng
埜yě
埝niàn
埞dī
域yù
埠bù
埡yā
埢quán
埣suì
埤pí
埥qīng
埦wǎn
埧jù
埨lǔn
埩zhēng
埪kōng
埫chǒng
埬dōng
埭dài
埮tàn
埯ǎn
埰cài
埱chù
埲běng
埳kǎn
埴zhí
埵duǒ
埶yì
執zhí
埸yì
培péi
基jī
埻zhǔn
埼qí
埽sào
埾jù
埿ní
堀kū
堁kè
堂táng
堃kūn
堄nì
堅jiān
堆duī
堇jǐn
堈gāng
堉yù
堊è
堋péng
堌gù
堍tù
堎lèng
堏fang
堐yá
堑qiàn
堒kūn
堓àn
堔shēn
堕duò
堖nǎo
堗tū
堘chéng
堙yīn
堚hún
堛bì
堜liàn
堝guō
堞dié
堟zhuàn
堠hòu
堡bǎo
堢bǎo
堣yú
堤dī
堥máo
堦jiē
堧ruán
堨yè
堩gèng
堪kān
堫zōng
堬yú
堭huáng
堮è
堯yáo
堰yàn
報bào
堲cí
堳méi
場chǎng
堵dǔ
堶tuó
堷yìn
堸féng
堹zhòng
堺jiè
堻jīn
堼hèng
堽gāng
堾chūn
堿jiǎn
塀píng
塁lěi
塂xiàng
塃huāng
塄léng
塅duàn
塆wān
塇xuān
塈jì
塉jí
塊kuài
塋yíng
塌tā
塍chéng
塎yǒng
塏kǎi
塐sù
塑sù
塒shí
塓mì
塔tǎ
塕wěng
塖chéng
塗tú
塘táng
塙què
塚zhǒng
塛lì
塜zhǒng
塝bàng
塞sāi
塟zàng
塠duī
塡tián
塢wù
塣zhèng
塤xūn
塥gé
塦zhèn
塧ài
塨gōng
塩yán
塪kǎn
填tián
塬yuán
塭wēn
塮xiè
塯liù
塰hǎi
塱lǎng
塲cháng
塳péng
塴bèng
塵chén
塶lù
塷lǔ
塸ōu
塹qiàn
塺méi
塻mò
塼zhuān
塽shuǎng
塾shú
塿lǒu
墀chí
墁màn
墂biāo
境jìng
墄cè
墅shù
墆zhì
墇zhàng
墈kàn
墉yōng
墊diàn
墋chěn
墌zhí
墍xì
墎guō
墏qiǎng
墐jìn
墑dì
墒shāng
墓mù
墔cuī
墕yàn
墖tǎ
増zēng
墘qián
墙qiáng
墚liáng
墛wèi
墜zhuì
墝qiāo
增zēng
墟xū
墠shàn
墡shàn
墢bá
墣pú
墤kuài
墥dǒng
墦fán
墧què
墨mò
墩dūn
墪dūn
墫zūn
墬dì
墭shèng
墮duò
墯duò
墰tán
墱dèng
墲mú
墳fén
墴huáng
墵tán
墶da
墷yè
墸zhù
墹jiàn
墺ào
墻qiáng
墼jī
墽qiāo
墾kěn
墿yì
壀pí
壁bì
壂diàn
壃jiāng
壄yě
壅yōng
壆xué
壇tán
壈lǎn
壉jù
壊huài
壋dàng
壌rǎng
壍qiàn
壎xūn
壏xiàn
壐xǐ
壑hè
壒ài
壓yā
壔dǎo
壕háo
壖ruán
壗jìn
壘lěi
壙kuàng
壚lú
壛yán
壜tán
壝wěi
壞huài
壟lǒng
壠lǒng
壡ruì
壢lì
壣lín
壤rǎng
壥chán
壦xūn
壧yán
壨léi
壩bà
壪wān
士shì
壬rén
壭san
壮zhuàng
壯zhuàng
声shēng
壱yī
売mài
壳ké
壴zhù
壵zhuàng
壶hú
壷hú
壸kǔn
壹yī
壺hú
壻xù
壼kǔn
壽shòu
壾mǎng
壿zūn
夀shòu
夁yī
夂zhǐ
夃gǔ
处chù
夅jiàng
夆féng
备bèi
夈zhāi
変biàn
夊suī
夋qūn
夌líng
复fù
夎cuò
夏xià
夐xiòng
夑xiè
夒náo
夓xià
夔kuí
夕xī
外wài
夗yuàn
夘mǎo
夙sù
多duō
夛duō
夜yè
夝qíng
夞wài
够gòu
夠gòu
夡qì
夢mèng
夣mèng
夤yín
夥huǒ
夦chěn
大dà
夨zè
天tiān
太tài
夫fū
夬guài
夭yāo
央yāng
夯hāng
夰gǎo
失shī
夲tāo
夳tài
头tóu
夵yǎn
夶bǐ
夷yí
夸kuā
夹jiā
夺duó
夻huà
夼kuǎng
夽yǔn
夾jiā
夿bā
奀ēn
奁lián
奂huàn
奃dī
奄yǎn
奅pào
奆juàn
奇qí
奈nài
奉fèng
奊xié
奋fèn
奌diǎn
奍quān
奎kuí
奏zòu
奐huàn
契qì
奒kāi
奓zhā
奔bēn
奕yì
奖jiǎng
套tào
奘zàng
奙běn
奚xī
奛huǎng
奜fěi
奝diāo
奞xùn
奟bēng
奠diàn
奡ào
奢shē
奣wěng
奤hǎ
奥ào
奦wù
奧ào
奨jiǎng
奩lián
奪duó
奫yūn
奬jiǎng
奭shì
奮fèn
奯huò
奰bì
奱luán
奲duǒ
女nǚ
奴nú
奵dǐng
奶nǎi
奷qiān
奸jiān
她tā
奺jiǔ
奻nuán
奼chà
好hǎo
奾xiān
奿fàn
妀jǐ
妁shuò
如rú
妃fēi
妄wàng
妅hóng
妆zhuāng
妇fù
妈mā
妉dān
妊rèn
妋fū
妌jìng
妍yán
妎hài
妏wèn
妐zhōng
妑pā
妒dù
妓jì
妔kēng
妕zhòng
妖yāo
妗jìn
妘yún
妙miào
妚fǒu
妛chī
妜yuè
妝zhuāng
妞niū
妟yàn
妠nà
妡xīn
妢fén
妣bǐ
妤yú
妥tuǒ
妦fēng
妧wàn
妨fáng
妩wǔ
妪yù
妫guī
妬dù
妭bá
妮nī
妯zhóu
妰zhuó
妱zhāo
妲dá
妳nǐ
妴yuàn
妵tǒu
妶xián
妷zhí
妸ē
妹mèi
妺mò
妻qī
妼bì
妽shēn
妾qiè
妿ē
姀hé
姁xǔ
姂fá
姃zhēng
姄mín
姅bàn
姆mǔ
姇fū
姈líng
姉zǐ
姊zǐ
始shǐ
姌rǎn
姍shān
姎yāng
姏mán
姐jiě
姑gū
姒sì
姓xìng
委wěi
姕zī
姖jù
姗shān
姘pīn
姙rèn
姚yáo
姛dòng
姜jiāng
姝shū
姞jí
姟gāi
姠xiàng
姡huá
姢juān
姣jiāo
姤gòu
姥lǎo
姦jiān
姧jiān
姨yí
姩niàn
姪zhí
姫jī
姬jī
姭xiàn
姮héng
姯guāng
姰jūn
姱kuā
姲yàn
姳mǐng
姴liè
姵pèi
姶è
姷yòu
姸yán
姹chà
姺shēn
姻yīn
姼shí
姽guǐ
姾quán
姿zī
娀sōng
威wēi
娂hóng
娃wá
娄lóu
娅yà
娆ráo
娇jiāo
娈luán
娉pīng
娊xiàn
娋shào
娌lǐ
娍chéng
娎xiè
娏máng
娐fū
娑suō
娒méi
娓wěi
娔kè
娕chuò
娖chuò
娗tǐng
娘niáng
娙xíng
娚nán
娛yú
娜nà
娝pōu
娞něi
娟juān
娠shēn
娡zhì
娢hán
娣dì
娤zhuāng
娥é
娦pín
娧tuì
娨xiàn
娩miǎn
娪wú
娫yán
娬wǔ
娭āi
娮yán
娯yú
娰sì
娱yú
娲wā
娳lì
娴xián
娵jū
娶qǔ
娷zhuì
娸qī
娹xián
娺zhuó
娻dōng
娼chāng
娽lù
娾ǎi
娿ē
婀ē
婁lóu
婂mián
婃cóng
婄pǒu
婅jú
婆pó
婇cǎi
婈líng
婉wǎn
婊biǎo
婋xiāo
婌shú
婍qǐ
婎huī
婏fàn
婐wǒ
婑ruí
婒tán
婓fēi
婔fēi
婕jié
婖tiān
婗ní
婘quán
婙jìng
婚hūn
婛jīng
婜qiān
婝diàn
婞xìng
婟hù
婠wān
婡lái
婢bì
婣yīn
婤chōu
婥nào
婦fù
婧jìng
婨lún
婩àn
婪lán
婫kūn
婬yín
婭yà
婮jū
婯lì
婰diǎn
婱xián
婲huā
婳huà
婴yīng
婵chán
婶shěn
婷tíng
婸dàng
婹yǎo
婺wù
婻nàn
婼chuò
婽jiǎ
婾tōu
婿xù
媀yù
媁wéi
媂dì
媃róu
媄měi
媅dān
媆ruǎn
媇qīn
媈huī
媉wò
媊qián
媋chūn
媌miáo
媍fù
媎jiě
媏duān
媐yí
媑zhòng
媒méi
媓huáng
媔mián
媕ān
媖yīng
媗xuān
媘jiē
媙wēi
媚mèi
媛yuàn
媜zhēng
媝qiū
媞shì
媟xiè
媠tuǒ
媡liàn
媢mào
媣rǎn
媤sī
媥piān
媦wèi
媧wā
媨cù
媩hú
媪ǎo
媫jié
媬bǎo
媭xū
媮tōu
媯guī
媰chú
媱yáo
媲pì
媳xí
媴yuán
媵yìng
媶róng
媷rù
媸chī
媹liú
媺měi
媻pán
媼ǎo
媽mā
媾gòu
媿kuì
嫀qín
嫁jià
嫂sǎo
嫃zhēn
嫄yuán
嫅jiē
嫆róng
嫇míng
嫈yīng
嫉jí
嫊sù
嫋niǎo
嫌xián
嫍tāo
嫎páng
嫏láng
嫐nǎo
嫑báo
嫒ài
嫓pì
嫔pín
嫕yì
嫖piáo
嫗yù
嫘léi
嫙xuán
嫚mān
嫛yī
嫜zhāng
嫝kāng
嫞yōng
嫟nì
嫠lí
嫡dí
嫢guī
嫣yān
嫤jǐn
嫥zhuān
嫦cháng
嫧zé
嫨hān
嫩nèn
嫪lào
嫫mó
嫬zhē
嫭hù
嫮hù
嫯ào
嫰nèn
嫱qiáng
嫲ma
嫳piè
嫴gū
嫵wǔ
嫶qiáo
嫷tuǒ
嫸zhǎn
嫹miáo
嫺xián
嫻xián
嫼mò
嫽liáo
嫾lián
嫿huà
嬀guī
嬁dēng
嬂zhí
嬃xū
嬄yī
嬅huà
嬆xī
嬇kuì
嬈ráo
嬉xī
嬊yàn
嬋chán
嬌jiāo
嬍měi
嬎fàn
嬏fān
嬐xiān
嬑yì
嬒huì
嬓jiào
嬔fù
嬕shì
嬖bì
嬗shàn
嬘suì
嬙qiáng
嬚liǎn
嬛huán
嬜xīn
嬝niǎo
嬞dǒng
嬟yì
嬠cān
嬡ài
嬢niáng
嬣níng
嬤mā
嬥tiǎo
嬦chóu
嬧jìn
嬨cí
嬩yú
嬪pín
嬫róng
嬬rú
嬭nǎi
嬮yān
嬯tái
嬰yīng
嬱qiàn
嬲niǎo
嬳yuè
嬴yíng
嬵mián
嬶bí
嬷mā
嬸shěn
嬹xìng
嬺nì
嬻dú
嬼liǔ
嬽yuān
嬾lǎn
嬿yàn
孀shuāng
孁líng
孂jiǎo
孃niáng
孄lǎn
孅qiān
孆yīng
孇shuāng
孈huì
孉quán
孊mǐ
孋lí
孌luán
孍yán
孎zhú
孏lǎn
子zi
孑jié
孒jué
孓jué
孔kǒng
孕yùn
孖mā
字zì
存cún
孙sūn
孚fú
孛bèi
孜zī
孝xiào
孞xìn
孟mèng
孠sì
孡tāi
孢bāo
季jì
孤gū
孥nú
学xué
孧yòu
孨zhuǎn
孩hái
孪luán
孫sūn
孬nāo
孭miē
孮cóng
孯qiān
孰shú
孱càn
孲yā
孳zī
孴nǐ
孵fū
孶zī
孷lí
學xué
孹bò
孺rú
孻nái
孼niè
孽niè
孾yīng
孿luán
宀mián
宁níng
宂rǒng
它tā
宄guǐ
宅zhái
宆qióng
宇yǔ
守shǒu
安ān
宊tū
宋sòng
完wán
宍ròu
宎yǎo
宏hóng
宐yí
宑jǐng
宒zhūn
宓mì
宔zhǔ
宕dàng
宖hóng
宗zōng
官guān
宙zhòu
定dìng
宛wǎn
宜yí
宝bǎo
实shí
実shí
宠chǒng
审shěn
客kè
宣xuān
室shì
宥yòu
宦huàn
宧yí
宨tiǎo
宩shǐ
宪xiàn
宫gōng
宬chéng
宭qún
宮gōng
宯xiāo
宰zǎi
宱zhà
宲bǎo
害hài
宴yàn
宵xiāo
家jiā
宷shěn
宸chén
容róng
宺huǎng
宻mì
宼kòu
宽kuān
宾bīn
宿sù
寀cǎi
寁zǎn
寂jì
寃yuān
寄jì
寅yín
密mì
寇kòu
寈qīng
寉hè
寊zhēn
寋jiàn
富fù
寍níng
寎bìng
寏huán
寐mèi
寑qǐn
寒hán
寓yù
寔shí
寕níng
寖jìn
寗níng
寘zhì
寙yǔ
寚bǎo
寛kuān
寜níng
寝qǐn
寞mò
察chá
寠jù
寡guǎ
寢qǐn
寣hū
寤wù
寥liáo
實shí
寧níng
寨zhài
審shěn
寪wěi
寫xiě
寬kuān
寭huì
寮liáo
寯jùn
寰huán
寱yì
寲yí
寳bǎo
寴qīn
寵chǒng
寶bǎo
寷fēng
寸cùn
对duì
寺sì
寻xún
导dǎo
寽lǜ
対duì
寿shòu
尀pǒ
封fēng
専zhuān
尃fū
射shè
尅kè
将jiāng
將jiāng
專zhuān
尉wèi
尊zūn
尋xún
尌shù
對duì
導dǎo
小xiǎo
尐jié
少shǎo
尒ěr
尓ěr
尔ěr
尕gǎ
尖jiān
尗shū
尘chén
尙shàng
尚shàng
尛mó
尜gá
尝cháng
尞liào
尟xiǎn
尠xiǎn
尡kun
尢yóu
尣wāng
尤yóu
尥liào
尦liào
尧yáo
尨máng
尩wāng
尪wāng
尫wāng
尬gà
尭yáo
尮duò
尯kuì
尰zhǒng
就jiù
尲gān
尳gǔ
尴gān
尵tuí
尶gān
尷gān
尸shī
尹yǐn
尺chǐ
尻kāo
尼ní
尽jǐn
尾wěi
尿niào
局jú
屁pì
层céng
屃xì
屄bī
居jū
屆jiè
屇tián
屈qū
屉tì
届jiè
屋wū
屌diǎo
屍shī
屎shǐ
屏píng
屐jī
屑xiè
屒zhěn
屓xiè
屔ní
展zhǎn
屖xī
屗wěi
屘mǎn
屙ē
屚lòu
屛píng
屜tì
屝fèi
属shǔ
屟xiè
屠tú
屡lǚ
屢lǚ
屣xǐ
層céng
履lǚ
屦jù
屧xiè
屨jù
屩juē
屪liáo
屫jué
屬shǔ
屭xì
屮chè
屯tún
屰nì
山shān
屲wā
屳xiān
屴lì
屵è
屶huì
屷huì
屸lóng
屹yì
屺qǐ
屻rèn
屼wù
屽hàn
屾shēn
屿yǔ
岀chū
岁suì
岂qǐ
岃rèn
岄yuè
岅bǎn
岆yǎo
岇áng
岈yá
岉wù
岊jié
岋è
岌jí
岍qiān
岎fén
岏wán
岐qí
岑cén
岒qián
岓qí
岔chà
岕jiè
岖qū
岗gǎng
岘xiàn
岙ào
岚lán
岛dǎo
岜bā
岝zuò
岞zuò
岟yǎng
岠jù
岡gāng
岢kě
岣gǒu
岤xué
岥pō
岦lì
岧tiáo
岨qū
岩yán
岪fú
岫xiù
岬jiǎ
岭lǐng
岮tuó
岯pí
岰ào
岱dài
岲kuàng
岳yuè
岴qū
岵hù
岶pò
岷mín
岸àn
岹tiáo
岺líng
岻chí
岼píng
岽dōng
岾hàn
岿kuī
峀xiù
峁mǎo
峂tóng
峃xué
峄yì
峅biàn
峆hé
峇bā
峈luò
峉è
峊fù
峋xún
峌dié
峍lù
峎ěn
峏ér
峐gāi
峑quān
峒dòng
峓yí
峔mǔ
峕shí
峖ān
峗wéi
峘huán
峙zhì
峚mì
峛lǐ
峜jì
峝tóng
峞wéi
峟yòu
峠qiǎ
峡xiá
峢lǐ
峣yáo
峤jiào
峥zhēng
峦luán
峧jiāo
峨é
峩é
峪yù
峫xié
峬bū
峭qiào
峮qūn
峯fēng
峰fēng
峱náo
峲lǐ
峳yóu
峴xiàn
峵róng
島dǎo
峷shēn
峸chéng
峹tú
峺gěng
峻jùn
峼gào
峽xiá
峾yín
峿yǔ
崀làng
崁kàn
崂láo
崃lái
崄xiǎn
崅què
崆kōng
崇chóng
崈chóng
崉tà
崊lín
崋huà
崌jū
崍lái
崎qí
崏mín
崐kūn
崑kūn
崒zú
崓gù
崔cuī
崕yá
崖yá
崗gǎng
崘lún
崙lún
崚léng
崛jué
崜duō
崝zhēng
崞guō
崟yín
崠dōng
崡hán
崢zhēng
崣wěi
崤xiáo
崥pí
崦yān
崧sōng
崨jié
崩bēng
崪zú
崫kū
崬dōng
崭zhǎn
崮gù
崯yín
崰zī
崱zè
崲huáng
崳yú
崴wǎi
崵yáng
崶fēng
崷qiú
崸yáng
崹tí
崺yǐ
崻zhì
崼shì
崽zǎi
崾yǎo
崿è
嵀zhù
嵁kān
嵂lǜ
嵃yǎn
嵄měi
嵅hán
嵆jī
嵇jī
嵈huàn
嵉tíng
嵊shèng
嵋méi
嵌qiàn
嵍wù
嵎yú
嵏zōng
嵐lán
嵑kě
嵒yán
嵓yán
嵔wěi
嵕zōng
嵖chá
嵗suì
嵘róng
嵙kē
嵚qīn
嵛yú
嵜qí
嵝lǒu
嵞tú
嵟duī
嵠xī
嵡wěng
嵢cāng
嵣dàng
嵤róng
嵥jié
嵦kǎi
嵧liú
嵨wù
嵩sōng
嵪qiāo
嵫zī
嵬wéi
嵭bēng
嵮diān
嵯cuó
嵰qiǎn
嵱yǒng
嵲niè
嵳cuó
嵴jǐ
嵵shí
嵶ruò
嵷sǒng
嵸zōng
嵹jiàng
嵺liáo
嵻kāng
嵼chǎn
嵽dié
嵾cēn
嵿dǐng
嶀tū
嶁lǒu
嶂zhàng
嶃zhǎn
嶄zhǎn
嶅áo
嶆cáo
嶇qū
嶈qiāng
嶉cuī
嶊zuǐ
嶋dǎo
嶌dǎo
嶍xí
嶎yù
嶏pèi
嶐lóng
嶑xiàng
嶒céng
嶓bō
嶔qīn
嶕jiāo
嶖yān
嶗láo
嶘zhàn
嶙lín
嶚liáo
嶛liáo
嶜jīn
嶝dèng
嶞duò
嶟zūn
嶠jiào
嶡guì
嶢yáo
嶣jiāo
嶤yáo
嶥jué
嶦zhān
嶧yì
嶨xué
嶩náo
嶪yè
嶫yè
嶬yí
嶭niè
嶮xiǎn
嶯jí
嶰xiè
嶱kě
嶲xī
嶳dì
嶴ào
嶵zuǐ
嶶wēi
嶷yí
嶸róng
嶹dǎo
嶺lǐng
嶻jié
嶼yǔ
嶽yuè
嶾yǐn
嶿ru
巀jié
巁lì
巂guī
巃lóng
巄lóng
巅diān
巆róng
巇xī
巈jú
巉chán
巊yǐng
巋kuī
巌yán
巍wēi
巎náo
巏quán
巐chǎo
巑cuán
巒luán
巓diān
巔diān
巕niè
巖yán
巗yán
巘yǎn
巙kuí
巚yǎn
巛chuān
巜kuài
川chuān
州zhōu
巟huāng
巠jīng
巡xún
巢cháo
巣cháo
巤liè
工gōng
左zuǒ
巧qiǎo
巨jù
巩gǒng
巪jù
巫wū
巬pu
巭pu
差chà
巯qiú
巰qiú
己jǐ
已yǐ
巳sì
巴bā
巵zhī
巶zhāo
巷xiàng
巸yí
巹jǐn
巺xùn
巻juàn
巼bā
巽xùn
巾jīn
巿fú
帀zā
币bì
市shì
布bù
帄dīng
帅shuài
帆fān
帇niè
师shī
帉fēn
帊pà
帋zhǐ
希xī
帍hù
帎dàn
帏wéi
帐zhàng
帑tǎng
帒dài
帓mò
帔pèi
帕pà
帖tiē
帗bō
帘lián
帙zhì
帚zhǒu
帛bó
帜zhì
帝dì
帞mò
帟yì
帠yì
帡píng
帢qià
帣juǎn
帤rú
帥shuài
带dài
帧zhèng
帨shuì
帩qiào
帪zhēn
師shī
帬qún
席xí
帮bāng
帯dài
帰guī
帱chóu
帲píng
帳zhàng
帴sàn
帵wān
帶dài
帷wéi
常cháng
帹shà
帺qí
帻zé
帼guó
帽mào
帾dǔ
帿hóu
幀zhèng
幁xū
幂mì
幃wéi
幄wò
幅fú
幆yì
幇bāng
幈píng
幉dié
幊gōng
幋pán
幌huǎng
幍tāo
幎mì
幏jià
幐téng
幑huī
幒zhōng
幓shān
幔màn
幕mù
幖biāo
幗guó
幘zé
幙mù
幚bāng
幛zhàng
幜jǐng
幝chǎn
幞fú
幟zhì
幠hū
幡fān
幢chuáng
幣bì
幤bì
幥zhǎng
幦mì
幧qiāo
幨chān
幩fén
幪méng
幫bāng
幬chóu
幭miè
幮chú
幯jié
幰xiǎn
幱lán
干gàn
平píng
年nián
幵jiān
并bìng
幷bìng
幸xìng
幹gàn
幺yāo
幻huàn
幼yòu
幽yōu
幾jǐ
广guǎng
庀pǐ
庁tīng
庂zè
広guǎng
庄zhuāng
庅mó
庆qìng
庇bì
庈qín
庉dùn
床chuáng
庋guǐ
庌yǎ
庍bài
庎jiè
序xù
庐lú
庑wǔ
庒zhuāng
库kù
应yīng
底dǐ
庖páo
店diàn
庘yā
庙miào
庚gēng
庛cì
府fǔ
庝tóng
庞páng
废fèi
庠xiáng
庡yǐ
庢zhì
庣tiāo
庤zhì
庥xiū
度dù
座zuò
庨xiāo
庩tú
庪guǐ
庫kù
庬máng
庭tíng
庮yǒu
庯bū
庰bìng
庱chěng
庲lái
庳bì
庴jí
庵ān
庶shù
康kāng
庸yōng
庹tuǒ
庺sōng
庻shù
庼qǐng
庽yù
庾yǔ
庿miào
廀sōu
廁cè
廂xiāng
廃fèi
廄jiù
廅è
廆guī
廇liù
廈shà
廉lián
廊láng
廋sōu
廌zhì
廍bù
廎qǐng
廏jiù
廐jiù
廑jǐn
廒áo
廓kuò
廔lóu
廕yìn
廖liào
廗dài
廘lù
廙yì
廚chú
廛chán
廜tú
廝sī
廞xīn
廟miào
廠chǎng
廡wǔ
廢fèi
廣guǎng
廤kù
廥kuài
廦bì
廧qiáng
廨xiè
廩lǐn
廪lǐn
廫liáo
廬lú
廭jì
廮yǐng
廯xiān
廰tīng
廱yōng
廲lí
廳tīng
廴yǐn
廵xún
延yán
廷tíng
廸dí
廹pǎi
建jiàn
廻huí
廼nǎi
廽huí
廾gǒng
廿niàn
开kāi
弁biàn
异yì
弃qì
弄nòng
弅fèn
弆jǔ
弇yǎn
弈yì
弉zàng
弊bì
弋yì
弌yī
弍èr
弎sān
式shì
弐èr
弑shì
弒shì
弓gōng
弔diào
引yǐn
弖hù
弗fú
弘hóng
弙wū
弚tuí
弛chí
弜jiàng
弝bà
弞shěn
弟dì
张zhāng
弡jué
弢tāo
弣fǔ
弤dǐ
弥mí
弦xián
弧hú
弨chāo
弩nǔ
弪jìng
弫zhěn
弬yí
弭mǐ
弮quān
弯wān
弰shāo
弱ruò
弲xuān
弳jìng
弴diāo
張zhāng
弶jiàng
強qiáng
弸péng
弹dàn
强qiáng
弻bì
弼bì
弽shè
弾dàn
弿jiǎn
彀gòu
彁gē
彂fā
彃bì
彄kōu
彅jiǎn
彆biè
彇xiāo
彈dàn
彉guō
彊jiàng
彋hóng
彌mí
彍guō
彎wān
彏jué
彐jì
彑jì
归guī
当dāng
彔lù
录lù
彖tuàn
彗huì
彘zhì
彙huì
彚huì
彛yí
彜yí
彝yí
彞yí
彟yuē
彠yuē
彡shān
形xíng
彣wén
彤tóng
彥yàn
彦yàn
彧yù
彨chī
彩cǎi
彪biāo
彫diāo
彬bīn
彭péng
彮yǒng
彯piāo
彰zhāng
影yǐng
彲chī
彳chì
彴zhuó
彵tuǒ
彶jí
彷páng
彸zhōng
役yì
彺wáng
彻chè
彼bǐ
彽dī
彾líng
彿fú
往wǎng
征zhēng
徂cú
徃wǎng
径jìng
待dài
徆xī
徇xùn
很hěn
徉yáng
徊huái
律lǜ
後hòu
徍wǎng
徎chěng
徏zhì
徐xú
徑jìng
徒tú
従cóng
徔zhi
徕lái
徖cóng
得dé
徘pái
徙xǐ
徚dōng
徛jì
徜cháng
徝zhì
從cóng
徟zhōu
徠lái
御yù
徢xiè
徣jiè
徤jiàn
徥shì
徦jiǎ
徧biàn
徨huáng
復fù
循xún
徫wěi
徬páng
徭yáo
微wēi
徯xī
徰zhēng
徱piào
徲tí
徳dé
徴zhēng
徵zhēng
徶bié
德dé
徸chōng
徹chè
徺jiǎo
徻huì
徼jiǎo
徽huī
徾méi
徿lòng
忀xiāng
忁bào
忂qú
心xīn
忄xin
必bì
忆yì
忇lè
忈rén
忉dāo
忊dìng
忋gǎi
忌jì
忍rěn
忎rén
忏chàn
忐tǎn
忑tè
忒tè
忓gān
忔qì
忕shì
忖cǔn
志zhì
忘wàng
忙máng
忚xī
忛fān
応yīng
忝tiǎn
忞mín
忟wěn
忠zhōng
忡chōng
忢wù
忣jí
忤wǔ
忥xì
忦jiá
忧yōu
忨wàn
忩cōng
忪sōng
快kuài
忬yù
忭biàn
忮zhì
忯qí
忰cuì
忱chén
忲tài
忳tún
忴qián
念niàn
忶hún
忷xiōng
忸niǔ
忹kuáng
忺xiān
忻xīn
忼kāng
忽hū
忾kài
忿fèn
怀huái
态tài
怂sǒng
怃wǔ
怄òu
怅chàng
怆chuàng
怇jù
怈yì
怉bǎo
怊chāo
怋mín
怌pēi
怍zuò
怎zěn
怏yàng
怐jù
怑bàn
怒nù
怓náo
怔zhēng
怕pà
怖bù
怗tiē
怘hù
怙hù
怚jù
怛dá
怜lián
思sī
怞chóu
怟dì
怠dài
怡yí
怢tū
怣yóu
怤fū
急jí
怦pēng
性xìng
怨yuàn
怩ní
怪guài
怫fú
怬xì
怭bì
怮yōu
怯qiè
怰xuàn
怱cōng
怲bǐng
怳huǎng
怴xù
怵chù
怶bì
怷shù
怸xī
怹tān
怺yǒng
总zǒng
怼duì
怽mo
怾zhǐ
怿yì
恀shì
恁nèn
恂xún
恃shì
恄xì
恅lǎo
恆héng
恇kuāng
恈móu
恉zhǐ
恊xié
恋liàn
恌tiāo
恍huǎng
恎dié
恏hào
恐kǒng
恑guǐ
恒héng
恓xī
恔jiǎo
恕shù
恖sī
恗hū
恘qiū
恙yàng
恚huì
恛huí
恜chì
恝jiá
恞yí
恟xiōng
恠guài
恡lìn
恢huī
恣zì
恤xù
恥chǐ
恦shàng
恧nǜ
恨hèn
恩ēn
恪kè
恫dòng
恬tián
恭gōng
恮quān
息xī
恰qià
恱yuè
恲pēng
恳kěn
恴dé
恵huì
恶è
恷xiao
恸tòng
恹yān
恺kǎi
恻cè
恼nǎo
恽yùn
恾máng
恿yǒng
悀yǒng
悁yuān
悂pī
悃kǔn
悄qiāo
悅yuè
悆yù
悇tú
悈jiè
悉xī
悊zhé
悋lìn
悌tì
悍hàn
悎hào
悏qiè
悐tì
悑bù
悒yì
悓qiàn
悔huǐ
悕xī
悖bèi
悗mán
悘yī
悙hēng
悚sǒng
悛quān
悜chěng
悝kuī
悞wù
悟wù
悠yōu
悡lí
悢liàng
患huàn
悤cōng
悥yì
悦yuè
悧lì
您nín
悩nǎo
悪è
悫què
悬xuán
悭qiān
悮wù
悯mǐn
悰cóng
悱fěi
悲bēi
悳dé
悴cuì
悵chàng
悶mèn
悷lì
悸jì
悹guàn
悺guàn
悻xìng
悼dào
悽qī
悾kōng
悿tiǎn
惀lún
惁xī
惂kǎn
惃gǔn
惄nì
情qíng
惆chóu
惇dūn
惈guǒ
惉zhān
惊jīng
惋wǎn
惌yuān
惍jīn
惎jì
惏lán
惐yù
惑huò
惒hé
惓quán
惔tán
惕tì
惖tì
惗niè
惘wǎng
惙chuò
惚hū
惛hūn
惜xī
惝chǎng
惞xīn
惟wéi
惠huì
惡è
惢suǒ
惣zǒng
惤jiān
惥yǒng
惦diàn
惧jù
惨cǎn
惩chéng
惪dé
惫bèi
惬qiè
惭cán
惮dàn
惯guàn
惰duò
惱nǎo
惲yùn
想xiǎng
惴zhuì
惵dié
惶huáng
惷chǔn
惸qióng
惹rě
惺xīng
惻cè
惼biǎn
惽mǐn
惾zōng
惿tí
愀qiǎo
愁chóu
愂bèi
愃xuān
愄wēi
愅gé
愆qiān
愇wěi
愈yù
愉yú
愊bì
愋xuān
愌huàn
愍mǐn
愎bì
意yì
愐miǎn
愑yǒng
愒kài
愓dàng
愔yīn
愕è
愖chén
愗mào
愘qià
愙kè
愚yú
愛ài
愜qiè
愝yǎn
愞nuò
感gǎn
愠yùn
愡zǒng
愢sāi
愣lèng
愤fèn
愥yīng
愦kuì
愧kuì
愨què
愩gōng
愪yún
愫sù
愬sù
愭qí
愮yáo
愯sǒng
愰huàng
愱jí
愲gǔ
愳jù
愴chuàng
愵nì
愶xié
愷kǎi
愸zhěng
愹yǒng
愺cǎo
愻xùn
愼shèn
愽bó
愾kài
愿yuàn
慀xì
慁hùn
慂yǒng
慃yǎng
慄lì
慅sāo
慆tāo
慇yīn
慈cí
慉xù
慊qiàn
態tài
慌huāng
慍yùn
慎shèn
慏mǐng
慐gong
慑shè
慒cóng
慓piāo
慔mù
慕mù
慖guó
慗chì
慘cǎn
慙cán
慚cán
慛cuī
慜mǐn
慝tè
慞zhāng
慟tòng
慠ào
慡shuǎng
慢màn
慣guàn
慤què
慥zào
慦jiù
慧huì
慨kǎi
慩lián
慪òu
慫sǒng
慬qín
慭yìn
慮lǜ
慯shāng
慰wèi
慱tuán
慲mán
慳qiān
慴shè
慵yōng
慶qìng
慷kāng
慸dì
慹zhí
慺lóu
慻juàn
慼qī
慽qī
慾yù
慿píng
憀liáo
憁còng
憂yōu
憃chōng
憄zhì
憅tòng
憆chēng
憇qì
憈qū
憉péng
憊bèi
憋biē
憌qióng
憍jiāo
憎zēng
憏chì
憐lián
憑píng
憒kuì
憓huì
憔qiáo
憕chéng
憖yìn
憗yìn
憘xǐ
憙xī
憚dàn
憛tán
憜duò
憝duì
憞duì
憟sù
憠jué
憡cè
憢xiāo
憣fān
憤fèn
憥láo
憦lào
憧chōng
憨hān
憩qì
憪xián
憫mǐn
憬jǐng
憭liǎo
憮wǔ
憯cǎn
憰jué
憱cù
憲xiàn
憳tǎn
憴shéng
憵pī
憶yì
憷chù
憸xiān
憹náo
憺dàn
憻tǎn
憼jǐng
憽sōng
憾hàn
憿jiǎo
懀wèi
懁xuān
懂dǒng
懃qín
懄qín
懅jù
懆cǎo
懇kěn
懈xiè
應yīng
懊ào
懋mào
懌yì
懍lǐn
懎sè
懏jùn
懐huái
懑mèn
懒lǎn
懓ài
懔lǐn
懕yān
懖kuò
懗xià
懘chì
懙yǔ
懚yìn
懛dāi
懜měng
懝ài
懞méng
懟duì
懠qí
懡mǒ
懢lán
懣mèn
懤chóu
懥zhì
懦nuò
懧nuò
懨yān
懩yǎng
懪bó
懫zhì
懬kuàng
懭kuǎng
懮yǒu
懯fū
懰liú
懱miè
懲chéng
懳hui
懴chàn
懵měng
懶lǎn
懷huái
懸xuán
懹ràng
懺chàn
懻jì
懼jù
懽huān
懾shè
懿yì
戀liàn
戁nǎn
戂mí
戃tǎng
戄jué
戅gàng
戆gàng
戇zhuàng
戈gē
戉yuè
戊wù
戋jiān
戌xū
戍shù
戎róng
戏xì
成chéng
我wǒ
戒jiè
戓gē
戔jiān
戕qiāng
或huò
戗qiāng
战zhàn
戙dòng
戚qī
戛jiá
戜dié
戝zéi
戞jiá
戟jǐ
戠zhī
戡kān
戢jí
戣kuí
戤gài
戥děng
戦zhàn
戧qiāng
戨gē
戩jiǎn
截jié
戫yù
戬jiǎn
戭yǎn
戮lù
戯hū
戰zhàn
戱xì
戲xì
戳chuō
戴dài
戵qú
戶hù
户hù
戸hù
戹è
戺shì
戻tì
戼mǎo
戽hù
戾lì
房fáng
所suǒ
扁biǎn
扂diàn
扃jiōng
扄shǎng
扅yí
扆yǐ
扇shàn
扈hù
扉fēi
扊yǎn
手shǒu
扌shou
才cái
扎zhā
扏qiú
扐lè
扑pū
扒bā
打dǎ
扔rēng
払fǎn
扖rù
扗zài
托tuō
扙zhàng
扚diǎo
扛káng
扜yū
扝kū
扞gǎn
扟shēn
扠chā
扡tuō
扢gǔ
扣kòu
扤wù
扥dèn
扦qiān
执zhí
扨rèn
扩kuò
扪mén
扫sǎo
扬yáng
扭niǔ
扮bàn
扯chě
扰rǎo
扱xī
扲qián
扳bān
扴jiá
扵yú
扶fú
扷ào
扸xī
批pī
扺zhǐ
扻zhì
扼è
扽dèn
找zhǎo
承chéng
技jì
抁yǎn
抂kuáng
抃biàn
抄chāo
抅jū
抆wěn
抇hú
抈yuè
抉jué
把bǎ
抋qìn
抌dǎn
抍zhěng
抎yǔn
抏wán
抐nè
抑yì
抒shū
抓zhuā
抔póu
投tóu
抖dǒu
抗kàng
折zhé
抙póu
抚fǔ
抛pāo
抜bá
抝ǎo
択zé
抟tuán
抠kōu
抡lūn
抢qiǎng
抣yun
护hù
报bào
抦bǐng
抧zhǐ
抨pēng
抩nán
抪bù
披pī
抬tái
抭yǎo
抮zhěn
抯zhā
抰yāng
抱bào
抲hē
抳nǐ
抴yè
抵dǐ
抶chì
抷pī
抸jiā
抹mǒ
抺mèi
抻chēn
押yā
抽chōu
抾qū
抿mǐn
拀chù
拁jiā
拂fú
拃zhǎ
拄zhǔ
担dān
拆chāi
拇mǔ
拈niān
拉lā
拊fǔ
拋pāo
拌bàn
拍pāi
拎līn
拏ná
拐guǎi
拑qián
拒jù
拓tuò
拔bá
拕tuō
拖tuō
拗ǎo
拘jū
拙zhuō
拚pàn
招zhāo
拜bài
拝bài
拞dǐ
拟nǐ
拠jù
拡kuò
拢lǒng
拣jiǎn
拤qiá
拥yōng
拦lán
拧níng
拨bō
择zé
拪qiān
拫hén
括kuò
拭shì
拮jié
拯zhěng
拰nǐn
拱gǒng
拲gǒng
拳quán
拴shuān
拵cún
拶zā
拷kǎo
拸yí
拹xié
拺cè
拻huī
拼pīn
拽zhuāi
拾shí
拿ná
挀bāi
持chí
挂guà
挃zhì
挄kuò
挅duǒ
挆duǒ
指zhǐ
挈qiè
按àn
挊nòng
挋zhèn
挌gé
挍jiào
挎kuà
挏dòng
挐ná
挑tiāo
挒liè
挓zhā
挔lǚ
挕dié
挖wā
挗jué
挘liě
挙jǔ
挚zhì
挛luán
挜yà
挝wō
挞tà
挟xié
挠náo
挡dǎng
挢jiǎo
挣zhēng
挤jǐ
挥huī
挦xián
挧yǔ
挨āi
挩tuō
挪nuó
挫cuò
挬bó
挭gěng
挮tǐ
振zhèn
挰chéng
挱sā
挲sā
挳kēng
挴měi
挵nòng
挶jū
挷péng
挸jiǎn
挹yì
挺tǐng
挻shān
挼ruá
挽wǎn
挾xié
挿chā
捀féng
捁jiǎo
捂wǔ
捃jùn
捄jiù
捅tǒng
捆kǔn
捇huò
捈tú
捉zhuō
捊póu
捋lǚ
捌bā
捍hàn
捎shāo
捏niē
捐juān
捑zè
捒shù
捓yé
捔jué
捕bǔ
捖wán
捗bù
捘zùn
捙yè
捚zhāi
捛lǚ
捜sōu
捝tuō
捞lāo
损sǔn
捠bāng
捡jiǎn
换huàn
捣dǎo
捤wěi
捥wàn
捦qín
捧pěng
捨shě
捩liè
捪mín
捫mén
捬fǔ
捭bǎi
据jù
捯dáo
捰wǒ
捱ái
捲juǎn
捳yuè
捴zǒng
捵chēn
捶chuí
捷jié
捸tū
捹bèn
捺nà
捻niǎn
捼ruó
捽zuó
捾wò
捿qī
掀xiān
掁chéng
掂diān
掃sǎo
掄lūn
掅qìng
掆gāng
掇duō
授shòu
掉diào
掊póu
掋dǐ
掌zhǎng
掍hùn
掎jǐ
掏tāo
掐qiā
掑qí
排pái
掓shū
掔qiān
掕líng
掖yē
掗yà
掘jué
掙zhēng
掚liǎng
掛guà
掜yì
掝huò
掞shàn
掟zhěng
掠lüè
採cǎi
探tàn
掣chè
掤bīng
接jiē
掦tì
控kòng
推tuī
掩yǎn
措cuò
掫zhōu
掬jū
掭tiàn
掮qián
掯kèn
掰bāi
掱pá
掲jiē
掳lǔ
掴guāi
掵ming
掶jié
掷zhì
掸dǎn
掹meng
掺càn
掻sāo
掼guàn
掽pèng
掾yuàn
掿nuò
揀jiǎn
揁zhēng
揂jiū
揃jiǎn
揄yú
揅yán
揆kuí
揇nǎn
揈hōng
揉róu
揊pì
揋wēi
揌sāi
揍zòu
揎xuān
描miáo
提tí
揑niē
插chā
揓shì
揔zǒng
揕zhèn
揖yī
揗xún
揘yóng
揙biān
揚yáng
換huàn
揜yǎn
揝zǎn
揞ǎn
揟xū
揠yà
握wò
揢ké
揣chuāi
揤jí
揥tì
揦lá
揧là
揨chén
揩kāi
揪jiū
揫jiū
揬tú
揭jiē
揮huī
揯gèn
揰chòng
揱xiāo
揲dié
揳xiē
援yuán
揵qián
揶yé
揷chā
揸zhā
揹bēi
揺yáo
揻wēi
揼beng
揽lǎn
揾wèn
揿qìn
搀chān
搁gē
搂lǒu
搃zǒng
搄gèn
搅jiǎo
搆gòu
搇qìn
搈róng
搉què
搊chōu
搋chuāi
搌zhǎn
損sǔn
搎sūn
搏bó
搐chù
搑róng
搒bàng
搓cuō
搔sāo
搕kē
搖yáo
搗dǎo
搘zhī
搙nù
搚lā
搛jiān
搜sōu
搝qiǔ
搞gǎo
搟xiǎn
搠shuò
搡sǎng
搢jìn
搣miè
搤è
搥chuí
搦nuò
搧shān
搨tà
搩zhǎ
搪táng
搫pán
搬bān
搭dā
搮lì
搯tāo
搰hú
搱zhì
搲wā
搳huá
搴qiān
搵wèn
搶qiǎng
搷tián
搸zhēn
搹è
携xié
搻nuò
搼quán
搽chá
搾zhà
搿gé
摀wǔ
摁èn
摂shè
摃káng
摄shè
摅shū
摆bǎi
摇yáo
摈bìn
摉sōu
摊tān
摋sà
摌chǎn
摍suō
摎jiū
摏chōng
摐chuāng
摑guāi
摒bǐng
摓féng
摔shuāi
摕dì
摖qì
摗sōu
摘zhāi
摙liǎn
摚chēng
摛chī
摜guàn
摝lù
摞luò
摟lǒu
摠zǒng
摡gài
摢hù
摣zhā
摤chuǎng
摥tàng
摦huà
摧cuī
摨nái
摩mó
摪jiāng
摫guī
摬yǐng
摭zhí
摮áo
摯zhì
摰niè
摱màn
摲chàn
摳kōu
摴chū
摵shè
摶tuán
摷jiǎo
摸mō
摹mó
摺zhé
摻càn
摼kēng
摽biāo
摾jiàng
摿yáo
撀gòu
撁qiān
撂liào
撃jī
撄yīng
撅juē
撆piē
撇piē
撈lāo
撉dūn
撊xiàn
撋ruán
撌guì
撍zǎn
撎yì
撏xián
撐chēng
撑chēng
撒sā
撓náo
撔hòng
撕sī
撖hàn
撗guàng
撘dā
撙zǔn
撚niǎn
撛lǐn
撜zhěng
撝huī
撞zhuàng
撟jiǎo
撠jǐ
撡cāo
撢dǎn
撣dǎn
撤chè
撥bō
撦chě
撧juē
撨fǔ
撩liāo
撪bèn
撫fǔ
撬qiào
播bō
撮cuō
撯zhuó
撰zhuàn
撱wěi
撲pū
撳qìn
撴dūn
撵niǎn
撶huá
撷xié
撸lū
撹jiǎo
撺cuān
撻tà
撼hàn
撽qiào
撾wō
撿jiǎn
擀gǎn
擁yōng
擂léi
擃nǎng
擄lǔ
擅shàn
擆zhuó
擇zé
擈pū
擉chuò
擊jī
擋dǎng
擌sè
操cāo
擎qíng
擏qíng
擐huàn
擑jiē
擒qín
擓kuǎi
擔dān
擕xié
擖kā
擗pǐ
擘bāi
擙ào
據jù
擛yè
擜è
擝mēng
擞sǒu
擟mí
擠jǐ
擡tái
擢zhuó
擣dǎo
擤xǐng
擥lǎn
擦cā
擧jǔ
擨yé
擩rǔ
擪yè
擫yè
擬nǐ
擭wò
擮jié
擯bìn
擰níng
擱gē
擲zhì
擳zhì
擴kuò
擵mó
擶jiàn
擷xié
擸liè
擹tān
擺bǎi
擻sǒu
擼lǔ
擽lüè
擾rǎo
擿tī
攀pān
攁yǎng
攂lèi
攃cā
攄shū
攅zǎn
攆niǎn
攇xiǎn
攈jùn
攉huō
攊lì
攋là
攌huǎn
攍yíng
攎lú
攏lǒng
攐qiān
攑qiān
攒zǎn
攓qiān
攔lán
攕xiān
攖yīng
攗méi
攘rǎng
攙chān
攚wěng
攛cuān
攜xié
攝shè
攞luó
攟jùn
攠mí
攡chī
攢zǎn
攣luán
攤tān
攥zuàn
攦lì
攧diān
攨wā
攩dǎng
攪jiǎo
攫jué
攬lǎn
攭lì
攮nǎng
支zhī
攰guì
攱guǐ
攲qī
攳xún
攴pū
攵pū
收shōu
攷kǎo
攸yōu
改gǎi
攺yǐ
攻gōng
攼gān
攽bān
放fàng
政zhèng
敀pò
敁diān
敂kòu
敃mǐn
敄wù
故gù
敆hé
敇cè
效xiào
敉mǐ
敊chù
敋gé
敌dí
敍xù
敎jiào
敏mǐn
敐chén
救jiù
敒shēn
敓duó
敔yǔ
敕chì
敖áo
敗bài
敘xù
教jiào
敚duó
敛liǎn
敜niè
敝bì
敞chǎng
敟diǎn
敠duō
敡yì
敢gǎn
散sàn
敤kě
敥yàn
敦dūn
敧jī
敨tǒu
敩xiào
敪duō
敫jiǎo
敬jìng
敭yáng
敮xiá
敯mǐn
数shù
敱ái
敲qiāo
敳ái
整zhěng
敵dí
敶zhèn
敷fū
數shù
敹liáo
敺qū
敻xiòng
敼yǐ
敽jiǎo
敾shàn
敿jiǎo
斀zhuó
斁yì
斂liǎn
斃bì
斄lí
斅xiào
斆xiào
文wén
斈xué
斉qí
斊qí
斋zhāi
斌bīn
斍jué
斎zhāi
斏láng
斐fěi
斑bān
斒bān
斓lán
斔yǔ
斕lán
斖wěi
斗dòu
斘shēng
料liào
斚jiǎ
斛hú
斜xié
斝jiǎ
斞yǔ
斟zhēn
斠jiào
斡wò
斢tiǎo
斣dòu
斤jīn
斥chì
斦yín
斧fǔ
斨qiāng
斩zhǎn
斪qú
斫zhuó
斬zhǎn
断duàn
斮cuò
斯sī
新xīn
斱zhuó
斲zhuó
斳qín
斴lín
斵zhuó
斶chù
斷duàn
斸zhǔ
方fāng
斺chǎn
斻háng
於yú
施shī
斾pèi
斿yóu
旀mèi
旁páng
旂qí
旃zhān
旄máo
旅lǚ
旆pèi
旇pī
旈liú
旉fū
旊fǎng
旋xuán
旌jīng
旍jīng
旎nǐ
族zú
旐zhào
旑yǐ
旒liú
旓shāo
旔jiàn
旕yú
旖yǐ
旗qí
旘zhì
旙fān
旚piāo
旛fān
旜zhān
旝kuài
旞suì
旟yú
无wú
旡jì
既jì
旣jì
旤huò
日rì
旦dàn
旧jiù
旨zhǐ
早zǎo
旪xié
旫tiāo
旬xún
旭xù
旮gā
旯lá
旰gàn
旱hàn
旲tái
旳dì
旴xū
旵chǎn
时shí
旷kuàng
旸yáng
旹shí
旺wàng
旻mín
旼mín
旽tūn
旾chūn
旿wǔ
昀yún
昁bèi
昂áng
昃zè
昄bǎn
昅jié
昆kūn
昇shēng
昈hù
昉fǎng
昊hào
昋guì
昌chāng
昍xuān
明míng
昏hūn
昐fēn
昑qǐn
昒hū
易yì
昔xī
昕xīn
昖yán
昗zè
昘fǎng
昙tán
昚shèn
昛jù
昜yáng
昝zǎn
昞bǐng
星xīng
映yìng
昡xuàn
昢pò
昣zhěn
昤líng
春chūn
昦hào
昧mèi
昨zuó
昩mò
昪biàn
昫xù
昬hūn
昭zhāo
昮zòng
是shì
昰shì
昱yù
昲fèi
昳dié
昴mǎo
昵nì
昶chǎng
昷wēn
昸dōng
昹ǎi
昺bǐng
昻áng
昼zhòu
昽lóng
显xiǎn
昿kuàng
晀tiǎo
晁cháo
時shí
晃huǎng
晄huǎng
晅xuǎn
晆kuí
晇xū
晈jiǎo
晉jìn
晊zhì
晋jìn
晌shǎng
晍tóng
晎hǒng
晏yàn
晐gāi
晑xiǎng
晒shài
晓xiǎo
晔yè
晕yūn
晖huī
晗hán
晘hàn
晙jùn
晚wǎn
晛xiàn
晜kūn
晝zhòu
晞xī
晟chéng
晠shèng
晡bū
晢zhé
晣zhé
晤wù
晥wǎn
晦huì
晧hào
晨chén
晩wǎn
晪tiǎn
晫zhuó
晬zuì
晭zhǒu
普pǔ
景jǐng
晰xī
晱shǎn
晲nǐ
晳xī
晴qíng
晵qǐ
晶jīng
晷guǐ
晸zhěng
晹yì
智zhì
晻àn
晼wǎn
晽lín
晾liàng
晿chāng
暀wǎng
暁xiǎo
暂zàn
暃fēi
暄xuān
暅gèng
暆yí
暇xiá
暈yūn
暉huī
暊xǔ
暋mǐn
暌kuí
暍yē
暎yìng
暏shǔ
暐wěi
暑shǔ
暒qíng
暓mào
暔nán
暕jiǎn
暖nuǎn
暗àn
暘yáng
暙chūn
暚yáo
暛suǒ
暜pǔ
暝míng
暞jiǎo
暟kǎi
暠gǎo
暡wěng
暢chàng
暣qì
暤hào
暥yàn
暦lì
暧ài
暨jì
暩jì
暪mèn
暫zàn
暬xiè
暭hào
暮mù
暯mò
暰cōng
暱nì
暲zhāng
暳huì
暴bào
暵hàn
暶xuán
暷chuán
暸liáo
暹xiān
暺tǎn
暻jǐng
暼piē
暽lín
暾tūn
暿xǐ
曀yì
曁jì
曂huàng
曃dài
曄yè
曅yè
曆lì
曇tán
曈tóng
曉xiǎo
曊fèi
曋shěn
曌zhào
曍hào
曎yì
曏xiǎng
曐xīng
曑shēn
曒jiǎo
曓bào
曔jìng
曕yàn
曖ài
曗yè
曘rú
曙shǔ
曚méng
曛xūn
曜yào
曝pù
曞lì
曟chén
曠kuàng
曡dié
曢liǎo
曣yàn
曤huò
曥lú
曦xī
曧róng
曨lóng
曩nǎng
曪luǒ
曫luán
曬shài
曭tǎng
曮yǎn
曯zhú
曰yuē
曱yuē
曲qū
曳yè
更gèng
曵yè
曶hū
曷hé
書shū
曹cáo
曺cáo
曻shēng
曼màn
曽cēng
曾céng
替tì
最zuì
朁cǎn
朂xù
會huì
朄yǐn
朅qiè
朆fēn
朇pí
月yuè
有yǒu
朊ruǎn
朋péng
朌fén
服fú
朎líng
朏fěi
朐qú
朑tì
朒nǜ
朓tiǎo
朔shuò
朕zhèn
朖lǎng
朗lǎng
朘zuī
朙míng
朚huāng
望wàng
朜tūn
朝cháo
朞jī
期qī
朠yīng
朡zōng
朢wàng
朣tóng
朤lǎng
朥láo
朦méng
朧lóng
木mù
朩děng
未wèi
末mò
本běn
札zhá
朮shù
术shù
朰mù
朱zhū
朲rén
朳bā
朴pǔ
朵duǒ
朶duǒ
朷dāo
朸lì
朹guǐ
机jī
朻jiū
朼bǐ
朽xiǔ
朾chéng
朿cì
杀shā
杁rù
杂zá
权quán
杄qiān
杅yú
杆gān
杇wū
杈chā
杉shān
杊xún
杋fán
杌wù
杍zǐ
李lǐ
杏xìng
材cái
村cūn
杒rèn
杓biāo
杔tuō
杕dì
杖zhàng
杗máng
杘chì
杙yì
杚gài
杛gōng
杜dù
杝lí
杞qǐ
束shù
杠gāng
条tiáo
杢jiang
杣mián
杤wàn
来lái
杦jiǔ
杧máng
杨yáng
杩mà
杪miǎo
杫sì
杬yuán
杭háng
杮fèi
杯bēi
杰jié
東dōng
杲gǎo
杳yǎo
杴xiān
杵chǔ
杶chūn
杷pá
杸shū
杹huà
杺xīn
杻chǒu
杼zhù
杽chǒu
松sōng
板bǎn
枀sōng
极jí
枂wò
枃jìn
构gòu
枅jī
枆máo
枇pí
枈bì
枉wǎng
枊àng
枋fāng
枌fén
枍yì
枎fú
枏nán
析xī
枑hù
枒yā
枓dǒu
枔xín
枕zhěn
枖yāo
林lín
枘ruì
枙ě
枚méi
枛zhào
果guǒ
枝zhī
枞cōng
枟yùn
枠zui
枡shēng
枢shū
枣zǎo
枤dì
枥lì
枦lú
枧jiǎn
枨chéng
枩sōng
枪qiāng
枫fēng
枬zhān
枭xiāo
枮xiān
枯kū
枰píng
枱tái
枲xǐ
枳zhǐ
枴guǎi
枵xiāo
架jià
枷jiā
枸gǒu
枹bāo
枺mò
枻yì
枼yè
枽yè
枾shì
枿niè
柀bǐ
柁duò
柂yí
柃líng
柄bǐng
柅nǐ
柆lā
柇hé
柈bàn
柉fán
柊zhōng
柋dài
柌cí
柍yǎng
柎fū
柏bǎi
某mǒu
柑gān
柒qī
染rǎn
柔róu
柕mào
柖sháo
柗sōng
柘zhè
柙xiá
柚yòu
柛shēn
柜guì
柝tuò
柞zhà
柟nán
柠níng
柡yǒng
柢dǐ
柣zhì
柤zhā
查chá
柦dàn
柧gū
柨bù
柩jiù
柪āo
柫fú
柬jiǎn
柭bā
柮duò
柯kē
柰nài
柱zhù
柲bì
柳liǔ
柴chái
柵shān
柶sì
柷chù
柸pēi
柹shì
柺guǎi
査zhā
柼yǎo
柽chēng
柾jiù
柿shì
栀zhī
栁liǔ
栂méi
栃lì
栄róng
栅zhà
栆zǎo
标biāo
栈zhàn
栉zhì
栊lóng
栋dòng
栌lú
栍shēng
栎lì
栏lán
栐yǒng
树shù
栒xún
栓shuān
栔qì
栕zhēn
栖qī
栗lì
栘yí
栙xiáng
栚zhèn
栛lì
栜sè
栝guā
栞kān
栟bēn
栠rěn
校xiào
栢bǎi
栣rěn
栤bìng
栥zī
栦chóu
栧yì
栨cì
栩xǔ
株zhū
栫jiàn
栬zuì
栭ér
栮ěr
栯yǒu
栰fá
栱gǒng
栲kǎo
栳lǎo
栴zhān
栵liè
栶yīn
样yàng
核hé
根gēn
栺yì
栻shì
格gé
栽zāi
栾luán
栿fú
桀jié
桁héng
桂guì
桃táo
桄guāng
桅wéi
框kuāng
桇rú
案àn
桉ān
桊juàn
桋yí
桌zhuō
桍kū
桎zhì
桏qióng
桐tóng
桑sāng
桒sāng
桓huán
桔jú
桕jiù
桖xuè
桗duò
桘zhuì
桙yú
桚zǎn
桜yīng
桝jié
桞liǔ
桟zhàn
桠yā
桡ráo
桢zhēn
档dàng
桤qī
桥qiáo
桦huà
桧guì
桨jiǎng
桩zhuāng
桪xún
桫suō
桬shā
桭zhēn
桮bēi
桯tīng
桰kuò
桱jìng
桲po
桳bèn
桴fú
桵ruí
桶tǒng
桷jué
桸xī
桹láng
桺liǔ
桻fēng
桼qī
桽wěn
桾jūn
桿gǎn
梀sù
梁liáng
梂qiú
梃tǐng
梄yǒu
梅méi
梆bāng
梇lòng
梈pēng
梉zhuāng
梊dì
梋xuān
梌tú
梍zào
梎āo
梏gù
梐bì
梑dí
梒hán
梓zǐ
梔zhī
梕rèn
梖bèi
梗gěng
梘jiǎn
梙huàn
梚wǎn
梛nuó
梜jiā
條tiáo
梞jì
梟xiāo
梠lǚ
梡hún
梢shāo
梣cén
梤fén
梥sōng
梦mèng
梧wú
梨lí
梩lí
梪dòu
梫qǐn
梬yǐng
梭suō
梮jū
梯tī
械xiè
梱kǔn
梲zhuó
梳shū
梴chān
梵fàn
梶wěi
梷jìng
梸lí
梹bīn
梺xià
梻fó
梼táo
梽zhì
梾lái
梿lián
检jiǎn
棁zhuō
棂líng
棃lí
棄qì
棅bǐng
棆lún
棇cōng
棈qiàn
棉mián
棊qí
棋qí
棌cài
棍gùn
棎chán
棏dé
棐fěi
棑pái
棒bàng
棓bàng
棔hūn
棕zōng
棖chéng
棗zǎo
棘jí
棙lì
棚péng
棛yù
棜yù
棝gù
棞jùn
棟dòng
棠táng
棡gāng
棢wǎng
棣dì
棤cuò
棥fán
棦chēng
棧zhàn
棨qǐ
棩yuān
棪yǎn
棫yù
棬quān
棭yì
森sēn
棯rěn
棰chuí
棱léng
棲qī
棳zhuō
棴fú
棵kē
棶lái
棷zōu
棸zōu
棹zhào
棺guān
棻fēn
棼fén
棽shēn
棾qíng
棿ní
椀wǎn
椁guǒ
椂lù
椃háo
椄jiē
椅yǐ
椆chóu
椇jǔ
椈jú
椉chéng
椊zuó
椋liáng
椌qiāng
植zhí
椎chuí
椏yā
椐jū
椑bēi
椒jiāo
椓zhuó
椔zī
椕bīn
椖péng
椗dìng
椘chǔ
椙chāng
椚mēn
椛huā
検jiǎn
椝guī
椞xì
椟dú
椠qiàn
椡dào
椢guì
椣diǎn
椤luó
椥zhī
椦quan
椧mìng
椨fǔ
椩gēng
椪pèng
椫shàn
椬yí
椭tuǒ
椮sēn
椯duǒ
椰yē
椱fù
椲wěi
椳wēi
椴duàn
椵jiǎ
椶zōng
椷jiān
椸yí
椹shèn
椺xí
椻yàn
椼yǎn
椽chuán
椾jiān
椿chūn
楀yǔ
楁hé
楂zhā
楃wò
楄pián
楅bī
楆yāo
楇huò
楈xū
楉ruò
楊yáng
楋là
楌yán
楍běn
楎huī
楏kuí
楐jiè
楑kuí
楒sī
楓fēng
楔xiē
楕tuǒ
楖zhì
楗jiàn
楘mù
楙mào
楚chǔ
楛hù
楜hú
楝liàn
楞léng
楟tíng
楠nán
楡yú
楢yóu
楣méi
楤sǒng
楥xuàn
楦xuàn
楧yǎng
楨zhēn
楩pián
楪yè
楫jí
楬jié
業yè
楮chǔ
楯dùn
楰yú
楱zòu
楲wēi
楳méi
楴tì
極jí
楶jié
楷kǎi
楸qiū
楹yíng
楺rǒu
楻huáng
楼lóu
楽lè
楾quán
楿xiāng
榀pǐn
榁shǐ
概gài
榃tán
榄lǎn
榅wēn
榆yú
榇chèn
榈lǘ
榉jǔ
榊shén
榋chu
榌bī
榍xiè
榎jiǎ
榏yì
榐zhǎn
榑fú
榒nuò
榓mì
榔láng
榕róng
榖gǔ
榗jiàn
榘jǔ
榙tā
榚yǎo
榛zhēn
榜bǎng
榝shā
榞yuán
榟zǐ
榠míng
榡sù
榢jià
榣yáo
榤jié
榥huàng
榦gàn
榧fěi
榨zhà
榩qián
榪mà
榫sǔn
榬yuán
榭xiè
榮róng
榯shí
榰zhī
榱cuī
榲wēn
榳tíng
榴liú
榵róng
榶táng
榷què
榸zhāi
榹sī
榺shèng
榻tà
榼kē
榽xī
榾gǔ
榿qī
槀gǎo
槁gǎo
槂sūn
槃pán
槄tāo
槅gé
槆chūn
槇diān
槈nòu
槉jí
槊shuò
構gòu
槌chuí
槍qiāng
槎chá
槏qiǎn
槐huái
槑méi
槒xù
槓gàng
槔gāo
槕zhuō
槖tuó
槗qiáo
様yàng
槙diān
槚jiǎ
槛kǎn
槜zuì
槝dǎo
槞lóng
槟bīn
槠zhū
槡sāng
槢xí
槣jī
槤lián
槥huì
槦yōng
槧qiàn
槨guǒ
槩gài
槪gài
槫tuán
槬huà
槭qī
槮sēn
槯cuī
槰péng
槱yǒu
槲hú
槳jiǎng
槴hù
槵huàn
槶guì
槷niè
槸yì
槹gāo
槺kāng
槻guī
槼guī
槽cáo
槾màn
槿jǐn
樀dí
樁zhuāng
樂lè
樃lǎng
樄chén
樅cōng
樆lí
樇xiū
樈qíng
樉shuǎng
樊fán
樋tōng
樌guàn
樍zé
樎sù
樏lěi
樐lǔ
樑liáng
樒mì
樓lóu
樔cháo
樕sù
樖kē
樗chū
樘táng
標biāo
樚lù
樛jiū
樜zhè
樝zhā
樞shū
樟zhāng
樠mán
模mó
樢niǎo
樣yàng
樤tiáo
樥péng
樦zhù
樧shā
樨xī
権quán
横héng
樫jiān
樬cōng
樭jī
樮yān
樯qiáng
樰xuě
樱yīng
樲èr
樳xún
樴zhí
樵qiáo
樶zuī
樷cóng
樸pǔ
樹shù
樺huà
樻kuì
樼zhēn
樽zūn
樾yuè
樿shàn
橀xī
橁chūn
橂diàn
橃fá
橄gǎn
橅mó
橆wǔ
橇qiāo
橈ráo
橉lìn
橊liú
橋qiáo
橌xiàn
橍rùn
橎fán
橏zhǎn
橐tuó
橑lǎo
橒yún
橓shùn
橔dūn
橕chēng
橖táng
橗méng
橘jú
橙chéng
橚sù
橛jué
橜jué
橝diàn
橞huì
機jī
橠nuǒ
橡xiàng
橢tuǒ
橣nǐng
橤ruǐ
橥zhū
橦tóng
橧zēng
橨fén
橩qióng
橪rǎn
橫héng
橬qián
橭gū
橮liǔ
橯lào
橰gāo
橱chú
橲xǐ
橳shèng
橴zǐ
橵san
橶jí
橷dōu
橸jīng
橹lǔ
橺jian
橻chu
橼yuán
橽tà
橾shū
橿jiāng
檀tán
檁lǐn
檂nóng
檃yǐn
檄xí
檅huì
檆shān
檇zuì
檈xuán
檉chēng
檊gàn
檋jú
檌zuì
檍yì
檎qín
檏pǔ
檐yán
檑léi
檒fēng
檓huǐ
檔dàng
檕jì
檖suì
檗bò
檘píng
檙chéng
檚chǔ
檛zhuā
檜guì
檝jí
檞jiě
檟jiǎ
檠qíng
檡zhái
檢jiǎn
檣qiáng
檤dào
檥yǐ
檦biǎo
檧sōng
檨shē
檩lǐn
檪lì
檫chá
檬méng
檭yín
檮táo
檯tái
檰mián
檱qí
檲tuán
檳bīn
檴huò
檵jì
檶qiān
檷nǐ
檸níng
檹yī
檺gǎo
檻kǎn
檼yìn
檽nòu
檾qǐng
檿yǎn
櫀qí
櫁mì
櫂zhào
櫃guì
櫄chūn
櫅jī
櫆kuí
櫇pó
櫈dèng
櫉chú
櫊gé
櫋mián
櫌yōu
櫍zhì
櫎huǎng
櫏qiān
櫐lěi
櫑léi
櫒sà
櫓lǔ
櫔lì
櫕cuán
櫖lǜ
櫗miè
櫘huì
櫙ōu
櫚lǘ
櫛zhì
櫜gāo
櫝dú
櫞yuán
櫟lì
櫠fèi
櫡zhuó
櫢sǒu
櫣lián
櫤jiàng
櫥chú
櫦qìng
櫧zhū
櫨lú
櫩yán
櫪lì
櫫zhū
櫬chèn
櫭jié
櫮è
櫯sū
櫰huái
櫱niè
櫲yù
櫳lóng
櫴lài
櫵jiao
櫶xiǎn
櫷guī
櫸jǔ
櫹xiāo
櫺líng
櫻yīng
櫼jiān
櫽yǐn
櫾yóu
櫿yíng
欀xiāng
欁nóng
欂bó
欃chán
欄lán
欅jǔ
欆shuāng
欇shè
欈wéi
欉cóng
權quán
欋qú
欌cáng
欍jiù
欎yù
欏luó
欐lì
欑cuán
欒luán
欓dǎng
欔jué
欕yán
欖lǎn
欗lán
欘zhú
欙léi
欚lǐ
欛bà
欜náng
欝yù
欞líng
欟guang
欠qiàn
次cì
欢huān
欣xīn
欤yú
欥yì
欦qiān
欧ōu
欨xū
欩chāo
欪chù
欫qì
欬kài
欭yì
欮jué
欯xì
欰xù
欱hē
欲yù
欳kuì
欴láng
欵kuǎn
欶shuò
欷xī
欸āi
欹yī
欺qī
欻chuā
欼chǐ
欽qīn
款kuǎn
欿kǎn
歀kuǎn
歁kǎn
歂chuǎn
歃shà
歄guā
歅yīn
歆xīn
歇xiē
歈yú
歉qiàn
歊xiāo
歋yè
歌gē
歍wū
歎tàn
歏jìn
歐ōu
歑hū
歒tì
歓huān
歔xū
歕pēn
歖xǐ
歗xiào
歘chuā
歙shè
歚shàn
歛hān
歜chù
歝yì
歞è
歟yú
歠chuò
歡huān
止zhǐ
正zhèng
此cǐ
步bù
武wǔ
歧qí
歨bù
歩bù
歪wāi
歫jù
歬qián
歭chí
歮sè
歯chǐ
歰sè
歱zhǒng
歲suì
歳suì
歴lì
歵zé
歶yú
歷lì
歸guī
歹dǎi
歺è
死sǐ
歼jiān
歽zhé
歾mò
歿mò
殀yāo
殁mò
殂cú
殃yāng
殄tiǎn
殅shēng
殆dài
殇shāng
殈xù
殉xùn
殊shū
残cán
殌jué
殍piǎo
殎qià
殏qiú
殐sù
殑qíng
殒yǔn
殓liàn
殔yì
殕fǒu
殖zhí
殗yè
殘cán
殙hūn
殚dān
殛jí
殜dié
殝zhēn
殞yǔn
殟wēn
殠chòu
殡bìn
殢tì
殣jìn
殤shāng
殥yín
殦diāo
殧jiù
殨huì
殩cuàn
殪yì
殫dān
殬dù
殭jiāng
殮liàn
殯bìn
殰dú
殱jiān
殲jiān
殳shū
殴ōu
段duàn
殶zhù
殷yīn
殸qìng
殹yì
殺shā
殻qiào
殼ké
殽xiáo
殾xùn
殿diàn
毀huǐ
毁huǐ
毂gǔ
毃qiāo
毄jī
毅yì
毆ōu
毇huǐ
毈duàn
毉yī
毊xiāo
毋wú
毌guàn
母mǔ
毎měi
每měi
毐ǎi
毑jiě
毒dú
毓yù
比bǐ
毕bì
毖bì
毗pí
毘pí
毙bì
毚chán
毛máo
毜háo
毝cǎi
毞pí
毟liě
毠jiā
毡zhān
毢sāi
毣mù
毤tuò
毥xún
毦ěr
毧róng
毨xiǎn
毩jú
毪mú
毫háo
毬qiú
毭dòu
毮shā
毯tǎn
毰péi
毱jú
毲duō
毳cuì
毴bī
毵sān
毶sān
毷mào
毸sāi
毹shū
毺shū
毻tuò
毼hé
毽jiàn
毾tà
毿sān
氀lǘ
氁mú
氂máo
氃tóng
氄rǒng
氅chǎng
氆pǔ
氇lu
氈zhān
氉sào
氊zhān
氋méng
氌lǔ
氍qú
氎dié
氏shì
氐dī
民mín
氒jué
氓máng
气qì
氕piē
氖nǎi
気qì
氘dāo
氙xiān
氚chuān
氛fēn
氜yáng
氝nèi
氞bin
氟fú
氠shēn
氡dōng
氢qīng
氣qì
氤yīn
氥xī
氦hài
氧yǎng
氨ān
氩yà
氪kè
氫qīng
氬yà
氭dōng
氮dàn
氯lǜ
氰qíng
氱yǎng
氲yūn
氳yūn
水shuǐ
氵shui
氶zhěng
氷bīng
永yǒng
氹dàng
氺shuǐ
氻lè
氼nì
氽tǔn
氾fàn
氿guǐ
汀tīng
汁zhī
求qiú
汃bīn
汄zè
汅miǎn
汆cuān
汇huì
汈diāo
汉hàn
汊chà
汋zhuó
汌chuàn
汍wán
汎fàn
汏dà
汐xī
汑tuō
汒máng
汓qiú
汔qì
汕shàn
汖pìn
汗hàn
汘qiān
汙wū
汚wū
汛xùn
汜sì
汝rǔ
汞gǒng
江jiāng
池chí
污wū
汢tu
汣jiǔ
汤tāng
汥zhī
汦zhǐ
汧qiān
汨mì
汩gǔ
汪wāng
汫jǐng
汬jǐng
汭ruì
汮jūn
汯hóng
汰tài
汱quǎn
汲jí
汳biàn
汴biàn
汵gàn
汶wèn
汷zhōng
汸fāng
汹xiōng
決jué
汻hǔ
汼niú
汽qì
汾fén
汿xù
沀xù
沁qìn
沂yí
沃wò
沄yún
沅yuán
沆hàng
沇yǎn
沈shěn
沉chén
沊dàn
沋yóu
沌dùn
沍hù
沎huò
沏qī
沐mù
沑nǜ
沒méi
沓dá
沔miǎn
沕mì
沖chōng
沗pāng
沘bǐ
沙shā
沚zhǐ
沛pèi
沜pàn
沝zhuǐ
沞zā
沟gōu
沠liú
没méi
沢zé
沣fēng
沤ōu
沥lì
沦lún
沧cāng
沨fēng
沩wéi
沪hù
沫mò
沬mèi
沭shù
沮jǔ
沯zá
沰tuō
沱tuó
沲tuó
河hé
沴lì
沵mǐ
沶yí
沷fā
沸fèi
油yóu
沺tián
治zhì
沼zhǎo
沽gū
沾zhān
沿yán
泀sī
況kuàng
泂jiǒng
泃jū
泄xiè
泅qiú
泆yì
泇jiā
泈zhōng
泉quán
泊pō
泋huì
泌mì
泍bēn
泎zé
泏zhú
泐lè
泑yōu
泒gū
泓hóng
泔gān
法fǎ
泖mǎo
泗sì
泘hū
泙píng
泚cǐ
泛fàn
泜zhī
泝sù
泞nìng
泟chēng
泠líng
泡pào
波bō
泣qì
泤sì
泥ní
泦jú
泧sà
注zhù
泩shēng
泪lèi
泫xuàn
泬jué
泭fú
泮pàn
泯mǐn
泰tài
泱yāng
泲jǐ
泳yǒng
泴guàn
泵bèng
泶xué
泷lóng
泸lú
泹dàn
泺luò
泻xiè
泼pō
泽zé
泾jīng
泿yín
洀pán
洁jié
洂yè
洃huī
洄huí
洅zài
洆chéng
洇yīn
洈wéi
洉hòu
洊jiàn
洋yáng
洌liè
洍sì
洎jì
洏ér
洐xíng
洑fú
洒sǎ
洓sè
洔zhǐ
洕yìn
洖wú
洗xǐ
洘kǎo
洙zhū
洚jiàng
洛luò
洜luò
洝àn
洞dòng
洟tì
洠móu
洡lèi
洢yī
洣mǐ
洤quán
津jīn
洦pò
洧wěi
洨xiáo
洩xiè
洪hóng
洫xù
洬sù
洭kuāng
洮táo
洯qiè
洰jù
洱ěr
洲zhōu
洳rù
洴píng
洵xún
洶xiōng
洷zhì
洸guāng
洹huán
洺míng
活huó
洼wā
洽qià
派pài
洿wū
浀qū
流liú
浂yì
浃jiā
浄jìng
浅qiǎn
浆jiāng
浇jiāo
浈zhēn
浉shī
浊zhuó
测cè
浌fá
浍huì
济jì
浏liú
浐chǎn
浑hún
浒hǔ
浓nóng
浔xún
浕jìn
浖liè
浗qiú
浘wěi
浙zhè
浚jùn
浛hán
浜bāng
浝máng
浞zhuó
浟yóu
浠xī
浡bó
浢dòu
浣huàn
浤hóng
浥yì
浦pǔ
浧yǐng
浨lǎn
浩hào
浪làng
浫hǎn
浬lǐ
浭gēng
浮fú
浯wú
浰liàn
浱chún
浲féng
浳yì
浴yù
浵tóng
浶láo
海hǎi
浸jìn
浹jiā
浺chōng
浻jiǒng
浼měi
浽suī
浾chēng
浿pèi
涀xiàn
涁shèn
涂tú
涃kùn
涄pīng
涅niè
涆hàn
涇jīng
消xiāo
涉shè
涊niǎn
涋tū
涌yǒng
涍xiào
涎xián
涏tǐng
涐é
涑sù
涒tūn
涓juān
涔cén
涕tì
涖lì
涗shuì
涘sì
涙lèi
涚shuì
涛tāo
涜dú
涝lào
涞lái
涟lián
涠wéi
涡wō
涢yún
涣huàn
涤dí
涥hēng
润rùn
涧jiàn
涨zhǎng
涩sè
涪fú
涫guàn
涬xìng
涭shòu
涮shuàn
涯yá
涰chuò
涱zhàng
液yè
涳kōng
涴wò
涵hán
涶tuō
涷dōng
涸hé
涹wō
涺jū
涻shè
涼liáng
涽hūn
涾tà
涿zhuō
淀diàn
淁qiè
淂dé
淃juàn
淄zī
淅xī
淆xiáo
淇qí
淈gǔ
淉guǒ
淊yān
淋lín
淌tǎng
淍zhōu
淎pěng
淏hào
淐chāng
淑shū
淒qī
淓fāng
淔zhí
淕lù
淖nào
淗jú
淘táo
淙cóng
淚lèi
淛zhè
淜píng
淝féi
淞sōng
淟tiǎn
淠pì
淡dàn
淢yù
淣ní
淤yū
淥lù
淦gàn
淧mì
淨jìng
淩líng
淪lún
淫yín
淬cuì
淭qú
淮huái
淯yù
淰niǎn
深shēn
淲biāo
淳chún
淴hū
淵yuān
淶lái
混hùn
淸qīng
淹yān
淺qiǎn
添tiān
淼miǎo
淽zhǐ
淾yǐn
淿bó
渀bèn
渁yuān
渂wèn
渃ruò
渄fēi
清qīng
渆yuān
渇kě
済jì
渉shè
渊yuān
渋sè
渌lù
渍zì
渎dú
渏yī
渐jiàn
渑miǎn
渒pài
渓xī
渔yú
渕yuān
渖shěn
渗shèn
渘róu
渙huàn
渚zhǔ
減jiǎn
渜nuǎn
渝yú
渞qiú
渟tíng
渠qú
渡dù
渢fán
渣zhā
渤bó
渥wò
渦wō
渧dì
渨wēi
温wēn
渪rú
渫xiè
測cè
渭wèi
渮hé
港gǎng
渰yǎn
渱hóng
渲xuàn
渳mǐ
渴kě
渵máo
渶yīng
渷yǎn
游yóu
渹hōng
渺miǎo
渻shěng
渼měi
渽zāi
渾hún
渿nài
湀guǐ
湁chì
湂è
湃pài
湄méi
湅liàn
湆qì
湇qì
湈méi
湉tián
湊còu
湋wéi
湌cān
湍tuān
湎miǎn
湏huì
湐mò
湑xū
湒jí
湓pén
湔jiān
湕jiǎn
湖hú
湗fèng
湘xiāng
湙yì
湚yìn
湛zhàn
湜shí
湝jiē
湞zhēn
湟huáng
湠tàn
湡yú
湢bì
湣mǐn
湤shī
湥tū
湦shēng
湧yǒng
湨jú
湩dòng
湪tuàn
湫jiǎo
湬jiǎo
湭qiú
湮yān
湯tāng
湰lóng
湱huò
湲yuán
湳nǎn
湴bàn
湵yǒu
湶quán
湷zhuāng
湸liàng
湹chán
湺xián
湻chún
湼niè
湽zī
湾wān
湿shī
満mǎn
溁yíng
溂là
溃kuì
溄féng
溅jiàn
溆xù
溇lóu
溈wéi
溉gài
溊bō
溋yíng
溌pō
溍jìn
溎yàn
溏táng
源yuán
溑suǒ
溒yuán
溓lián
溔yǎo
溕méng
準zhǔn
溗chéng
溘kè
溙tài
溚tǎ
溛wā
溜liū
溝gōu
溞sāo
溟míng
溠zhà
溡shí
溢yì
溣lùn
溤mǎ
溥pǔ
溦wēi
溧lì
溨zāi
溩wù
溪xī
溫wēn
溬qiāng
溭zé
溮shī
溯sù
溰ái
溱qín
溲sōu
溳yún
溴xiù
溵yīn
溶róng
溷hùn
溸sù
溹suò
溺nì
溻tā
溼shī
溽rù
溾āi
溿pàn
滀chù
滁chú
滂pāng
滃wēng
滄cāng
滅miè
滆gé
滇diān
滈hào
滉huàng
滊xì
滋zī
滌dí
滍zhì
滎xíng
滏fǔ
滐jié
滑huá
滒gē
滓zǐ
滔tāo
滕téng
滖suī
滗bì
滘jiào
滙huì
滚gǔn
滛yín
滜gāo
滝lóng
滞zhì
滟yàn
滠shè
满mǎn
滢yíng
滣chún
滤lǜ
滥làn
滦luán
滧yáo
滨bīn
滩tān
滪yù
滫xiǔ
滬hù
滭bì
滮biāo
滯zhì
滰jiàng
滱kòu
滲shèn
滳shāng
滴dī
滵mì
滶áo
滷lǔ
滸hǔ
滹hū
滺yōu
滻chǎn
滼fàn
滽yōng
滾gǔn
滿mǎn
漀qǐng
漁yú
漂piāo
漃jì
漄yá
漅cháo
漆qī
漇xǐ
漈jì
漉lù
漊lóu
漋lóng
漌jǐn
漍guó
漎cóng
漏lòu
漐zhí
漑gài
漒qiáng
漓lí
演yǎn
漕cáo
漖jiào
漗cōng
漘chún
漙tuán
漚ōu
漛téng
漜yě
漝xí
漞mì
漟táng
漠mò
漡shāng
漢hàn
漣lián
漤lǎn
漥wā
漦chí
漧gān
漨féng
漩xuán
漪yī
漫màn
漬zì
漭mǎng
漮kāng
漯luò
漰pēng
漱shù
漲zhǎng
漳zhāng
漴zhuàng
漵xù
漶huàn
漷huǒ
漸jiàn
漹yān
漺shuǎng
漻liáo
漼cuǐ
漽tí
漾yàng
漿jiāng
潀cóng
潁yǐng
潂hóng
潃xiǔ
潄shù
潅guàn
潆yíng
潇xiāo
潈zong
潉kūn
潊xù
潋liàn
潌zhì
潍wéi
潎pì
潏yù
潐jiào
潑pō
潒dàng
潓huì
潔jié
潕wǔ
潖pá
潗jí
潘pān
潙wéi
潚sù
潛qián
潜qián
潝xī
潞lù
潟xì
潠xùn
潡dùn
潢huáng
潣mǐn
潤rùn
潥sù
潦lǎo
潧zhēn
潨cóng
潩yì
潪zhè
潫wān
潬shàn
潭tán
潮cháo
潯xún
潰kuì
潱yē
潲shào
潳tú
潴zhū
潵sǎ
潶hēi
潷bì
潸shān
潹chán
潺chán
潻shǔ
潼tóng
潽pū
潾lín
潿wéi
澀sè
澁sè
澂chéng
澃jiǒng
澄chéng
澅huà
澆jiāo
澇lào
澈chè
澉gǎn
澊cūn
澋hòng
澌sī
澍shù
澎pēng
澏hán
澐yún
澑liù
澒hòng
澓fú
澔hào
澕hé
澖xián
澗jiàn
澘shān
澙xì
澚yu
澛lǔ
澜lán
澝nìng
澞yú
澟lǐn
澠miǎn
澡zǎo
澢dāng
澣huàn
澤zé
澥xiè
澦yù
澧lǐ
澨shì
澩xué
澪líng
澫wàn
澬zī
澭yōng
澮huì
澯càn
澰liàn
澱diàn
澲yè
澳ào
澴huán
澵zhēn
澶chán
澷màn
澸dǎn
澹dàn
澺yì
澻suì
澼pì
澽jù
澾tà
澿qín
激jī
濁zhuó
濂lián
濃nóng
濄guō
濅jìn
濆fén
濇sè
濈jí
濉suī
濊huì
濋chǔ
濌tà
濍sōng
濎dǐng
濏sè
濐zhǔ
濑lài
濒bīn
濓lián
濔mǐ
濕shī
濖shù
濗mì
濘nìng
濙yíng
濚yíng
濛méng
濜jìn
濝qí
濞bì
濟jì
濠háo
濡rú
濢cuì
濣wò
濤tāo
濥yǐn
濦yǐn
濧duì
濨cí
濩huò
濪qìng
濫làn
濬jùn
濭ǎi
濮pú
濯zhuó
濰wéi
濱bīn
濲gǔ
濳qián
濴yíng
濵bīn
濶kuò
濷fèi
濸cāng
濹me
濺jiàn
濻wěi
濼luò
濽zàn
濾lǜ
濿lì
瀀yōu
瀁yàng
瀂lǔ
瀃sì
瀄zhì
瀅yíng
瀆dú
瀇wǎng
瀈huī
瀉xiè
瀊pán
瀋shěn
瀌biāo
瀍chán
瀎mò
瀏liú
瀐jiān
瀑pù
瀒sè
瀓chéng
瀔gǔ
瀕bīn
瀖huò
瀗xiàn
瀘lú
瀙qìn
瀚hàn
瀛yíng
瀜róng
瀝lì
瀞jìng
瀟xiāo
瀠yíng
瀡suǐ
瀢wěi
瀣xiè
瀤huái
瀥xuè
瀦zhū
瀧lóng
瀨lài
瀩duì
瀪fán
瀫hú
瀬lài
瀭shū
瀮ling
瀯yíng
瀰mí
瀱jì
瀲liàn
瀳jiàn
瀴yíng
瀵fèn
瀶lín
瀷yì
瀸jiān
瀹yuè
瀺chán
瀻dài
瀼ráng
瀽jiǎn
瀾lán
瀿fán
灀shuàng
灁yuān
灂zhuó
灃fēng
灄shè
灅lěi
灆lán
灇cóng
灈qú
灉yōng
灊qián
灋fǎ
灌guàn
灍jué
灎yàn
灏hào
灐yíng
灑sǎ
灒zàn
灓luán
灔yàn
灕lí
灖mǐ
灗shàn
灘tān
灙dǎng
灚jiǎo
灛chǎn
灜yíng
灝hào
灞bà
灟zhú
灠lǎn
灡lán
灢nǎng
灣wān
灤luán
灥xún
灦xiǎn
灧yàn
灨gàn
灩yàn
灪yù
火huǒ
灬biāo
灭miè
灮guāng
灯dēng
灰huī
灱xiāo
灲xiāo
灳huī
灴hōng
灵líng
灶zào
灷zhuàn
灸jiǔ
灹zhà
灺xiè
灻chì
灼zhuó
災zāi
灾zāi
灿càn
炀yáng
炁qì
炂zhōng
炃fén
炄niǔ
炅jiǒng
炆wén
炇pū
炈yì
炉lú
炊chuī
炋pī
炌kài
炍pàn
炎yán
炏kài
炐pàng
炑mù
炒chǎo
炓liào
炔guì
炕kàng
炖dùn
炗guāng
炘xīn
炙zhì
炚guāng
炛guāng
炜wěi
炝qiàng
炞bian
炟dá
炠xiá
炡zhēng
炢zhú
炣kě
炤zhào
炥fú
炦bá
炧xiè
炨xiè
炩lìng
炪zhuō
炫xuàn
炬jù
炭tàn
炮pào
炯jiǒng
炰páo
炱tái
炲tái
炳bǐng
炴yǎng
炵tōng
炶shǎn
炷zhù
炸zhà
点diǎn
為wèi
炻shí
炼liàn
炽chì
炾huǎng
炿zhōu
烀hū
烁shuò
烂làn
烃tīng
烄jiǎo
烅xù
烆héng
烇quǎn
烈liè
烉huàn
烊yáng
烋xiū
烌xiū
烍xiǎn
烎yín
烏wū
烐zhōu
烑yáo
烒shì
烓wēi
烔tóng
烕miè
烖zāi
烗kài
烘hōng
烙lào
烚xiá
烛zhú
烜xuǎn
烝zhēng
烞pò
烟yān
烠huí
烡guāng
烢chè
烣huī
烤kǎo
烥jù
烦fán
烧shāo
烨yè
烩huì
烫tàng
烬jìn
热rè
烮liè
烯xī
烰fú
烱jiǒng
烲xiè
烳pǔ
烴tīng
烵zhuó
烶tǐng
烷wán
烸hǎi
烹pēng
烺lǎng
烻yàn
烼xù
烽fēng
烾chì
烿róng
焀hú
焁xī
焂shū
焃hè
焄xūn
焅kù
焆juān
焇xiāo
焈xī
焉yān
焊hàn
焋zhuàng
焌jùn
焍dì
焎xiè
焏jí
焐wù
焑yān
焒lǚ
焓hán
焔yàn
焕huàn
焖mèn
焗jú
焘dào
焙bèi
焚fén
焛lìn
焜kūn
焝hùn
焞tūn
焟xī
焠cuì
無wú
焢hōng
焣chǎo
焤fǔ
焥wò
焦jiāo
焧cōng
焨fèng
焩píng
焪qióng
焫ruò
焬xī
焭qióng
焮xìn
焯chāo
焰yàn
焱yàn
焲yì
焳jué
焴yù
焵gàng
然rán
焷pí
焸xiòng
焹gàng
焺shēng
焻chàng
焼shāo
焽xiǒng
焾niǎn
焿gēng
煀wei
煁chén
煂hè
煃kuǐ
煄zhǒng
煅duàn
煆xiā
煇huī
煈fèng
煉liàn
煊xuān
煋xīng
煌huáng
煍jiǎo
煎jiān
煏bì
煐yīng
煑zhǔ
煒wěi
煓tuān
煔shǎn
煕xī
煖nuǎn
煗nuǎn
煘chán
煙yān
煚jiǒng
煛jiǒng
煜yù
煝mèi
煞shā
煟wèi
煠zhá
煡jìn
煢qióng
煣róu
煤méi
煥huàn
煦xù
照zhào
煨wēi
煩fán
煪qiú
煫suì
煬yáng
煭liè
煮zhǔ
煯jiē
煰zào
煱guā
煲bāo
煳hú
煴yūn
煵nǎn
煶shì
煷liang
煸biān
煹gòu
煺tuì
煻táng
煼chǎo
煽shān
煾ēn
煿bó
熀huǎng
熁xié
熂xì
熃wù
熄xī
熅yùn
熆hé
熇hè
熈xī
熉yún
熊xióng
熋nái
熌shǎn
熍qióng
熎yào
熏xūn
熐mì
熑lián
熒yíng
熓wǔ
熔róng
熕gōng
熖yàn
熗qiàng
熘liū
熙xī
熚bì
熛biāo
熜cōng
熝lù
熞jiān
熟shú
熠yì
熡lóu
熢péng
熣suī
熤yì
熥tēng
熦jué
熧zōng
熨yùn
熩hù
熪yí
熫zhì
熬áo
熭wèi
熮liǔ
熯hàn
熰ōu
熱rè
熲jiǒng
熳màn
熴kūn
熵shāng
熶cuàn
熷zēng
熸jiān
熹xī
熺xī
熻xī
熼yì
熽xiào
熾chì
熿huáng
燀chǎn
燁yè
燂tán
燃rán
燄yàn
燅xún
燆qiāo
燇jùn
燈dēng
燉dùn
燊shēn
燋jiāo
燌fén
燍sī
燎liáo
燏yù
燐lín
燑tóng
燒shāo
燓fén
燔fán
燕yàn
燖xún
燗làn
燘měi
燙tàng
燚yì
燛jiǒng
燜mèn
燝jing
燞jiǎo
營yíng
燠yù
燡yì
燢xué
燣lán
燤tài
燥zào
燦càn
燧suì
燨xī
燩què
燪zǒng
燫lián
燬huǐ
燭zhú
燮xiè
燯líng
燰wēi
燱yì
燲xié
燳zhào
燴huì
燵dá
燶nóng
燷lán
燸rú
燹xiǎn
燺hè
燻xūn
燼jìn
燽chóu
燾dào
燿yào
爀hè
爁làn
爂biāo
爃róng
爄lì
爅mò
爆bào
爇ruò
爈lǜ
爉là
爊āo
爋xūn
爌kuàng
爍shuò
爎liáo
爏lì
爐lú
爑jué
爒liǎo
爓yàn
爔xī
爕xiè
爖lóng
爗yè
爘cān
爙rǎng
爚yuè
爛làn
爜cóng
爝jué
爞chóng
爟guàn
爠ju
爡chè
爢mí
爣tǎng
爤làn
爥zhú
爦lǎn
爧líng
爨cuàn
爩yù
爪zhǎo
爫zhǎo
爬pá
爭zhēng
爮páo
爯chēng
爰yuán
爱ài
爲wèi
爳han
爴jué
爵jué
父fù
爷yé
爸bà
爹diē
爺yé
爻yáo
爼zǔ
爽shuǎng
爾ěr
爿pán
牀chuáng
牁kē
牂zāng
牃dié
牄qiāng
牅yōng
牆qiáng
片piàn
版bǎn
牉pàn
牊cháo
牋jiān
牌pái
牍dú
牎chuāng
牏yú
牐zhá
牑biān
牒dié
牓bǎng
牔bó
牕chuāng
牖yǒu
牗yǒu
牘dú
牙yá
牚chēng
牛niú
牜niú
牝pìn
牞jiū
牟móu
牠tā
牡mǔ
牢láo
牣rèn
牤māng
牥fāng
牦máo
牧mù
牨gāng
物wù
牪yàn
牫gē
牬bèi
牭sì
牮jiàn
牯gǔ
牰yòu
牱gē
牲shēng
牳mǔ
牴dǐ
牵qiān
牶quàn
牷quán
牸zì
特tè
牺xī
牻máng
牼kēng
牽qiān
牾wǔ
牿gù
犀xī
犁lí
犂lí
犃pǒu
犄jī
犅gāng
犆zhí
犇bēn
犈quán
犉chún
犊dú
犋jù
犌jiā
犍jiān
犎fēng
犏piān
犐kē
犑jú
犒kào
犓chú
犔xì
犕bèi
犖luò
犗jiè
犘má
犙sān
犚wèi
犛máo
犜dūn
犝tóng
犞qiáo
犟jiàng
犠xī
犡lì
犢dú
犣liè
犤pái
犥piāo
犦bó
犧xī
犨chōu
犩wéi
犪kuí
犫chōu
犬quǎn
犭quǎn
犮bá
犯fàn
犰qiú
犱jǐ
犲chái
犳zhuó
犴àn
犵gē
状zhuàng
犷guǎng
犸mà
犹yóu
犺kàng
犻bó
犼hǒu
犽yà
犾yín
犿huān
狀zhuàng
狁yǔn
狂kuáng
狃niǔ
狄dí
狅kuáng
狆zhòng
狇mù
狈bèi
狉pī
狊jú
狋yí
狌shēng
狍páo
狎xiá
狏tuó
狐hú
狑líng
狒fèi
狓pí
狔nǐ
狕yǎo
狖yòu
狗gǒu
狘xuè
狙jū
狚dàn
狛bó
狜kǔ
狝xiǎn
狞níng
狟huán
狠hěn
狡jiǎo
狢hé
狣zhào
狤jí
狥xùn
狦shān
狧tà
狨róng
狩shòu
狪tóng
狫lǎo
独dú
狭xiá
狮shī
狯kuài
狰zhēng
狱yù
狲sūn
狳yú
狴bì
狵máng
狶xī
狷juàn
狸lí
狹xiá
狺yín
狻suān
狼láng
狽bèi
狾zhì
狿yán
猀shā
猁lì
猂hàn
猃xiǎn
猄jīng
猅pái
猆fēi
猇xiāo
猈bài
猉qí
猊ní
猋biāo
猌yìn
猍lái
猎liè
猏jiān
猐qiāng
猑kūn
猒yàn
猓guǒ
猔zòng
猕mí
猖chāng
猗yī
猘zhì
猙zhēng
猚yá
猛měng
猜cāi
猝cù
猞shē
猟liè
猠diǎn
猡luó
猢hú
猣zōng
猤guì
猥wěi
猦fēng
猧wō
猨yuán
猩xīng
猪zhū
猫māo
猬wèi
猭chuān
献xiàn
猯tuān
猰yà
猱náo
猲xiē
猳jiā
猴hóu
猵biān
猶yóu
猷yóu
猸méi
猹chá
猺yáo
猻sūn
猼bó
猽míng
猾huá
猿yuán
獀sōu
獁mà
獂yuán
獃dāi
獄yù
獅shī
獆háo
獇qiāng
獈yì
獉zhēn
獊cāng
獋háo
獌màn
獍jìng
獎jiǎng
獏mò
獐zhāng
獑chán
獒áo
獓áo
獔háo
獕cuī
獖bèn
獗jué
獘bì
獙bì
獚huáng
獛pú
獜lín
獝xù
獞tóng
獟yào
獠liáo
獡shuò
獢xiāo
獣shòu
獤dūn
獥jiào
獦gé
獧juàn
獨dú
獩huì
獪kuài
獫xiǎn
獬xiè
獭tǎ
獮xiǎn
獯xūn
獰níng
獱biān
獲huò
獳nòu
獴měng
獵liè
獶nǎo
獷guǎng
獸shòu
獹lú
獺tǎ
獻xiàn
獼mí
獽ráng
獾huān
獿nǎo
玀luó
玁xiǎn
玂qí
玃jué
玄xuán
玅miào
玆zī
率lǜ
玈lú
玉yù
玊sù
王wáng
玌qiú
玍gǎ
玎dīng
玏lè
玐bā
玑jī
玒hóng
玓dì
玔chuàn
玕gān
玖jiǔ
玗yú
玘qǐ
玙yú
玚chàng
玛mǎ
玜hóng
玝wǔ
玞fū
玟wén
玠jiè
玡yá
玢bīn
玣biàn
玤bàng
玥yuè
玦jué
玧mén
玨jué
玩wán
玪jiān
玫méi
玬dǎn
玭pín
玮wěi
环huán
现xiàn
玱qiāng
玲líng
玳dài
玴yì
玵án
玶píng
玷diàn
玸fú
玹xuán
玺xǐ
玻bō
玼cǐ
玽gǒu
玾jiǎ
玿sháo
珀pò
珁cí
珂kē
珃rǎn
珄shēng
珅shēn
珆yí
珇zǔ
珈jiā
珉mín
珊shān
珋liǔ
珌bì
珍zhēn
珎zhēn
珏jué
珐fà
珑lóng
珒jīn
珓jiào
珔jiàn
珕lì
珖guāng
珗xiān
珘zhōu
珙gǒng
珚yān
珛xiù
珜yáng
珝xǔ
珞luò
珟sù
珠zhū
珡qín
珢yín
珣xún
珤bǎo
珥ěr
珦xiàng
珧yáo
珨xiá
珩háng
珪guī
珫chōng
珬xù
班bān
珮pèi
珯lǎo
珰dāng
珱yīng
珲huī
珳wén
珴é
珵chéng
珶dì
珷wǔ
珸wú
珹chéng
珺jùn
珻méi
珼bèi
珽tǐng
現xiàn
珿chù
琀hán
琁xuán
琂yán
球qiú
琄xuàn
琅láng
理lǐ
琇xiù
琈fú
琉liú
琊yá
琋xī
琌líng
琍lí
琎jìn
琏liǎn
琐suǒ
琑suǒ
琒fēng
琓wán
琔diàn
琕pín
琖zhǎn
琗sè
琘mín
琙yù
琚jū
琛chēn
琜lái
琝mín
琞shèng
琟wéi
琠tiǎn
琡chù
琢zuó
琣běng
琤chēng
琥hǔ
琦qí
琧è
琨kūn
琩chāng
琪qí
琫běng
琬wǎn
琭lù
琮cóng
琯guǎn
琰yǎn
琱diāo
琲bèi
琳lín
琴qín
琵pí
琶pá
琷què
琸zhuó
琹qín
琺fà
琻jīn
琼qióng
琽dǔ
琾jiè
琿hún
瑀yǔ
瑁mào
瑂méi
瑃chūn
瑄xuān
瑅tí
瑆xīng
瑇dài
瑈róu
瑉mín
瑊jiān
瑋wěi
瑌ruǎn
瑍huàn
瑎xié
瑏chuān
瑐jiǎn
瑑zhuàn
瑒chàng
瑓liàn
瑔quán
瑕xiá
瑖duàn
瑗yuàn
瑘yá
瑙nǎo
瑚hú
瑛yīng
瑜yú
瑝huáng
瑞ruì
瑟sè
瑠liú
瑡shī
瑢róng
瑣suǒ
瑤yáo
瑥wēn
瑦wǔ
瑧zhēn
瑨jìn
瑩yíng
瑪mǎ
瑫tāo
瑬liú
瑭táng
瑮lì
瑯láng
瑰guī
瑱zhèn
瑲qiāng
瑳cuō
瑴jué
瑵zhǎo
瑶yáo
瑷ài
瑸bīn
瑹shū
瑺cháng
瑻kūn
瑼zhuān
瑽cōng
瑾jǐn
瑿yī
璀cuǐ
璁cōng
璂qí
璃lí
璄jǐng
璅suǒ
璆qiú
璇xuán
璈áo
璉liǎn
璊mén
璋zhāng
璌yín
璍yè
璎yīng
璏wèi
璐lù
璑wú
璒dēng
璓xiù
璔zēng
璕xún
璖qú
璗dàng
璘lín
璙liáo
璚qióng
璛sù
璜huáng
璝guī
璞pú
璟jǐng
璠fán
璡jìn
璢liú
璣jī
璤huì
璥jǐng
璦ài
璧bì
璨càn
璩qú
璪zǎo
璫dāng
璬jiǎo
璭gùn
璮tǎn
璯huì
環huán
璱sè
璲suì
璳tián
璴chǔ
璵yú
璶jìn
璷lú
璸bīn
璹shú
璺wèn
璻zuǐ
璼lán
璽xǐ
璾zī
璿xuán
瓀ruǎn
瓁wò
瓂gài
瓃léi
瓄dú
瓅lì
瓆zhì
瓇róu
瓈lí
瓉zàn
瓊qióng
瓋tì
瓌guī
瓍suí
瓎là
瓏lóng
瓐lú
瓑lì
瓒zàn
瓓làn
瓔yīng
瓕mí
瓖xiāng
瓗qióng
瓘guàn
瓙dào
瓚zàn
瓛huán
瓜guā
瓝bó
瓞dié
瓟bó
瓠hù
瓡zhí
瓢piáo
瓣bàn
瓤ráng
瓥lì
瓦wǎ
瓨xiáng
瓩qiān
瓪bǎn
瓫pén
瓬fǎng
瓭dǎn
瓮wèng
瓯ōu
瓲wa
瓳hú
瓴líng
瓵yí
瓶píng
瓷cí
瓸bǎi
瓹juān
瓺cháng
瓻chī
瓽dàng
瓾měng
瓿bù
甀zhuì
甁píng
甂biān
甃zhòu
甄zhēn
甆cí
甇yīng
甈qì
甉xián
甊lǒu
甋dì
甌ōu
甍méng
甎zhuān
甏bèng
甐lìn
甑zèng
甒wǔ
甓pì
甔dān
甕wèng
甖yīng
甗yǎn
甘gān
甙dài
甚shén
甛tián
甜tián
甝hán
甞cháng
生shēng
甠qíng
甡shēn
產chǎn
産chǎn
甤ruí
甥shēng
甦sū
甧shēn
用yòng
甩shuǎi
甪lù
甫fǔ
甬yǒng
甭béng
甮fèng
甯níng
田tián
由yóu
甲jiǎ
申shēn
甴zhá
电diàn
甶fú
男nán
甸diān
甹pīng
町tīng
画huà
甼tǐng
甽zhèn
甾zāi
甿méng
畀bì
畁bì
畂liù
畃xún
畄liú
畅chàng
畆mǔ
畇yún
畈fàn
畉fú
畊gēng
畋tián
界jiè
畍jiè
畎quǎn
畏wèi
畐fú
畑tián
畒mǔ
畓duō
畔pàn
畕jiāng
畖wā
畗dá
畘nán
留liú
畚běn
畛zhěn
畜chù
畝mǔ
畞mǔ
畟cè
畠tián
畡gāi
畢bì
畣dá
畤zhì
略lüè
畦qí
畧lüè
畨pān
畩yī
番fān
畫huà
畬shē
畭yú
畮mǔ
畯jùn
異yì
畱liú
畲shē
畳dié
畴chóu
畵huà
當dāng
畷zhuì
畸jī
畹wǎn
畺jiāng
畻chéng
畼chàng
畽tǔn
畾léi
畿jī
疀chā
疁liú
疂dié
疃tuǎn
疄lìn
疅jiāng
疆jiāng
疇chóu
疈pì
疉dié
疊dié
疋pǐ
疌jié
疍dàn
疎shū
疏shū
疐zhì
疑yí
疒nè
疓nǎi
疔dīng
疕bǐ
疖jiē
疗liáo
疘gāng
疙gē
疚jiù
疛zhǒu
疜xià
疝shàn
疞xū
疟nüè
疠lì
疡yáng
疢chèn
疣yóu
疤bā
疥jiè
疦jué
疧qí
疨xiā
疩cuì
疪bì
疫yì
疬lì
疭zòng
疮chuāng
疯fēng
疰zhù
疱pào
疲pí
疳gān
疴kē
疵cī
疶xuē
疷zhī
疸dǎn
疹zhěn
疺fá
疻zhǐ
疼téng
疽jū
疾jí
疿fèi
痀jū
痁shān
痂jiā
痃xuán
痄zhà
病bìng
痆niè
症zhèng
痈yōng
痉jìng
痊quán
痋téng
痌tōng
痍yí
痎jiē
痏wěi
痐huí
痑tān
痒yǎng
痓chì
痔zhì
痕hén
痖yǎ
痗mèi
痘dòu
痙jìng
痚xiāo
痛tòng
痜tū
痝máng
痞pǐ
痟xiāo
痠suān
痡fū
痢lì
痣zhì
痤cuó
痥duó
痦wù
痧shā
痨láo
痩shòu
痪huàn
痫xián
痬yì
痭bēng
痮zhàng
痯guǎn
痰tán
痱fèi
痲má
痳lín
痴chī
痵jì
痶tiǎn
痷ān
痸chì
痹bì
痺bì
痻mín
痼gù
痽duī
痾ē
痿wěi
瘀yū
瘁cuì
瘂yǎ
瘃zhú
瘄cù
瘅dān
瘆shèn
瘇zhǒng
瘈chì
瘉yù
瘊hóu
瘋fēng
瘌là
瘍yáng
瘎chén
瘏tú
瘐yǔ
瘑guō
瘒wén
瘓huàn
瘔kù
瘕jiǎ
瘖yīn
瘗yì
瘘lòu
瘙sào
瘚jué
瘛chì
瘜xī
瘝guān
瘞yì
瘟wēn
瘠jí
瘡chuāng
瘢bān
瘣huì
瘤liú
瘥chài
瘦shòu
瘧nüè
瘨diān
瘩dā
瘪biě
瘫tān
瘬zhàng
瘭biāo
瘮shèn
瘯cù
瘰luǒ
瘱yì
瘲zòng
瘳chōu
瘴zhàng
瘵zhài
瘶sòu
瘷sè
瘸qué
瘹diào
瘺lòu
瘻lòu
瘼mò
瘽qín
瘾yǐn
瘿yǐng
癀huáng
癁fú
療liáo
癃lóng
癄qiáo
癅liú
癆láo
癇xián
癈fèi
癉dān
癊yìn
癋hè
癌ái
癍bān
癎xián
癏guān
癐guì
癑nòng
癒yù
癓wéi
癔yì
癕yōng
癖pǐ
癗lěi
癘lì
癙shǔ
癚dàn
癛lǐn
癜diàn
癝lǐn
癞lài
癟biě
癠jì
癡chī
癢yǎng
癣xuǎn
癤jiē
癥zhēng
癦me
癧lì
癨huò
癩lài
癪jī
癫diān
癬xuǎn
癭yǐng
癮yǐn
癯qú
癰yōng
癱tān
癲diān
癳luǒ
癴luán
癵luán
癶bō
癷bō
癸guǐ
癹bá
発fā
登dēng
發fā
白bái
百bǎi
癿qié
皀jí
皁zào
皂zào
皃mào
的de
皅pā
皆jiē
皇huáng
皈guī
皉cǐ
皊líng
皋gāo
皌mò
皍jí
皎jiǎo
皏pěng
皐gāo
皑ái
皒é
皓hào
皔hàn
皕bì
皖wǎn
皗chóu
皘qiàn
皙xī
皚ái
皛xiǎo
皜hào
皝huàng
皞hào
皟zé
皠cuǐ
皡hào
皢xiǎo
皣yè
皤pó
皥hào
皦jiǎo
皧ài
皨xīng
皩huàng
皪lì
皫piǎo
皬hé
皭jiào
皮pí
皯gǎn
皰pào
皱zhòu
皲jūn
皳qiú
皴cūn
皵què
皶zhā
皷gǔ
皸jūn
皹jūn
皺zhòu
皻zhā
皼gǔ
皽zhāo
皾dú
皿mǐn
盀qǐ
盁yíng
盂yú
盃bēi
盄zhāo
盅zhōng
盆pén
盇hé
盈yíng
盉hé
益yì
盋bō
盌wǎn
盍hé
盎àng
盏zhǎn
盐yán
监jiān
盒hé
盓yū
盔kuī
盕fàn
盖gài
盗dào
盘pán
盙fǔ
盚qiú
盛shèng
盜dào
盝lù
盞zhǎn
盟méng
盠lí
盡jǐn
盢xù
監jiān
盤pán
盥guàn
盦ān
盧lú
盨xǔ
盩zhōu
盪dàng
盫ān
盬gǔ
盭lì
目mù
盯dīng
盰gàn
盱xū
盲máng
盳wàng
直zhí
盵qì
盶yuǎn
盷tián
相xiāng
盹dǔn
盺xīn
盻xì
盼pàn
盽fēng
盾dùn
盿mín
眀míng
省shěng
眂shì
眃yún
眄miǎn
眅pān
眆fǎng
眇miǎo
眈dān
眉méi
眊mào
看kàn
県xiàn
眍kōu
眎shì
眏yāng
眐zhēng
眑yǎo
眒shēn
眓huò
眔dà
眕zhěn
眖kuàng
眗jū
眘shèn
眙yí
眚shěng
眛mèi
眜mò
眝zhù
眞zhēn
真zhēn
眠mián
眡shì
眢yuān
眣dié
眤nì
眥zì
眦zì
眧chǎo
眨zhǎ
眩xuàn
眪bǐng
眫mǐ
眬lóng
眭suī
眮tóng
眯mī
眰diè
眱dì
眲nè
眳míng
眴xuàn
眵chī
眶kuàng
眷juàn
眸móu
眹zhèn
眺tiào
眻yáng
眼yǎn
眽mò
眾zhòng
眿mò
着zhe
睁zhēng
睂méi
睃suō
睄shào
睅hàn
睆huàn
睇dì
睈chěng
睉cuó
睊juàn
睋é
睌mǎn
睍xiàn
睎xī
睏kùn
睐lài
睑jiǎn
睒shǎn
睓tiǎn
睔gùn
睕wǎn
睖lèng
睗shì
睘qióng
睙liè
睚yá
睛jīng
睜zhēng
睝lí
睞lài
睟suì
睠juàn
睡shuì
睢suī
督dū
睤bì
睥pì
睦mù
睧hūn
睨nì
睩lù
睪yì
睫jié
睬cǎi
睭zhǒu
睮yú
睯hūn
睰mà
睱xià
睲xǐng
睳huī
睴gùn
睵zāi
睶chǔn
睷jiān
睸mèi
睹dǔ
睺hóu
睻xuān
睼tiàn
睽kuí
睾gāo
睿ruì
瞀mào
瞁xù
瞂fá
瞃wò
瞄miáo
瞅chǒu
瞆kuì
瞇mī
瞈wěng
瞉kòu
瞊dàng
瞋chēn
瞌kē
瞍sǒu
瞎xiā
瞏qióng
瞐mò
瞑míng
瞒mán
瞓shuì
瞔zé
瞕zhàng
瞖yì
瞗diāo
瞘kōu
瞙mò
瞚shùn
瞛cōng
瞜lōu
瞝chī
瞞mán
瞟piǎo
瞠chēng
瞡guī
瞢méng
瞣wàn
瞤rún
瞥piē
瞦xī
瞧qiáo
瞨pú
瞩zhǔ
瞪dèng
瞫shěn
瞬shùn
瞭liǎo
瞮chè
瞯xián
瞰kàn
瞱yè
瞲xù
瞳tóng
瞴móu
瞵lín
瞶guì
瞷jiàn
瞸yè
瞹ài
瞺huì
瞻zhān
瞼jiǎn
瞽gǔ
瞾zhào
瞿qú
矀méi
矁chǒu
矂sào
矃nǐng
矄xūn
矅yào
矆huò
矇méng
矈mián
矉pín
矊mián
矋lěi
矌kuàng
矍jué
矎xuān
矏mián
矐huò
矑lú
矒méng
矓lóng
矔guàn
矕mǎn
矖xǐ
矗chù
矘tǎng
矙kàn
矚zhǔ
矛máo
矜jīn
矝jīn
矞yù
矟shuò
矠zé
矡jué
矢shǐ
矣yǐ
矤shěn
知zhī
矦hóu
矧shěn
矨yǐng
矩jǔ
矪zhōu
矫jiǎo
矬cuó
短duǎn
矮ǎi
矯jiǎo
矰zēng
矱yuē
矲bà
石shí
矴dìng
矵qì
矶jī
矷zǐ
矸gān
矹wù
矺zhé
矻kū
矼gāng
矽xì
矾fán
矿kuàng
砀dàng
码mǎ
砂shā
砃dān
砄jué
砅lì
砆fū
砇mín
砈ě
砉huò
砊kāng
砋zhǐ
砌qì
砍kǎn
砎jiè
砏bīn
砐è
砑yà
砒pī
砓zhé
研yán
砕suì
砖zhuān
砗chē
砘dùn
砙wǎ
砚yàn
砛jīn
砜fēng
砝fá
砞mò
砟zhǎ
砠jū
砡yù
砢kē
砣tuó
砤tuó
砥dǐ
砦zhài
砧zhēn
砨è
砩fú
砪mǔ
砫zhù
砬lá
砭biān
砮nǔ
砯pīng
砰pēng
砱líng
砲pào
砳lè
破pò
砵bō
砶pò
砷shēn
砸zá
砹ài
砺lì
砻lóng
砼tóng
砽yòng
砾lì
砿kuàng
础chǔ
硁kēng
硂quán
硃zhū
硄kuāng
硅guī
硆è
硇náo
硈qià
硉lù
硊wěi
硋ài
硌gè
硍xiàn
硎xíng
硏yán
硐dòng
硑pēng
硒xī
硓lǎo
硔hóng
硕shuò
硖xiá
硗qiāo
硘qing
硙wéi
硚qiáo
硛yì
硜kēng
硝xiāo
硞què
硟chàn
硠láng
硡hōng
硢yú
硣xiāo
硤xiá
硥mǎng
硦luò
硧yǒng
硨chē
硩chè
硪wò
硫liú
硬yìng
硭máng
确què
硯yàn
硰shā
硱kǔn
硲yù
硳chì
硴huā
硵lǔ
硶chěn
硷jiǎn
硸nüè
硹sōng
硺zhuó
硻kēng
硼péng
硽yān
硾zhuì
硿kōng
碀chéng
碁qí
碂zòng
碃qìng
碄lín
碅jūn
碆bō
碇dìng
碈mín
碉diāo
碊jiān
碋hè
碌lù
碍ài
碎suì
碏què
碐léng
碑bēi
碒yín
碓duì
碔wǔ
碕qí
碖lǔn
碗wǎn
碘diǎn
碙náo
碚bèi
碛qì
碜chěn
碝ruǎn
碞yán
碟dié
碠dìng
碡dú
碢tuó
碣jié
碤yīng
碥biǎn
碦kè
碧bì
碨wèi
碩shuò
碪zhēn
碫duàn
碬xiá
碭dàng
碮tí
碯nǎo
碰pèng
碱jiǎn
碲dì
碳tàn
碴chá
碵tián
碶qì
碷dùn
碸fēng
碹xuàn
確què
碻què
碼mǎ
碽gōng
碾niǎn
碿sù
磀é
磁cí
磂liú
磃sī
磄táng
磅bàng
磆huá
磇pī
磈wěi
磉sǎng
磊lěi
磋cuō
磌tián
磍xiá
磎xī
磏lián
磐pán
磑wéi
磒yǔn
磓duī
磔zhé
磕kē
磖lá
磗zhuān
磘yáo
磙gǔn
磚zhuān
磛chán
磜qì
磝áo
磞pēng
磟liù
磠lǔ
磡kàn
磢chuǎng
磣chěn
磤yǐn
磥lěi
磦biāo
磧qì
磨mó
磩qì
磪cuī
磫zōng
磬qìng
磭chuò
磮lún
磯jī
磰shàn
磱láo
磲qú
磳zēng
磴dèng
磵jiàn
磶xì
磷lín
磸dìng
磹tán
磺huáng
磻pán
磼zá
磽qiāo
磾dī
磿lì
礀jiàn
礁jiāo
礂xī
礃zhǎng
礄qiáo
礅dūn
礆jiǎn
礇yù
礈zhuì
礉hé
礊kè
礋zé
礌léi
礍jié
礎chǔ
礏yè
礐què
礑dàng
礒yǐ
礓jiāng
礔pī
礕pī
礖yù
礗pīn
礘è
礙ài
礚kē
礛jiān
礜yù
礝ruǎn
礞méng
礟pào
礠cí
礡bó
礢yǎng
礣mà
礤cǎ
礥xián
礦kuàng
礧léi
礨lěi
礩zhì
礪lì
礫lì
礬fán
礭què
礮pào
礯yīng
礰lì
礱lóng
礲lóng
礳mò
礴bó
礵shuāng
礶guàn
礷lán
礸cǎ
礹yán
示shì
礻shì
礼lǐ
礽réng
社shè
礿yuè
祀sì
祁qí
祂tā
祃mà
祄xiè
祅yāo
祆xiān
祇qí
祈qí
祉zhǐ
祊bēng
祋duì
祌zhòng
祍rèn
祎yī
祏shí
祐yòu
祑zhì
祒tiáo
祓fú
祔fù
祕mì
祖zǔ
祗zhī
祘suàn
祙mèi
祚zuò
祛qū
祜hù
祝zhù
神shén
祟suì
祠cí
祡chái
祢mí
祣lǚ
祤yǔ
祥xiáng
祦wú
祧tiāo
票piào
祩zhù
祪guǐ
祫xiá
祬zhī
祭jì
祮gào
祯zhēn
祰gào
祱shuì
祲jìn
祳shèn
祴gāi
祵kǔn
祶dì
祷dǎo
祸huò
祹táo
祺qí
祻gù
祼guàn
祽zuì
祾líng
祿lù
禀bǐng
禁jìn
禂dǎo
禃zhí
禄lù
禅chán
禆bì
禇zhě
禈huī
禉yǒu
禊xì
禋yīn
禌zī
禍huò
禎zhēn
福fú
禐yuàn
禑wú
禒xiǎn
禓yáng
禔zhī
禕yī
禖méi
禗sī
禘dì
禙bèi
禚zhuó
禛zhēn
禜yǒng
禝jì
禞gào
禟táng
禠sī
禡mà
禢tà
禣fù
禤xuān
禥qí
禦yù
禧xǐ
禨jī
禩sì
禪chán
禫dàn
禬guì
禭suì
禮lǐ
禯nóng
禰mí
禱dǎo
禲lì
禳ráng
禴yuè
禵tí
禶zàn
禷lèi
禸róu
禹yǔ
禺yú
离lí
禼xiè
禽qín
禾hé
禿tū
秀xiù
私sī
秂rén
秃tū
秄zǐ
秅chá
秆gǎn
秇yì
秈xiān
秉bǐng
秊nián
秋qiū
秌qiū
种zhǒng
秎fèn
秏hào
秐yún
科kē
秒miǎo
秓zhī
秔jīng
秕bǐ
秖zhī
秗yù
秘mì
秙kù
秚bàn
秛pī
秜ní
秝lì
秞yóu
租zū
秠pī
秡bó
秢líng
秣mò
秤chèng
秥nián
秦qín
秧yāng
秨zuó
秩zhì
秪zhī
秫shú
秬jù
秭zǐ
秮huó
积jī
称chēng
秱tóng
秲zhì
秳huó
秴hé
秵yīn
秶zī
秷zhì
秸jiē
秹rěn
秺dù
移yí
秼zhū
秽huì
秾nóng
秿fù
稀xī
稁gǎo
稂láng
稃fū
稄xùn
稅shuì
稆lǚ
稇kǔn
稈gǎn
稉jīng
稊tí
程chéng
稌tú
稍shāo
税shuì
稏yà
稐lǔn
稑lù
稒gù
稓zuó
稔rěn
稕zhùn
稖bàng
稗bài
稘jī
稙zhī
稚zhì
稛kǔn
稜léng
稝péng
稞kē
稟bǐng
稠chóu
稡zuì
稢yù
稣sū
稤lüè
稥xiāng
稦yī
稧xì
稨biǎn
稩jì
稪fú
稫pì
稬nuò
稭jiē
種zhǒng
稯zōng
稰xǔ
稱chēng
稲dào
稳wěn
稴xián
稵zī
稶yù
稷jì
稸xù
稹zhěn
稺zhì
稻dào
稼jià
稽jī
稾gǎo
稿gǎo
穀gǔ
穁róng
穂suì
穃rong
穄jì
穅kāng
穆mù
穇cǎn
穈méi
穉zhì
穊jì
穋lù
穌sū
積jī
穎yǐng
穏wěn
穐qiū
穑sè
穒hè
穓yì
穔huáng
穕qiè
穖jǐ
穗suì
穘xiāo
穙pú
穚jiāo
穛zhuō
穜zhǒng
穝zui
穞lǚ
穟suì
穠nóng
穡sè
穢huì
穣ráng
穤nuò
穥yù
穦pīn
穧jì
穨tuí
穩wěn
穪chēng
穫huò
穬kuàng
穭lǚ
穮biāo
穯sè
穰ráng
穱zhuō
穲lí
穳cuán
穴xué
穵wā
究jiū
穷qióng
穸xī
穹qióng
空kōng
穻yū
穼shēn
穽jǐng
穾yào
穿chuān
窀zhūn
突tū
窂láo
窃qiè
窄zhǎi
窅yǎo
窆biǎn
窇báo
窈yǎo
窉bǐng
窊wā
窋zhú
窌jiào
窍qiào
窎diào
窏wū
窐guī
窑yáo
窒zhì
窓chuāng
窔yào
窕tiǎo
窖jiào
窗chuāng
窘jiǒng
窙xiāo
窚chéng
窛kòu
窜cuàn
窝wō
窞dàn
窟kū
窠kē
窡zhuó
窢xū
窣sū
窤guān
窥kuī
窦dòu
窧zhuo
窨xūn
窩wō
窪wā
窫yà
窬yú
窭jù
窮qióng
窯yáo
窰yáo
窱tiǎo
窲cháo
窳yǔ
窴tián
窵diào
窶jù
窷liào
窸xī
窹wù
窺kuī
窻chuāng
窼zhāo
窽kuǎn
窾kuǎn
窿lóng
竀chēng
竁cuì
竂liáo
竃zào
竄cuàn
竅qiào
竆qióng
竇dòu
竈zào
竉lǒng
竊qiè
立lì
竌chù
竍shí
竎fù
竏qiān
竐chù
竑hóng
竒qí
竓háo
竔shēng
竕fēn
竖shù
竗miào
竘qǔ
站zhàn
竚zhù
竛líng
竜lóng
竝bìng
竞jìng
竟jìng
章zhāng
竡bǎi
竢sì
竣jùn
竤hóng
童tóng
竦sǒng
竧jìng
竨diào
竩yì
竪shù
竫jìng
竬qǔ
竭jié
竮pīng
端duān
竰lí
竱zhuǎn
竲céng
竳dēng
竴cūn
竵wāi
競jìng
竷kǎn
竸jìng
竹zhú
竺zhú
竻lè
竼péng
竽yú
竾chí
竿gān
笀máng
笁zhú
笂wán
笃dǔ
笄jī
笅jiǎo
笆bā
笇suàn
笈jí
笉qǐn
笊zhào
笋sǔn
笌yá
笍zhuì
笎yuán
笏hù
笐háng
笑xiào
笒cén
笓bì
笔bǐ
笕jiǎn
笖yǐ
笗dōng
笘shān
笙shēng
笚dā
笛dí
笜zhú
笝nà
笞chī
笟gū
笠lì
笡qiè
笢mǐn
笣bāo
笤tiáo
笥sì
符fú
笧cè
笨bèn
笩fá
笪dá
笫zǐ
第dì
笭líng
笮zé
笯nú
笰fú
笱gǒu
笲fán
笳jiā
笴gǎn
笵fàn
笶shǐ
笷mǎo
笸pǒ
笹ti
笺jiān
笻qióng
笼lóng
笽mǐn
笾biān
笿luò
筀guì
筁qū
筂chí
筃yīn
筄yào
筅xiǎn
筆bǐ
筇qióng
筈kuò
等děng
筊xiáo
筋jīn
筌quán
筍sǔn
筎rú
筏fá
筐kuāng
筑zhù
筒tǒng
筓jī
答dá
筕háng
策cè
筗zhòng
筘kòu
筙lái
筚bì
筛shāi
筜dāng
筝zhēng
筞cè
筟fū
筠yún
筡tú
筢pá
筣lí
筤láng
筥jǔ
筦guǎn
筧jiǎn
筨hán
筩tóng
筪xiá
筫zhì
筬chéng
筭suàn
筮shì
筯zhù
筰zuó
筱xiǎo
筲shāo
筳tíng
筴cè
筵yán
筶gào
筷kuài
筸gān
筹chóu
筺kuāng
筻gàng
筼yún
筽o
签qiān
筿xiǎo
简jiǎn
箁póu
箂lái
箃zōu
箄bǐ
箅bì
箆bì
箇gè
箈tái
箉guǎi
箊yū
箋jiān
箌dào
箍gū
箎chí
箏zhēng
箐qìng
箑shà
箒zhǒu
箓lù
箔bó
箕jī
箖lín
算suàn
箘jùn
箙fú
箚zhá
箛gū
箜kōng
箝qián
箞qiān
箟jùn
箠chuí
管guǎn
箢yuān
箣cè
箤zú
箥bǒ
箦zé
箧qiè
箨tuò
箩luó
箪dān
箫xiāo
箬ruò
箭jiàn
箮xuān
箯biān
箰sǔn
箱xiāng
箲xiǎn
箳píng
箴zhēn
箵xīng
箶hú
箷yí
箸zhù
箹yuē
箺chūn
箻lǜ
箼wū
箽dǒng
箾shuò
箿jí
節jié
篁huáng
篂xīng
篃mèi
範fàn
篅chuán
篆zhuàn
篇piān
篈fēng
築zhù
篊huáng
篋qiè
篌hóu
篍qiū
篎miǎo
篏qiàn
篐gū
篑kuì
篒shi
篓lǒu
篔yún
篕hé
篖táng
篗yuè
篘chōu
篙gāo
篚fěi
篛ruò
篜zhēng
篝gōu
篞niè
篟qiàn
篠xiǎo
篡cuàn
篢lǒng
篣péng
篤dǔ
篥lì
篦bì
篧zhuó
篨chú
篩shāi
篪chí
篫zhù
篬qiāng
篭lóng
篮lán
篯jiān
篰bù
篱lí
篲huì
篳bì
篴dí
篵cōng
篶yān
篷péng
篸cǎn
篹zhuàn
篺pí
篻piǎo
篼dōu
篽yù
篾miè
篿tuán
簀zé
簁shāi
簂guì
簃yí
簄hù
簅chǎn
簆kòu
簇cù
簈píng
簉zào
簊jī
簋guǐ
簌sù
簍lǒu
簎cè
簏lù
簐niǎn
簑suō
簒cuàn
簓diāo
簔suō
簕lè
簖duàn
簗zhù
簘xiāo
簙bó
簚mì
簛shāi
簜dàng
簝liáo
簞dān
簟diàn
簠fǔ
簡jiǎn
簢mǐn
簣kuì
簤dài
簥jiāo
簦dēng
簧huáng
簨sǔn
簩láo
簪zān
簫xiāo
簬lù
簭shì
簮zān
簯qi
簰pái
簱qí
簲pái
簳gǎn
簴jù
簵lù
簶lù
簷yán
簸bǒ
簹dāng
簺sài
簻zhuā
簼gōu
簽qiān
簾lián
簿bù
籀zhòu
籁lài
籂shi
籃lán
籄kuì
籅yú
籆yuè
籇háo
籈zhēn
籉tái
籊tì
籋niè
籌chóu
籍jí
籎yí
籏qí
籐téng
籑zhuàn
籒zhòu
籓fān
籔sǒu
籕zhòu
籖qian
籗zhuó
籘téng
籙lù
籚lú
籛jiǎn
籜tuò
籝yíng
籞yù
籟lài
籠lóng
籡qiè
籢lián
籣lán
籤qiān
籥yuè
籦zhōng
籧qú
籨lián
籩biān
籪duàn
籫zuǎn
籬lí
籭sī
籮luó
籯yíng
籰yuè
籱zhuó
籲yù
米mǐ
籴dí
籵fán
籶shēn
籷zhé
籸shēn
籹nǚ
籺hé
类lèi
籼xiān
籽zǐ
籾ní
籿cùn
粀zhàng
粁qiān
粂zhāi
粃bǐ
粄bǎn
粅wù
粆shā
粇kāng
粈róu
粉fěn
粊bì
粋cuì
粌yin
粍zhé
粎mǐ
粏tai
粐hù
粑bā
粒lì
粓gān
粔jù
粕pò
粖mò
粗cū
粘zhān
粙zhòu
粚chī
粛sù
粜tiào
粝lì
粞xī
粟sù
粠hóng
粡tóng
粢zī
粣cè
粤yuè
粥zhōu
粦lín
粧zhuāng
粨bǎi
粩lāo
粪fèn
粫ér
粬qū
粭hé
粮liáng
粯xiàn
粰fú
粱liáng
粲càn
粳jīng
粴lǐ
粵yuè
粶lù
粷jú
粸qí
粹cuì
粺bài
粻zhāng
粼lín
粽zòng
精jīng
粿guǒ
糀huā
糁sǎn
糂sǎn
糃táng
糄biǎn
糅róu
糆miàn
糇hóu
糈xǔ
糉zòng
糊hú
糋jiàn
糌zān
糍cí
糎lí
糏xiè
糐fū
糑nuò
糒bèi
糓gǔ
糔xiǔ
糕gāo
糖táng
糗qiǔ
糘jiā
糙cāo
糚zhuāng
糛táng
糜mí
糝sǎn
糞fèn
糟zāo
糠kāng
糡jiàng
糢mó
糣sǎn
糤sǎn
糥nuò
糦xī
糧liáng
糨jiàng
糩kuài
糪bò
糫huán
糬shǔ
糭zòng
糮xiàn
糯nuò
糰tuán
糱niè
糲lì
糳zuò
糴dí
糵niè
糶tiào
糷làn
糸mì
糹sī
糺jiū
系xì
糼gōng
糽zhěng
糾jiū
糿yòu
紀jì
紁chà
紂zhòu
紃xún
約yuē
紅hóng
紆yū
紇hé
紈wán
紉rèn
紊wěn
紋wén
紌qiú
納nà
紎zī
紏tǒu
紐niǔ
紑fóu
紒jì
紓shū
純chún
紕pī
紖zhèn
紗shā
紘hóng
紙zhǐ
級jí
紛fēn
紜yún
紝rèn
紞dǎn
紟jīn
素sù
紡fǎng
索suǒ
紣cuì
紤jiǔ
紥zā
紦ba
紧jǐn
紨fū
紩zhì
紪qī
紫zǐ
紬chóu
紭hóng
紮zā
累lèi
細xì
紱fú
紲xiè
紳shēn
紴bō
紵zhù
紶qū
紷líng
紸zhù
紹shào
紺gàn
紻yǎng
紼fú
紽tuó
紾zhěn
紿dài
絀chù
絁shī
終zhōng
絃xián
組zǔ
絅jiōng
絆bàn
絇qú
絈mò
絉shù
絊zuì
絋kuàng
経jīng
絍rèn
絎háng
絏xiè
結jié
絑zhū
絒chóu
絓guà
絔bǎi
絕jué
絖kuàng
絗hú
絘cì
絙huán
絚gēng
絛tāo
絜jié
絝kù
絞jiǎo
絟quán
絠gǎi
絡luò
絢xuàn
絣bēng
絤xiàn
絥fú
給gěi
絧dòng
絨róng
絩tiào
絪yīn
絫lěi
絬xiè
絭juàn
絮xù
絯gāi
絰dié
統tǒng
絲sī
絳jiàng
絴xiáng
絵huì
絶jué
絷zhí
絸jiǎn
絹juàn
絺chī
絻miǎn
絼zhèn
絽lǚ
絾chéng
絿qiú
綀shū
綁bǎng
綂tǒng
綃xiāo
綄huán
綅qīn
綆gěng
綇xiǔ
綈tí
綉tòu
綊xié
綋hóng
綌xì
綍fú
綎tīng
綏suī
綐duì
綑kǔn
綒fū
經jīng
綔hù
綕zhī
綖yán
綗jiǒng
綘féng
継jì
続xù
綛rěn
綜zōng
綝chēn
綞duǒ
綟lì
綠lǜ
綡liáng
綢chóu
綣quǎn
綤shào
綥qí
綦qí
綧zhǔn
綨qí
綩wǎn
綪qiàn
綫xiàn
綬shòu
維wéi
綮qǐ
綯táo
綰wǎn
綱gāng
網wǎng
綳bēng
綴zhuì
綵cǎi
綶guǒ
綷cuì
綸lún
綹liǔ
綺qǐ
綻zhàn
綼bì
綽chuò
綾líng
綿mián
緀qī
緁qiè
緂tián
緃zōng
緄gǔn
緅zōu
緆xī
緇zī
緈xìng
緉liǎng
緊jǐn
緋fēi
緌ruí
緍mín
緎yù
総zǒng
緐fán
緑lǜ
緒xù
緓yīng
緔shàng
緕qi
緖xù
緗xiāng
緘jiān
緙kè
線xiàn
緛ruǎn
緜mián
緝jī
緞duàn
緟chóng
締dì
緡mín
緢miáo
緣yuán
緤xiè
緥bǎo
緦sī
緧qiū
編biān
緩huǎn
緪gēng
緫cōng
緬miǎn
緭wèi
緮fù
緯wěi
緰tóu
緱gōu
緲miǎo
緳xié
練liàn
緵zōng
緶biàn
緷yùn
緸yīn
緹tí
緺guā
緻zhì
緼yùn
緽chēng
緾chán
緿dài
縀xiá
縁yuán
縂zǒng
縃xū
縄shéng
縅wēi
縆gēng
縇xuān
縈yíng
縉jìn
縊yì
縋zhuì
縌nì
縍bāng
縎gǔ
縏pán
縐zhòu
縑jiān
縒cī
縓quán
縔shuǎng
縕yùn
縖xiá
縗cuī
縘xī
縙róng
縚tāo
縛fù
縜yún
縝chēn
縞gǎo
縟rù
縠hú
縡zài
縢téng
縣xiàn
縤sù
縥zhěn
縦zòng
縧tāo
縨huǎng
縩cài
縪bì
縫fèng
縬cù
縭lí
縮suō
縯yǎn
縰xǐ
縱zòng
縲léi
縳juàn
縴qiàn
縵màn
縶zhí
縷lǚ
縸mù
縹piǎo
縺lián
縻mí
縼xuàn
總zǒng
績jī
縿shān
繀suì
繁fán
繂lǜ
繃běng
繄yī
繅sāo
繆móu
繇yáo
繈qiǎng
繉hún
繊xiān
繋jì
繌sha
繍xiù
繎rán
繏xuàn
繐suì
繑qiāo
繒zēng
繓zuǒ
織zhī
繕shàn
繖sǎn
繗lín
繘yù
繙fān
繚liáo
繛chuò
繜zūn
繝jiàn
繞rào
繟chǎn
繠ruǐ
繡xiù
繢huì
繣huà
繤zuǎn
繥xī
繦qiǎng
繧yun
繨da
繩shéng
繪huì
繫xì
繬sè
繭jiǎn
繮jiāng
繯huán
繰zǎo
繱cōng
繲xiè
繳jiǎo
繴bì
繵dàn
繶yì
繷nǒng
繸suì
繹yì
繺shǎi
繻xū
繼jì
繽bīn
繾qiǎn
繿lán
纀pú
纁xūn
纂zuǎn
纃qí
纄péng
纅yào
纆mò
纇lèi
纈xié
纉zuǎn
纊kuàng
纋yōu
續xù
纍léi
纎xiān
纏chán
纐jiǎo
纑lú
纒chán
纓yīng
纔cái
纕rǎng
纖xiān
纗zuī
纘zuǎn
纙luò
纚lí
纛dào
纜lǎn
纝léi
纞liàn
纟sī
纠jiū
纡yū
红hóng
纣zhòu
纤xiān
纥gē
约yuē
级jí
纨wán
纩kuàng
纪jì
纫rèn
纬wěi
纭yún
纮hóng
纯chún
纰pī
纱shā
纲gāng
纳nà
纴rèn
纵zòng
纶lún
纷fēn
纸zhǐ
纹wén
纺fǎng
纻zhù
纼zhèn
纽niǔ
纾shū
线xiàn
绀gàn
绁xiè
绂fú
练liàn
组zǔ
绅shēn
细xì
织zhī
终zhōng
绉zhòu
绊bàn
绋fú
绌chù
绍shào
绎yì
经jīng
绐dài
绑bǎng
绒róng
结jié
绔kù
绕rào
绖dié
绗háng
绘huì
给gěi
绚xuàn
绛jiàng
络luò
绝jué
绞jiǎo
统tǒng
绠gěng
绡xiāo
绢juàn
绣xiù
绤xì
绥suí
绦tāo
继jì
绨tí
绩jì
绪xù
绫líng
绬yīng
续xù
绮qǐ
绯fēi
绰chuò
绱shàng
绲gǔn
绳shéng
维wéi
绵mián
绶shòu
绷bēng
绸chóu
绹táo
绺liǔ
绻quǎn
综zōng
绽zhàn
绾wǎn
绿lǜ
缀zhuì
缁zī
缂kè
缃xiāng
缄jiān
缅miǎn
缆lǎn
缇tí
缈miǎo
缉jī
缊yūn
缋huì
缌sī
缍duǒ
缎duàn
缏biàn
缐xiàn
缑gōu
缒zhuì
缓huǎn
缔dì
缕lǚ
编biān
缗mín
缘yuán
缙jìn
缚fù
缛rù
缜zhěn
缝fèng
缞cuī
缟gǎo
缠chán
缡lí
缢yì
缣jiān
缤bīn
缥piāo
缦màn
缧léi
缨yīng
缩suō
缪móu
缫sāo
缬xié
缭liáo
缮shàn
缯zēng
缰jiāng
缱qiǎn
缲qiāo
缳huán
缴jiǎo
缵zuǎn
缶fǒu
缷xiè
缸gāng
缹fǒu
缺quē
缻fǒu
缼qi
缽bō
缾píng
缿xiàng
罀zhao
罁gāng
罂yīng
罃yīng
罄qìng
罅xià
罆guàn
罇zūn
罈tán
罉chēng
罊qì
罋wèng
罌yīng
罍léi
罎tán
罏lú
罐guàn
网wǎng
罒wǎng
罓gāng
罔wǎng
罕hǎn
罖luó
罗luó
罘fú
罙shēn
罚fá
罛gū
罜zhǔ
罝jū
罞máo
罟gǔ
罠mín
罡gāng
罢bà
罣guà
罤tí
罥juàn
罦fú
罧shèn
罨yǎn
罩zhào
罪zuì
罫guà
罬zhuó
罭yù
置zhì
罯ǎn
罰fá
罱lǎn
署shǔ
罳sī
罴pí
罵mà
罶liǔ
罷bà
罸fá
罹lí
罺cháo
罻wèi
罼bì
罽jì
罾zēng
罿chōng
羀liǔ
羁jī
羂juàn
羃mì
羄zhào
羅luó
羆pí
羇jī
羈jī
羉luán
羊yáng
羋mǐ
羌qiāng
羍dá
美měi
羏yáng
羐yǒu
羑yǒu
羒fén
羓bā
羔gāo
羕yàng
羖gǔ
羗qiāng
羘zāng
羙gāo
羚líng
羛yì
羜zhù
羝dī
羞xiū
羟qiǎng
羠yí
羡xiàn
羢róng
羣qún
群qún
羥qiǎng
羦huán
羧suō
羨xiàn
義yì
羪yang
羫qiāng
羬qián
羭yú
羮gēng
羯jié
羰tāng
羱yuán
羲xī
羳fán
羴shān
羵fén
羶shān
羷liǎn
羸léi
羹gēng
羺nóu
羻qiàng
羼chàn
羽yǔ
羾gòng
羿yì
翀chōng
翁wēng
翂fēn
翃hóng
翄chì
翅chì
翆cuì
翇fú
翈xiá
翉běn
翊yì
翋lā
翌yì
翍pī
翎líng
翏liù
翐zhì
翑qú
習xí
翓xié
翔xiáng
翕xī
翖xī
翗ké
翘qiào
翙huì
翚huī
翛xiāo
翜shà
翝hóng
翞jiāng
翟dí
翠cuì
翡fěi
翢dào
翣shà
翤chì
翥zhù
翦jiǎn
翧xuān
翨chì
翩piān
翪zōng
翫wán
翬huī
翭hóu
翮hé
翯hè
翰hàn
翱áo
翲piāo
翳yì
翴lián
翵hóu
翶áo
翷lín
翸pěn
翹qiào
翺áo
翻fān
翼yì
翽huì
翾xuān
翿dào
耀yào
老lǎo
耂lǎo
考kǎo
耄mào
者zhě
耆qí
耇gǒu
耈gǒu
耉gǒu
耊dié
耋dié
而ér
耍shuǎ
耎ruǎn
耏nài
耐nài
耑duān
耒lěi
耓tīng
耔zǐ
耕gēng
耖chào
耗hào
耘yún
耙bà
耚pī
耛yí
耜sì
耝qù
耞jiā
耟jù
耠huō
耡chú
耢lào
耣lǔn
耤jí
耥tāng
耦ǒu
耧lóu
耨nòu
耩jiǎng
耪pǎng
耫zhá
耬lóu
耭jī
耮lào
耯huò
耰yōu
耱mò
耲huái
耳ěr
耴yì
耵dīng
耶yé
耷dā
耸sǒng
耹qín
耺yún
耻chǐ
耼dān
耽dān
耾hóng
耿gěng
聀zhí
聁pàn
聂niè
聃dān
聄zhěn
聅chè
聆líng
聇zhēng
聈yǒu
聉wà
聊liáo
聋lóng
职zhí
聍níng
聎tiāo
聏ér
聐yà
聑tiē
聒guā
聓xù
联lián
聕hào
聖shèng
聗liè
聘pìn
聙jīng
聚jù
聛bǐ
聜dǐ
聝guó
聞wén
聟xù
聠pīng
聡cōng
聢dìng
聣ní
聤tíng
聥jǔ
聦cōng
聧kuī
聨lián
聩kuì
聪cōng
聫lián
聬wěng
聭kuì
聮lián
聯lián
聰cōng
聱áo
聲shēng
聳sǒng
聴tīng
聵kuì
聶niè
職zhí
聸dān
聹níng
聺qié
聻nǐ
聼tīng
聽tīng
聾lóng
聿yù
肀yù
肁zhào
肂sì
肃sù
肄yì
肅sù
肆sì
肇zhào
肈zhào
肉ròu
肊yì
肋lē
肌jī
肍qiú
肎kěn
肏cào
肐gē
肑bó
肒huàn
肓huāng
肔chǐ
肕rèn
肖xiào
肗rǔ
肘zhǒu
肙yuàn
肚dù
肛gāng
肜róng
肝gān
肞chā
肟wò
肠cháng
股gǔ
肢zhī
肣hán
肤fū
肥féi
肦fén
肧pēi
肨pàng
肩jiān
肪fáng
肫zhūn
肬yóu
肭nà
肮āng
肯kěn
肰rán
肱gōng
育yù
肳wěn
肴yáo
肵qí
肶pí
肷qiǎn
肸xī
肹xī
肺fèi
肻kěn
肼jǐng
肽tài
肾shèn
肿zhǒng
胀zhàng
胁xié
胂shèn
胃wèi
胄zhòu
胅dié
胆dǎn
胇fèi
胈bá
胉bó
胊qú
胋tián
背bèi
胍guā
胎tāi
胏zǐ
胐fěi
胑zhī
胒nì
胓píng
胔zì
胕fǔ
胖pàng
胗zhēn
胘xián
胙zuò
胚pēi
胛jiǎ
胜shèng
胝zhī
胞bāo
胟mǔ
胠qū
胡hú
胢kē
胣chǐ
胤yìn
胥xū
胦yāng
胧lóng
胨dòng
胩kǎ
胪lú
胫jìng
胬nǔ
胭yān
胮pāng
胯kuà
胰yí
胱guāng
胲hǎi
胳gē
胴dòng
胵chī
胶jiāo
胷xiōng
胸xiōng
胹ér
胺àn
胻héng
胼pián
能néng
胾zì
胿guī
脀chéng
脁tiǎo
脂zhī
脃cuì
脄méi
脅xié
脆cuì
脇xié
脈mài
脉mài
脊jí
脋xié
脌nin
脍kuài
脎sà
脏zàng
脐qí
脑nǎo
脒mǐ
脓nóng
脔luán
脕wàn
脖bó
脗wěn
脘wǎn
脙xiū
脚jiǎo
脛jìng
脜yǒu
脝hēng
脞cuǒ
脟liè
脠shān
脡tǐng
脢méi
脣chún
脤shèn
脥qiǎn
脦de
脧juān
脨cù
脩xiū
脪xìn
脫tuō
脬pāo
脭chéng
脮něi
脯pú
脰dòu
脱tuō
脲niào
脳nǎo
脴pǐ
脵gǔ
脶luó
脷lì
脸liǎn
脹zhàng
脺cuì
脻jiē
脼liǎng
脽shuí
脾pí
脿biāo
腀lún
腁pián
腂lěi
腃kuì
腄chuí
腅dàn
腆tiǎn
腇něi
腈jīng
腉nái
腊là
腋yè
腌yān
腍rèn
腎shèn
腏chuò
腐fǔ
腑fǔ
腒jū
腓féi
腔qiāng
腕wàn
腖dòng
腗pí
腘guó
腙zōng
腚dìng
腛wò
腜méi
腝ní
腞zhuàn
腟chì
腠còu
腡luó
腢ǒu
腣dì
腤ān
腥xīng
腦nǎo
腧shù
腨shuàn
腩nǎn
腪yùn
腫zhǒng
腬róu
腭è
腮sāi
腯tú
腰yāo
腱jiàn
腲wěi
腳jiǎo
腴yú
腵jiā
腶duàn
腷bì
腸cháng
腹fù
腺xiàn
腻nì
腼miǎn
腽wà
腾téng
腿tuǐ
膀bǎng
膁qiǎn
膂lǚ
膃wà
膄shòu
膅táng
膆sù
膇zhuì
膈gé
膉yì
膊bó
膋liáo
膌jí
膍pí
膎xié
膏gāo
膐lǚ
膑bìn
膒ōu
膓cháng
膔lù
膕guó
膖pāng
膗chuái
膘biāo
膙jiǎng
膚fū
膛táng
膜mó
膝xī
膞zhuān
膟lǜ
膠jiāo
膡yìng
膢lǘ
膣zhì
膤xuě
膥cūn
膦lìn
膧tóng
膨péng
膩nì
膪chuài
膫liáo
膬cuì
膭guī
膮xiāo
膯tēng
膰fán
膱zhí
膲jiāo
膳shàn
膴hū
膵cuì
膶rùn
膷xiāng
膸suǐ
膹fèn
膺yīng
膻shān
膼zhuā
膽dǎn
膾kuài
膿nóng
臀tún
臁lián
臂bì
臃yōng
臄jué
臅chù
臆yì
臇juǎn
臈là
臉liǎn
臊sāo
臋tún
臌gǔ
臍qí
臎cuì
臏bìn
臐xūn
臑nào
臒wò
臓zàng
臔xiàn
臕biāo
臖xìng
臗kuān
臘là
臙yān
臚lú
臛huò
臜zā
臝luǒ
臞qú
臟zàng
臠luán
臡ní
臢zā
臣chén
臤qiān
臥wò
臦guàng
臧zāng
臨lín
臩guǎng
自zì
臫jiǎo
臬niè
臭chòu
臮jì
臯gāo
臰chòu
臱mián
臲niè
至zhì
致zhì
臵gé
臶jiàn
臷dié
臸zhī
臹xiū
臺tái
臻zhēn
臼jiù
臽xiàn
臾yú
臿chā
舀yǎo
舁yú
舂chōng
舃xì
舄xì
舅jiù
舆yú
與yǔ
興xìng
舉jǔ
舊jiù
舋xìn
舌shé
舍shě
舎shè
舏jiǔ
舐shì
舑tān
舒shū
舓shì
舔tiǎn
舕tàn
舖pù
舗pù
舘guǎn
舙huà
舚tiàn
舛chuǎn
舜shùn
舝xiá
舞wǔ
舟zhōu
舠dāo
舡chuán
舢shān
舣yǐ
舤fán
舥pā
舦tài
舧fán
舨bǎn
舩chuán
航háng
舫fǎng
般bān
舭bǐ
舮lú
舯zhōng
舰jiàn
舱cāng
舲líng
舳zhú
舴zé
舵duò
舶bó
舷xián
舸gě
船chuán
舺xiá
舻lú
舼qióng
舽páng
舾xī
舿kuā
艀fú
艁zào
艂féng
艃lí
艄shāo
艅yú
艆láng
艇tǐng
艈yù
艉wěi
艊bó
艋měng
艌niàn
艍jū
艎huáng
艏shǒu
艐kè
艑biàn
艒mù
艓dié
艔dào
艕bàng
艖chā
艗yì
艘sōu
艙cāng
艚cáo
艛lóu
艜dài
艝xuě
艞yào
艟chōng
艠dēng
艡dāng
艢qiáng
艣lǔ
艤yǐ
艥jí
艦jiàn
艧huò
艨méng
艩qí
艪lǔ
艫lú
艬chán
艭shuāng
艮gěn
良liáng
艰jiān
艱jiān
色sè
艳yàn
艴fú
艵pīng
艶yàn
艷yàn
艸cǎo
艹cǎo
艺yì
艻lè
艼tīng
艽jiāo
艾ài
艿nǎi
芀tiáo
芁jiāo
节jié
芃péng
芄wán
芅yì
芆chāi
芇mián
芈mǐ
芉gān
芊qiān
芋yù
芌yù
芍sháo
芎qiōng
芏dù
芐hù
芑qǐ
芒máng
芓zì
芔huì
芕suī
芖zhì
芗xiāng
芘pí
芙fú
芚tún
芛wěi
芜wú
芝zhī
芞qì
芟shān
芠wén
芡qiàn
芢rén
芣fú
芤kōu
芥jiè
芦lú
芧xù
芨jī
芩qín
芪qí
芫yán
芬fēn
芭bā
芮ruì
芯xīn
芰jì
花huā
芲huā
芳fāng
芴wù
芵jué
芶gǒu
芷zhǐ
芸yún
芹qín
芺ǎo
芻chú
芼mào
芽yá
芾fèi
芿rèng
苀háng
苁cōng
苂yín
苃yǒu
苄biàn
苅yì
苆qiē
苇wěi
苈lì
苉pǐ
苊è
苋xiàn
苌cháng
苍cāng
苎zhù
苏sū
苐tí
苑yuàn
苒rǎn
苓líng
苔tái
苕sháo
苖dí
苗miáo
苘qǐng
苙lì
苚yòng
苛kē
苜mù
苝bèi
苞bāo
苟gǒu
苠mín
苡yǐ
苢yǐ
苣jù
苤piě
若ruò
苦kǔ
苧níng
苨nǐ
苩bó
苪bǐng
苫shān
苬xiú
苭yǎo
苮xiān
苯běn
苰hóng
英yīng
苲zhǎ
苳dōng
苴jū
苵dié
苶nié
苷gān
苸hū
苹píng
苺méi
苻fú
苼shēng
苽gū
苾bì
苿wèi
茀fú
茁zhuó
茂mào
范fàn
茄jiā
茅máo
茆máo
茇bá
茈cí
茉mò
茊zī
茋zhǐ
茌chí
茍jì
茎jīng
茏lóng
茐cōng
茑niǎo
茒yuán
茓xué
茔yíng
茕qióng
茖gé
茗míng
茘lì
茙róng
茚yìn
茛gèn
茜qiàn
茝chǎi
茞chén
茟yù
茠hāo
茡zì
茢liè
茣wú
茤jì
茥guī
茦cì
茧jiǎn
茨cí
茩gòu
茪guāng
茫máng
茬chá
茭jiāo
茮jiāo
茯fú
茰yú
茱zhū
茲zī
茳jiāng
茴huí
茵yīn
茶chá
茷fá
茸rōng
茹rú
茺chōng
茻mǎng
茼tóng
茽zhòng
茾qiān
茿zhú
荀xún
荁huán
荂fū
荃quán
荄gāi
荅dā
荆jīng
荇xìng
荈chuǎn
草cǎo
荊jīng
荋ér
荌àn
荍qiáo
荎chí
荏rěn
荐jiàn
荑tí
荒huāng
荓píng
荔lì
荕jīn
荖lǎo
荗shù
荘zhuāng
荙dá
荚jiá
荛ráo
荜bì
荝cè
荞qiáo
荟huì
荠jì
荡dàng
荢zì
荣róng
荤hūn
荥xíng
荦luò
荧yíng
荨xún
荩jìn
荪sūn
荫yīn
荬mǎi
荭hóng
荮zhòu
药yào
荰dù
荱wěi
荲lí
荳dòu
荴fū
荵rěn
荶yín
荷hé
荸bí
荹bù
荺yǔn
荻dí
荼tú
荽suī
荾suī
荿chéng
莀chén
莁wú
莂bié
莃xī
莄gěng
莅lì
莆pú
莇zhù
莈mò
莉lì
莊zhuāng
莋zuó
莌tuō
莍qiú
莎shā
莏suō
莐chén
莑péng
莒jǔ
莓méi
莔méng
莕xìng
莖jīng
莗chē
莘shēn
莙jūn
莚yán
莛tíng
莜yóu
莝cuò
莞guǎn
莟hàn
莠yǒu
莡cuò
莢jiá
莣wáng
莤sù
莥niǔ
莦shāo
莧xiàn
莨làng
莩fú
莪é
莫mò
莬wèn
莭jié
莮nán
莯mù
莰kǎn
莱lái
莲lián
莳shí
莴wō
莵tù
莶xiān
获huò
莸yóu
莹yíng
莺yīng
莻gòng
莼chún
莽mǎng
莾mǎng
莿cì
菀wǎn
菁jīng
菂dì
菃qú
菄dōng
菅jiān
菆zōu
菇gū
菈lā
菉lù
菊jú
菋wèi
菌jūn
菍niè
菎kūn
菏hé
菐pú
菑zāi
菒gǎo
菓guǒ
菔fú
菕lún
菖chāng
菗chóu
菘sōng
菙chuí
菚zhàn
菛mén
菜cài
菝bá
菞lí
菟tú
菠bō
菡hàn
菢bào
菣qìn
菤juǎn
菥xī
菦qín
菧dǐ
菨jiē
菩pú
菪dàng
菫jǐn
菬qiáo
菭tái
菮gēng
華huá
菰gū
菱líng
菲fēi
菳qín
菴ān
菵wǎng
菶běng
菷zhǒu
菸yān
菹jū
菺jiān
菻lǐn
菼tǎn
菽shū
菾tián
菿dào
萀hǔ
萁qí
萂hé
萃cuì
萄táo
萅chūn
萆bì
萇cháng
萈huán
萉fèi
萊lái
萋qī
萌méng
萍píng
萎wēi
萏dàn
萐shà
萑huán
萒yǎn
萓yí
萔tiáo
萕qí
萖wǎn
萗cè
萘nài
萙zhěn
萚tuò
萛jiū
萜tiē
萝luó
萞bì
萟yì
萠pān
萡bo
萢pāo
萣dìng
萤yíng
营yíng
萦yíng
萧xiāo
萨sà
萩qiū
萪kē
萫xiàng
萬wàn
萭yǔ
萮yú
萯fù
萰liàn
萱xuān
萲xuān
萳nǎn
萴cè
萵wō
萶chǔn
萷xiāo
萸yú
萹biǎn
萺mào
萻ān
萼è
落luò
萾yíng
萿kuò
葀kuò
葁jiāng
葂miǎn
葃zuò
葄zuò
葅zū
葆bǎo
葇róu
葈xǐ
葉yè
葊ān
葋qú
葌jiān
葍fú
葎lǜ
葏jīng
葐pén
葑fēng
葒hóng
葓hóng
葔hóu
葕yàn
葖tū
著zhù
葘zī
葙xiāng
葚rèn
葛gé
葜qiā
葝qíng
葞mǐ
葟huáng
葠shēn
葡pú
葢gài
董dǒng
葤zhòu
葥jiàn
葦wěi
葧bó
葨wēi
葩pā
葪jì
葫hú
葬zàng
葭jiā
葮duàn
葯yào
葰suī
葱cōng
葲quán
葳wēi
葴zhēn
葵kuí
葶tíng
葷hūn
葸xǐ
葹shī
葺qì
葻lán
葼zōng
葽yāo
葾yuān
葿méi
蒀yūn
蒁shù
蒂dì
蒃zhuàn
蒄guān
蒅rǎn
蒆xuē
蒇chǎn
蒈kǎi
蒉kuì
蒊huā
蒋jiǎng
蒌lóu
蒍wěi
蒎pài
蒏you
蒐sōu
蒑yīn
蒒shī
蒓chún
蒔shí
蒕yūn
蒖zhēn
蒗làng
蒘rú
蒙méng
蒚lì
蒛quē
蒜suàn
蒝yuán
蒞lì
蒟jǔ
蒠xī
蒡bàng
蒢chú
蒣xú
蒤tú
蒥liú
蒦huò
蒧diǎn
蒨qiàn
蒩zū
蒪pò
蒫cuó
蒬yuān
蒭chú
蒮yù
蒯kuǎi
蒰pán
蒱pú
蒲pú
蒳nà
蒴shuò
蒵xí
蒶fén
蒷yún
蒸zhēng
蒹jiān
蒺jí
蒻ruò
蒼cāng
蒽ēn
蒾mí
蒿hāo
蓀sūn
蓁zhēn
蓂míng
蓃sōu
蓄xù
蓅liú
蓆xí
蓇gǔ
蓈láng
蓉róng
蓊wěng
蓋gài
蓌cuò
蓍shī
蓎táng
蓏luǒ
蓐rù
蓑suō
蓒xuān
蓓bèi
蓔yǎo
蓕guì
蓖bì
蓗zǒng
蓘gǔn
蓙zuò
蓚tiáo
蓛cè
蓜pèi
蓝lán
蓞dàn
蓟jì
蓠lí
蓡shēn
蓢lǎng
蓣yù
蓤líng
蓥yíng
蓦mò
蓧diào
蓨tiáo
蓩mǎo
蓪tōng
蓫chù
蓬péng
蓭ān
蓮lián
蓯cōng
蓰xǐ
蓱píng
蓲qiū
蓳jǐn
蓴chún
蓵jié
蓶wéi
蓷tuī
蓸cáo
蓹yù
蓺yì
蓻zí
蓼liǎo
蓽bì
蓾lǔ
蓿xu
蔀bù
蔁zhāng
蔂léi
蔃qiáng
蔄màn
蔅yán
蔆líng
蔇jì
蔈biāo
蔉gǔn
蔊hǎn
蔋dí
蔌sù
蔍lù
蔎shè
蔏shāng
蔐dí
蔑miè
蔒xūn
蔓màn
蔔bó
蔕dì
蔖cuó
蔗zhè
蔘shēn
蔙xuàn
蔚wèi
蔛hú
蔜áo
蔝mǐ
蔞lóu
蔟cù
蔠zhōng
蔡cài
蔢pó
蔣jiǎng
蔤mì
蔥cōng
蔦niǎo
蔧huì
蔨juàn
蔩yín
蔪jiàn
蔫niān
蔬shū
蔭yīn
蔮guó
蔯chén
蔰hù
蔱shā
蔲kòu
蔳qiàn
蔴má
蔵zāng
蔶zé
蔷qiáng
蔸dōu
蔹liǎn
蔺lìn
蔻kòu
蔼ǎi
蔽bì
蔾lí
蔿wěi
蕀jí
蕁qián
蕂shèng
蕃fān
蕄méng
蕅ǒu
蕆chǎn
蕇diǎn
蕈xùn
蕉jiāo
蕊ruǐ
蕋ruǐ
蕌lěi
蕍yú
蕎qiáo
蕏chú
蕐huá
蕑jiān
蕒mǎi
蕓yún
蕔bāo
蕕yóu
蕖qú
蕗lù
蕘ráo
蕙huì
蕚è
蕛tí
蕜fěi
蕝jué
蕞zuì
蕟fà
蕠rú
蕡fén
蕢kuì
蕣shùn
蕤ruí
蕥yǎ
蕦xū
蕧fù
蕨jué
蕩dàng
蕪wú
蕫dǒng
蕬sī
蕭xiāo
蕮xì
蕯lóng
蕰wēn
蕱shāo
蕲qí
蕳jiān
蕴yùn
蕵sūn
蕶líng
蕷yù
蕸xiá
蕹wèng
蕺jí
蕻hóng
蕼sì
蕽nóng
蕾lěi
蕿xuān
薀yùn
薁yù
薂xí
薃hào
薄báo
薅hāo
薆ài
薇wēi
薈huì
薉huì
薊jì
薋cí
薌xiāng
薍wàn
薎miè
薏yì
薐léng
薑jiāng
薒càn
薓shēn
薔qiáng
薕lián
薖kē
薗yuán
薘dá
薙tì
薚tāng
薛xuē
薜bì
薝zhān
薞sūn
薟xiān
薠fán
薡dǐng
薢xiè
薣gǔ
薤xiè
薥shǔ
薦jiàn
薧hāo
薨hōng
薩sà
薪xīn
薫xūn
薬yào
薭bài
薮sǒu
薯shǔ
薰xūn
薱duì
薲pín
薳wěi
薴níng
薵chóu
薶mái
薷rú
薸piáo
薹tái
薺jì
薻zǎo
薼chén
薽zhēn
薾ěr
薿nǐ
藀yíng
藁gǎo
藂cóng
藃xiāo
藄qí
藅fá
藆jiǎn
藇xù
藈kuí
藉jí
藊biǎn
藋diào
藌mì
藍lán
藎jìn
藏cáng
藐miǎo
藑qióng
藒qiè
藓xiǎn
藔liáo
藕ǒu
藖xián
藗sù
藘lǘ
藙yì
藚xù
藛xiě
藜lí
藝yì
藞lǎ
藟lěi
藠jiào
藡dí
藢zhǐ
藣bēi
藤téng
藥yào
藦mò
藧huàn
藨biāo
藩fān
藪sǒu
藫tán
藬tuī
藭qióng
藮qiáo
藯wèi
藰liú
藱huì
藲ōu
藳gǎo
藴yùn
藵bǎo
藶lì
藷shǔ
藸chú
藹ǎi
藺lìn
藻zǎo
藼xuān
藽qìn
藾lài
藿huò
蘀tuò
蘁wù
蘂ruǐ
蘃ruǐ
蘄qí
蘅héng
蘆lú
蘇sū
蘈tuí
蘉méng
蘊yùn
蘋píng
蘌yǔ
蘍xūn
蘎jì
蘏jiōng
蘐xuān
蘑mó
蘒qiū
蘓sū
蘔jiōng
蘕péng
蘖niè
蘗bò
蘘ráng
蘙yì
蘚xiǎn
蘛yú
蘜jú
蘝liǎn
蘞liǎn
蘟yǐn
蘠qiáng
蘡yīng
蘢lóng
蘣tǒu
蘤huā
蘥yuè
蘦líng
蘧qú
蘨yáo
蘩fán
蘪méi
蘫hàn
蘬kuī
蘭lán
蘮jì
蘯dàng
蘰màn
蘱lèi
蘲léi
蘳huī
蘴fēng
蘵zhī
蘶wèi
蘷kuí
蘸zhàn
蘹huái
蘺lí
蘻jì
蘼mí
蘽lěi
蘾huài
蘿luó
虀jī
虁kuí
虂lù
虃jiān
虄sà
虅téng
虆léi
虇quǎn
虈xiāo
虉yì
虊luán
虋mén
虌biē
虍hū
虎hǔ
虏lǔ
虐nüè
虑lǜ
虒sī
虓xiāo
虔qián
處chù
虖hū
虗xū
虘cuó
虙fú
虚xū
虛xū
虜lǔ
虝hǔ
虞yú
號hào
虠jiāo
虡jù
虢guó
虣bào
虤yán
虥zhàn
虦zhàn
虧kuī
虨bīn
虩xì
虪shù
虫chóng
虬qiú
虭diāo
虮jǐ
虯qiú
虰dīng
虱shī
虲xiā
虳jué
虴zhé
虵shé
虶yū
虷hán
虸zǐ
虹hóng
虺huī
虻méng
虼gè
虽suī
虾xiā
虿chài
蚀shí
蚁yǐ
蚂mǎ
蚃xiǎng
蚄fāng
蚅è
蚆bā
蚇chǐ
蚈qiān
蚉wén
蚊wén
蚋ruì
蚌bàng
蚍pí
蚎yuè
蚏yuè
蚐jūn
蚑qí
蚒tóng
蚓yǐn
蚔qí
蚕cán
蚖yuán
蚗jué
蚘huí
蚙qín
蚚qí
蚛zhòng
蚜yá
蚝háo
蚞mù
蚟wáng
蚠fén
蚡fén
蚢háng
蚣gōng
蚤zǎo
蚥fù
蚦rán
蚧jiè
蚨fú
蚩chī
蚪dǒu
蚫bào
蚬xiǎn
蚭ní
蚮dài
蚯qiū
蚰yóu
蚱zhà
蚲píng
蚳chí
蚴yòu
蚵hé
蚶hān
蚷jù
蚸lì
蚹fù
蚺rán
蚻zhá
蚼gǒu
蚽pí
蚾pí
蚿xián
蛀zhù
蛁diāo
蛂bié
蛃bǐng
蛄gū
蛅zhān
蛆qū
蛇shé
蛈tiě
蛉líng
蛊gǔ
蛋dàn
蛌gǔ
蛍yíng
蛎lì
蛏chēng
蛐qū
蛑móu
蛒gé
蛓cì
蛔huí
蛕huí
蛖máng
蛗fù
蛘yáng
蛙wā
蛚liè
蛛zhū
蛜yī
蛝xián
蛞kuò
蛟jiāo
蛠lì
蛡yì
蛢píng
蛣qī
蛤há
蛥shé
蛦yí
蛧wǎng
蛨mò
蛩qióng
蛪qiè
蛫guǐ
蛬qióng
蛭zhì
蛮mán
蛯lǎo
蛰zhé
蛱jiá
蛲náo
蛳sī
蛴qí
蛵xīng
蛶jiè
蛷qiú
蛸shāo
蛹yǒng
蛺jiá
蛻tuì
蛼chē
蛽bèi
蛾é
蛿hàn
蜀shǔ
蜁xuán
蜂fēng
蜃shèn
蜄shèn
蜅fǔ
蜆xiàn
蜇zhē
蜈wú
蜉fú
蜊lí
蜋láng
蜌bì
蜍chú
蜎yuān
蜏yǒu
蜐jié
蜑dàn
蜒yán
蜓tíng
蜔diàn
蜕tuì
蜖huí
蜗wō
蜘zhī
蜙sōng
蜚fēi
蜛jū
蜜mì
蜝qí
蜞qí
蜟yù
蜠jùn
蜡là
蜢měng
蜣qiāng
蜤sī
蜥xī
蜦lún
蜧lì
蜨dié
蜩tiáo
蜪táo
蜫kūn
蜬hán
蜭hàn
蜮yù
蜯bàng
蜰féi
蜱pí
蜲wēi
蜳dūn
蜴yì
蜵yuān
蜶suò
蜷quán
蜸qiǎn
蜹ruì
蜺ní
蜻qīng
蜼wèi
蜽liǎng
蜾guǒ
蜿wān
蝀dōng
蝁è
蝂bǎn
蝃dì
蝄wǎng
蝅cán
蝆yǎng
蝇yíng
蝈guō
蝉chán
蝊dìng
蝋là
蝌kē
蝍jié
蝎xiē
蝏tíng
蝐mào
蝑xū
蝒mián
蝓yú
蝔jiē
蝕shí
蝖xuān
蝗huáng
蝘yǎn
蝙biān
蝚róu
蝛wēi
蝜fù
蝝yuán
蝞mèi
蝟wèi
蝠fú
蝡rú
蝢xié
蝣yóu
蝤qiú
蝥máo
蝦xiā
蝧yīng
蝨shī
蝩chóng
蝪tāng
蝫zhū
蝬zōng
蝭tí
蝮fù
蝯yuán
蝰kuí
蝱méng
蝲là
蝳dú
蝴hú
蝵qiū
蝶dié
蝷lì
蝸wō
蝹yūn
蝺qǔ
蝻nǎn
蝼lóu
蝽chūn
蝾róng
蝿yíng
螀jiāng
螁ban
螂láng
螃páng
螄sī
螅xī
螆cì
螇xī
螈yuán
螉wēng
螊lián
螋sōu
螌bān
融róng
螎róng
螏jí
螐wū
螑xiù
螒hàn
螓qín
螔yí
螕bī
螖huá
螗táng
螘yǐ
螙dù
螚nài
螛hé
螜hú
螝guī
螞mǎ
螟míng
螠yì
螡wén
螢yíng
螣tè
螤zhōng
螥cāng
螦sāo
螧qí
螨mǎn
螩tiao
螪shāng
螫shì
螬cáo
螭chī
螮dì
螯áo
螰lù
螱wèi
螲zhì
螳táng
螴chén
螵piāo
螶qú
螷pí
螸yú
螹jiàn
螺luó
螻lóu
螼qǐn
螽zhōng
螾yǐn
螿jiāng
蟀shuài
蟁wén
蟂xiāo
蟃wàn
蟄zhé
蟅zhè
蟆má
蟇má
蟈guō
蟉liú
蟊máo
蟋xī
蟌cōng
蟍lí
蟎mǎn
蟏xiāo
蟐chang
蟑zhāng
蟒mǎng
蟓xiàng
蟔mò
蟕zuī
蟖sī
蟗qiū
蟘tè
蟙zhí
蟚péng
蟛péng
蟜jiǎo
蟝qú
蟞biē
蟟liáo
蟠pán
蟡guǐ
蟢xǐ
蟣jǐ
蟤zhuān
蟥huáng
蟦féi
蟧láo
蟨jué
蟩jué
蟪huì
蟫yín
蟬chán
蟭jiāo
蟮shàn
蟯náo
蟰xiāo
蟱wú
蟲chóng
蟳xún
蟴sī
蟵chú
蟶chēng
蟷dāng
蟸lǐ
蟹xiè
蟺shàn
蟻yǐ
蟼jǐng
蟽dá
蟾chán
蟿qì
蠀cī
蠁xiǎng
蠂shè
蠃luǒ
蠄qín
蠅yíng
蠆chài
蠇lì
蠈zéi
蠉xuān
蠊lián
蠋zhú
蠌zé
蠍xiē
蠎mǎng
蠏xiè
蠐qí
蠑róng
蠒jiǎn
蠓měng
蠔háo
蠕rú
蠖huò
蠗zhuó
蠘jié
蠙pín
蠚hē
蠛miè
蠜fán
蠝lěi
蠞jié
蠟là
蠠mǐn
蠡lí
蠢chǔn
蠣lì
蠤qiū
蠥niè
蠦lú
蠧dù
蠨xiāo
蠩zhū
蠪lóng
蠫lí
蠬lóng
蠭fēng
蠮yē
蠯pí
蠰náng
蠱gǔ
蠲juān
蠳yīng
蠴shǔ
蠵xī
蠶cán
蠷qú
蠸quán
蠹dù
蠺cán
蠻mán
蠼qú
蠽jié
蠾zhú
蠿zhuō
血xuè
衁huāng
衂nǜ
衃pēi
衄nǜ
衅xìn
衆zhòng
衇mài
衈èr
衉kā
衊miè
衋xì
行xíng
衍yǎn
衎kàn
衏yuàn
衐qú
衑líng
衒xuàn
術shù
衔xián
衕tòng
衖xiàng
街jiē
衘xián
衙yá
衚hú
衛wèi
衜dào
衝chōng
衞wèi
衟dào
衠zhūn
衡héng
衢qú
衣yī
衤yī
补bǔ
衦gǎn
衧yú
表biǎo
衩chǎ
衪yí
衫shān
衬chèn
衭fū
衮gǔn
衯fēn
衰shuāi
衱jié
衲nà
衳zhōng
衴dǎn
衵yì
衶zhòng
衷zhōng
衸jiè
衹zhǐ
衺xié
衻rán
衼zhī
衽rèn
衾qīn
衿jīn
袀jūn
袁yuán
袂mèi
袃chài
袄ǎo
袅niǎo
袆huī
袇rán
袈jiā
袉tuó
袊lǐng
袋dài
袌bào
袍páo
袎yào
袏zuò
袐bì
袑shào
袒tǎn
袓jù
袔hè
袕xué
袖xiù
袗zhěn
袘yí
袙pà
袚bō
袛dī
袜wà
袝fù
袞gǔn
袟zhì
袠zhì
袡rán
袢pàn
袣yì
袤mào
袥tuō
袦nà
袧gōu
袨xuàn
袩zhé
袪qū
被bèi
袬yù
袭xí
袮mí
袯bó
袰bō
袱fú
袲chǐ
袳chǐ
袴kù
袵rèn
袶jiàng
袷jiá
袸jiàn
袹bó
袺jié
袻ér
袼gē
袽rú
袾zhū
袿guī
裀yīn
裁cái
裂liè
裃kǎ
裄xing
装zhuāng
裆dāng
裇xū
裈kūn
裉kèn
裊niǎo
裋shù
裌jiá
裍kǔn
裎chéng
裏lǐ
裐juān
裑shēn
裒póu
裓gé
裔yì
裕yù
裖zhěn
裗liú
裘qiú
裙qún
裚jì
裛yì
補bǔ
裝zhuāng
裞shuì
裟shā
裠qún
裡lǐ
裢lián
裣liǎn
裤kù
裥jiǎn
裦fóu
裧chān
裨bì
裩kūn
裪táo
裫yuàn
裬líng
裭chǐ
裮chāng
裯chóu
裰duō
裱biǎo
裲liǎng
裳shang
裴péi
裵péi
裶fēi
裷yuān
裸luǒ
裹guǒ
裺yǎn
裻dú
裼tì
製zhì
裾jū
裿yǐ
褀qí
褁guǒ
褂guà
褃kèn
褄qī
褅tì
褆tí
複fù
褈chóng
褉xiè
褊biǎn
褋dié
褌kūn
褍duān
褎xiù
褏xiù
褐hè
褑yuàn
褒bāo
褓bǎo
褔fù
褕yú
褖tuàn
褗yǎn
褘huī
褙bèi
褚chǔ
褛lǚ
褜páo
褝dān
褞yǔn
褟tā
褠gōu
褡dā
褢huái
褣róng
褤yuàn
褥rù
褦nài
褧jiǒng
褨suǒ
褩bān
褪tuì
褫chǐ
褬sǎng
褭niǎo
褮yīng
褯jiè
褰qiān
褱huái
褲kù
褳lián
褴lán
褵lí
褶zhě
褷shī
褸lǚ
褹yì
褺diē
褻xiè
褼xiān
褽wèi
褾biǎo
褿cáo
襀jī
襁qiǎng
襂sēn
襃bāo
襄xiāng
襅bì
襆fú
襇jiǎn
襈zhuàn
襉jiǎn
襊cuì
襋jí
襌dān
襍zá
襎fán
襏bó
襐xiàng
襑xín
襒bié
襓ráo
襔mǎn
襕lán
襖ǎo
襗zé
襘guì
襙cào
襚suì
襛nóng
襜chān
襝liǎn
襞bì
襟jīn
襠dāng
襡shǔ
襢tǎn
襣bì
襤lán
襥fú
襦rú
襧zhǐ
襨duì
襩shǔ
襪wà
襫shì
襬bǎi
襭xié
襮bó
襯chèn
襰lài
襱lóng
襲xí
襳xiān
襴lán
襵zhě
襶dài
襷jǔ
襸zàn
襹shī
襺jiǎn
襻pàn
襼yì
襽lán
襾yà
西xī
覀xī
要yào
覂fěng
覃tán
覄fù
覅fiào
覆fù
覇bà
覈hé
覉jī
覊jī
見jiàn
覌guān
覍biàn
覎yàn
規guī
覐jué
覑piǎn
覒mào
覓mì
覔mì
覕miè
視shì
覗sì
覘chān
覙luó
覚jué
覛mì
覜tiào
覝lián
覞yào
覟zhì
覠jūn
覡xí
覢shǎn
覣wēi
覤xì
覥tiǎn
覦yú
覧lǎn
覨è
覩dǔ
親qīn
覫pǎng
覬jì
覭míng
覮yíng
覯gòu
覰qū
覱zhàn
覲jìn
観guān
覴dēng
覵jiàn
覶luó
覷qù
覸jiān
覹wéi
覺jué
覻qū
覼luó
覽lǎn
覾shěn
覿dí
觀guān
见jiàn
观guān
觃yàn
规guī
觅mì
视shì
觇chān
览lǎn
觉jué
觊jì
觋xí
觌dí
觍tiǎn
觎yú
觏gòu
觐jìn
觑qù
角jiǎo
觓qiú
觔jīn
觕cū
觖jué
觗zhì
觘chào
觙jí
觚gū
觛dàn
觜zī
觝dǐ
觞shāng
觟huà
觠quán
觡gé
觢shì
解jiě
觤guǐ
觥gōng
触chù
觧jiě
觨hùn
觩qiú
觪xīng
觫sù
觬ní
觭jī
觮lù
觯zhì
觰zhā
觱bì
觲xīng
觳hú
觴shāng
觵gōng
觶zhì
觷xué
觸chù
觹xī
觺yí
觻lì
觼jué
觽xī
觾yàn
觿xī
言yán
訁yán
訂dìng
訃fù
訄qiú
訅qiú
訆jiào
訇hōng
計jì
訉fàn
訊xùn
訋diào
訌hòng
訍chài
討tǎo
訏xū
訐jié
訑yí
訒rèn
訓xùn
訔yín
訕shàn
訖qì
託tuō
記jì
訙xùn
訚yín
訛é
訜fēn
訝yà
訞yāo
訟sòng
訠shěn
訡yín
訢xīn
訣jué
訤xiáo
訥nè
訦chén
訧yóu
訨zhǐ
訩xiōng
訪fǎng
訫xìn
訬chāo
設shè
訮yán
訯sǎ
訰zhùn
許xǔ
訲yì
訳yì
訴sù
訵chī
訶hē
訷shēn
訸hé
訹xù
診zhěn
註zhù
証zhèng
訽gòu
訾zī
訿zǐ
詀zhān
詁gǔ
詂fù
詃jiǎn
詄dié
詅líng
詆dǐ
詇yàng
詈lì
詉náo
詊pàn
詋zhòu
詌gàn
詍yì
詎jù
詏yào
詐zhà
詑yí
詒yí
詓qǔ
詔zhào
評píng
詖bì
詗xiòng
詘qū
詙bá
詚dá
詛zǔ
詜tāo
詝zhǔ
詞cí
詟zhé
詠yǒng
詡xǔ
詢xún
詣yì
詤huǎng
詥hé
試shì
詧chá
詨xiào
詩shī
詪hěn
詫chà
詬gòu
詭guǐ
詮quán
詯huì
詰jié
話huà
該gāi
詳xiáng
詴wēi
詵shēn
詶zhòu
詷tóng
詸mí
詹zhān
詺mìng
詻è
詼huī
詽yán
詾xiōng
詿guà
誀èr
誁bìng
誂tiǎo
誃yí
誄lěi
誅zhū
誆kuāng
誇kuā
誈wū
誉yù
誊téng
誋jì
誌zhì
認rèn
誎cù
誏lǎng
誐é
誑kuáng
誒éi
誓shì
誔tǐng
誕dàn
誖bèi
誗chán
誘yòu
誙kēng
誚qiào
誛qīn
誜shuà
誝ān
語yǔ
誟xiào
誠chéng
誡jiè
誢xiàn
誣wū
誤wù
誥gào
誦sòng
誧bū
誨huì
誩jìng
說shuō
誫zhèn
説shuō
読dú
誮huā
誯chàng
誰shuí
誱jié
課kè
誳qū
誴cóng
誵xiáo
誶suì
誷wǎng
誸xián
誹fěi
誺chī
誻tà
誼yì
誽nì
誾yín
調diào
諀pǐ
諁zhuó
諂chǎn
諃chēn
諄zhūn
諅jì
諆qī
談tán
諈zhuì
諉wěi
諊jū
請qǐng
諌dǒng
諍zhèng
諎zé
諏zōu
諐qiān
諑zhuó
諒liàng
諓jiàn
諔chù
諕háo
論lùn
諗shěn
諘biǎo
諙huà
諚pián
諛yú
諜dié
諝xū
諞piǎn
諟shì
諠xuān
諡shì
諢hùn
諣huà
諤è
諥zhòng
諦dì
諧xié
諨fú
諩pǔ
諪tíng
諫jiàn
諬qǐ
諭yù
諮zī
諯zhuān
諰xǐ
諱huì
諲yīn
諳ān
諴xián
諵nán
諶chén
諷fěng
諸zhū
諹yáng
諺yàn
諻huáng
諼xuān
諽gé
諾nuò
諿qī
謀móu
謁yè
謂wèi
謃xīng
謄téng
謅zhōu
謆shàn
謇jiǎn
謈pó
謉kuì
謊huǎng
謋huò
謌gē
謍yíng
謎mí
謏xiǎo
謐mì
謑xǐ
謒qiāng
謓chēn
謔xuè
謕tí
謖sù
謗bàng
謘chí
謙qiān
謚shì
講jiǎng
謜yuán
謝xiè
謞hè
謟tāo
謠yáo
謡yáo
謢lū
謣yú
謤biāo
謥còng
謦qǐng
謧lí
謨mó
謩mó
謪shāng
謫zhé
謬miù
謭jiǎn
謮zé
謯jiē
謰lián
謱lóu
謲càn
謳ōu
謴gùn
謵xí
謶zhuó
謷áo
謸áo
謹jǐn
謺zhé
謻yí
謼hū
謽jiàng
謾mán
謿cháo
譀hàn
譁huá
譂chǎn
譃xū
譄zēng
譅sè
譆xī
譇zhā
譈duì
證zhèng
譊náo
譋lán
譌é
譍yīng
譎jué
譏jī
譐zǔn
譑jiǎo
譒bò
譓huì
譔zhuàn
譕wú
譖zèn
譗zhá
識shí
譙qiào
譚tán
譛zèn
譜pǔ
譝shéng
譞xuān
譟zào
譠tán
譡dǎng
譢suì
譣xiǎn
譤jī
譥jiào
警jǐng
譧zhàn
譨náng
譩yī
譪ǎi
譫zhān
譬pì
譭huǐ
譮huà
譯yì
議yì
譱shàn
譲ràng
譳nòu
譴qiǎn
譵duì
譶tà
護hù
譸zhōu
譹háo
譺ài
譻yīng
譼jiàn
譽yù
譾jiǎn
譿huì
讀dú
讁zhé
讂xuàn
讃zàn
讄lěi
讅shěn
讆wèi
讇chǎn
讈lì
讉yí
變biàn
讋zhé
讌yàn
讍è
讎chóu
讏wèi
讐chóu
讑yào
讒chán
讓ràng
讔yǐn
讕lán
讖chèn
讗xié
讘niè
讙huān
讚zàn
讛yì
讜dǎng
讝zhán
讞yàn
讟dú
讠yán
计jì
订dìng
讣fù
认rèn
讥jī
讦jié
讧hòng
讨tǎo
让ràng
讪shàn
讫qì
讬tuō
训xùn
议yì
讯xùn
记jì
讱rèn
讲jiǎng
讳huì
讴ōu
讵jù
讶yà
讷nè
许xǔ
讹é
论lùn
讻xiōng
讼sòng
讽fěng
设shè
访fǎng
诀jué
证zhèng
诂gǔ
诃hē
评píng
诅zǔ
识shí
诇xiòng
诈zhà
诉sù
诊zhěn
诋dǐ
诌zhōu
词cí
诎qū
诏zhào
诐bì
译yì
诒yí
诓kuāng
诔lěi
试shì
诖guà
诗shī
诘jí
诙huī
诚chéng
诛zhū
诜shēn
话huà
诞dàn
诟gòu
诠quán
诡guǐ
询xún
诣yì
诤zhèng
该gāi
详xiáng
诧chà
诨hùn
诩xǔ
诪zhōu
诫jiè
诬wū
语yǔ
诮qiào
误wù
诰gào
诱yòu
诲huì
诳kuáng
说shuō
诵sòng
诶éi
请qǐng
诸zhū
诹zōu
诺nuò
读dú
诼zhuó
诽fěi
课kè
诿wěi
谀yú
谁shéi
谂shěn
调diào
谄chǎn
谅liàng
谆zhūn
谇suì
谈tán
谉shěn
谊yì
谋móu
谌chén
谍dié
谎huǎng
谏jiàn
谐xié
谑xuè
谒yè
谓wèi
谔è
谕yù
谖xuān
谗chán
谘zī
谙ān
谚yàn
谛dì
谜mí
谝pián
谞xū
谟mó
谠dǎng
谡sù
谢xiè
谣yáo
谤bàng
谥shì
谦qiān
谧mì
谨jǐn
谩mán
谪zhé
谫jiǎn
谬miù
谭tán
谮zèn
谯qiáo
谰lán
谱pǔ
谲jué
谳yàn
谴qiǎn
谵zhān
谶chèn
谷gǔ
谸qiān
谹hóng
谺xiā
谻jí
谼hóng
谽hān
谾hōng
谿xī
豀xī
豁huō
豂liáo
豃hǎn
豄dú
豅lóng
豆dòu
豇jiāng
豈qǐ
豉shì
豊lǐ
豋dēng
豌wān
豍bī
豎shù
豏xiàn
豐fēng
豑zhì
豒zhì
豓yàn
豔yàn
豕shǐ
豖chù
豗huī
豘tún
豙yì
豚tún
豛yì
豜jiān
豝bā
豞hòu
豟è
豠chú
象xiàng
豢huàn
豣jiān
豤kěn
豥gāi
豦jù
豧fū
豨xī
豩bīn
豪háo
豫yù
豬zhū
豭jiā
豮fén
豯xī
豰bó
豱wēn
豲huán
豳bīn
豴dí
豵zōng
豶fén
豷yì
豸zhì
豹bào
豺chái
豻àn
豼pí
豽nà
豾pī
豿gǒu
貀nà
貁yòu
貂diāo
貃mò
貄sì
貅xiū
貆huán
貇kūn
貈hé
貉háo
貊mò
貋àn
貌mào
貍lí
貎ní
貏bǐ
貐yǔ
貑jiā
貒tuān
貓māo
貔pí
貕xī
貖yì
貗jù
貘mò
貙chū
貚tán
貛huān
貜jué
貝bèi
貞zhēn
貟yuán
負fù
財cái
貢gòng
貣tè
貤yí
貥háng
貦wán
貧pín
貨huò
販fàn
貪tān
貫guàn
責zé
貭zhì
貮èr
貯zhù
貰shì
貱bì
貲zī
貳èr
貴guì
貵piǎn
貶biǎn
買mǎi
貸dài
貹shèng
貺kuàng
費fèi
貼tiē
貽yí
貾chí
貿mào
賀hè
賁bì
賂lù
賃lìn
賄huì
賅gāi
賆pián
資zī
賈jiǎ
賉xù
賊zéi
賋jiǎo
賌gāi
賍zāng
賎jiàn
賏yīng
賐xùn
賑zhèn
賒shē
賓bīn
賔bīn
賕qiú
賖shē
賗chuàn
賘zāng
賙zhōu
賚lài
賛zàn
賜cì
賝chēn
賞shǎng
賟tiǎn
賠péi
賡gēng
賢xián
賣mài
賤jiàn
賥suì
賦fù
賧tàn
賨cóng
賩cóng
質zhì
賫jī
賬zhàng
賭dǔ
賮jìn
賯xiōng
賰chǔn
賱yǔn
賲bǎo
賳zāi
賴lài
賵fèng
賶càng
賷jī
賸shèng
賹yì
賺zhuàn
賻fù
購gòu
賽sài
賾zé
賿liáo
贀yì
贁bài
贂chěn
贃wàn
贄zhì
贅zhuì
贆biāo
贇yūn
贈zèng
贉dàn
贊zàn
贋yàn
贌pú
贍shàn
贎wàn
贏yíng
贐jìn
贑gàn
贒xián
贓zāng
贔bì
贕dú
贖shú
贗yàn
贘shǎng
贙xuàn
贚lòng
贛gàn
贜zāng
贝bèi
贞zhēn
负fù
贠yuán
贡gòng
财cái
责zé
贤xián
败bài
账zhàng
货huò
质zhì
贩fàn
贪tān
贫pín
贬biǎn
购gòu
贮zhù
贯guàn
贰èr
贱jiàn
贲bēn
贳shì
贴tiē
贵guì
贶kuàng
贷dài
贸mào
费fèi
贺hè
贻yí
贼zéi
贽zhì
贾jiǎ
贿huì
赀zī
赁lìn
赂lù
赃zāng
资zī
赅gāi
赆jìn
赇qiú
赈zhèn
赉lài
赊shē
赋fù
赌dǔ
赍jī
赎shú
赏shǎng
赐cì
赑bì
赒zhōu
赓gēng
赔péi
赕dǎn
赖lài
赗fèng
赘zhuì
赙fù
赚zhuàn
赛sài
赜zé
赝yàn
赞zàn
赟yūn
赠zèng
赡shàn
赢yíng
赣gàn
赤chì
赥xī
赦shè
赧nǎn
赨tóng
赩xì
赪chēng
赫hè
赬chēng
赭zhě
赮xiá
赯táng
走zǒu
赱zǒu
赲lì
赳jiū
赴fù
赵zhào
赶gǎn
起qǐ
赸shàn
赹qióng
赺yǐn
赻xiǎn
赼zī
赽jué
赾qǐn
赿chí
趀cī
趁chèn
趂chèn
趃dié
趄jū
超chāo
趆dī
趇xì
趈zhān
趉jué
越yuè
趋qū
趌jí
趍chí
趎chú
趏guā
趐xuè
趑zī
趒tiáo
趓duǒ
趔liè
趕gǎn
趖suō
趗cù
趘xí
趙zhào
趚sù
趛yǐn
趜jú
趝jiàn
趞què
趟tàng
趠chuò
趡cuǐ
趢lù
趣qù
趤dàng
趥qiū
趦zī
趧tí
趨qū
趩chì
趪huáng
趫qiáo
趬qiāo
趭jiào
趮zào
趯tì
趰ěr
趱zǎn
趲zǎn
足zú
趴pā
趵bào
趶kù
趷kē
趸dǔn
趹jué
趺fū
趻chěn
趼jiǎn
趽fàng
趾zhǐ
趿tā
跀yuè
跁bà
跂qí
跃yuè
跄qiāng
跅tuò
跆tái
跇yì
跈niǎn
跉líng
跊mèi
跋bá
跌diē
跍kū
跎tuó
跏jiā
跐cī
跑pǎo
跒qiǎ
跓zhù
跔jū
跕diǎn
跖zhí
跗fū
跘pán
跙jù
跚shān
跛bǒ
跜ní
距jù
跞lì
跟gēn
跠yí
跡jī
跢duò
跣xiǎn
跤jiāo
跥duò
跦zhū
跧quán
跨kuà
跩zhuǎi
跪guì
跫qióng
跬kuǐ
跭xiáng
跮chì
路lù
跰pián
跱zhì
跲jiá
跳tiào
跴cǎi
践jiàn
跶dá
跷qiāo
跸bì
跹xiān
跺duò
跻jī
跼jú
跽jì
跾shū
跿tú
踀chù
踁jìng
踂niè
踃xiāo
踄bù
踅xué
踆cūn
踇mǔ
踈shū
踉liáng
踊yǒng
踋jiǎo
踌chóu
踍qiāo
踎móu
踏tà
踐jiàn
踑qí
踒wō
踓wěi
踔chuō
踕jié
踖jí
踗niè
踘jū
踙niè
踚lún
踛lù
踜lèng
踝huái
踞jù
踟chí
踠wǎn
踡quán
踢tī
踣bó
踤zú
踥qiè
踦yǐ
踧cù
踨zōng
踩cǎi
踪zōng
踫pèng
踬zhì
踭zhēng
踮diǎn
踯zhí
踰yú
踱duó
踲dùn
踳chuǎn
踴yǒng
踵zhǒng
踶dì
踷zhǎ
踸chěn
踹chuài
踺jiàn
踻guā
踼táng
踽jǔ
踾fú
踿zú
蹀dié
蹁pián
蹂róu
蹃nuò
蹄tí
蹅chǎ
蹆tuǐ
蹇jiǎn
蹈dǎo
蹉cuō
蹊qī
蹋tà
蹌qiāng
蹍niǎn
蹎diān
蹏tí
蹐jí
蹑niè
蹒pán
蹓liū
蹔zàn
蹕bì
蹖chōng
蹗lù
蹘liáo
蹙cù
蹚tāng
蹛dài
蹜sù
蹝xǐ
蹞kuǐ
蹟jī
蹠zhí
蹡qiāng
蹢dí
蹣pán
蹤zōng
蹥lián
蹦bèng
蹧zāo
蹨niǎn
蹩bié
蹪tuí
蹫jú
蹬dēng
蹭cèng
蹮xiān
蹯fán
蹰chú
蹱zhōng
蹲dūn
蹳bō
蹴cù
蹵cù
蹶jué
蹷jué
蹸lìn
蹹tá
蹺qiāo
蹻juē
蹼pǔ
蹽liāo
蹾dūn
蹿cuān
躀guàn
躁zào
躂dá
躃bì
躄bì
躅zhú
躆jù
躇chú
躈qiào
躉dǔn
躊chóu
躋jī
躌wǔ
躍yuè
躎niǎn
躏lìn
躐liè
躑zhí
躒lì
躓zhì
躔chán
躕chú
躖duàn
躗wèi
躘lóng
躙lìn
躚xiān
躛wèi
躜zuān
躝lán
躞xiè
躟ráng
躠sǎ
躡niè
躢tà
躣qú
躤jí
躥cuān
躦cuó
躧xǐ
躨kuí
躩jué
躪lìn
身shēn
躬gōng
躭dān
躮fēn
躯qū
躰tǐ
躱duǒ
躲duǒ
躳gōng
躴láng
躵rěn
躶luǒ
躷ǎi
躸jī
躹jú
躺tǎng
躻kōng
躼lào
躽yǎn
躾měi
躿kāng
軀qū
軁lóu
軂lào
軃duǒ
軄zhí
軅yàn
軆tǐ
軇dào
軈yīng
軉yù
車chē
軋yà
軌guǐ
軍jūn
軎wèi
軏yuè
軐xìn
軑dài
軒xuān
軓fàn
軔rèn
軕shān
軖kuáng
軗shū
軘tún
軙chén
軚dài
軛è
軜nà
軝qí
軞máo
軟ruǎn
軠kuáng
軡qián
転zhuǎn
軣hōng
軤hū
軥qú
軦kuàng
軧dǐ
軨líng
軩dài
軪āo
軫zhěn
軬fàn
軭kuāng
軮yǎng
軯pēng
軰bèi
軱gū
軲gū
軳páo
軴zhù
軵rǒng
軶è
軷bá
軸zhóu
軹zhǐ
軺yáo
軻kē
軼yì
軽zhì
軾shì
軿píng
輀ér
輁gǒng
輂jú
較jiào
輄guāng
輅hé
輆kǎi
輇quán
輈zhōu
載zài
輊zhì
輋shē
輌liàng
輍yù
輎shāo
輏yóu
輐wàn
輑yǐn
輒zhé
輓wǎn
輔fǔ
輕qīng
輖zhōu
輗ní
輘léng
輙zhé
輚zhàn
輛liàng
輜zī
輝huī
輞wǎng
輟chuò
輠guǒ
輡kǎn
輢yǐ
輣péng
輤qiàn
輥gǔn
輦niǎn
輧píng
輨guǎn
輩bèi
輪lún
輫pái
輬liáng
輭ruǎn
輮róu
輯jí
輰yáng
輱xián
輲chuán
輳còu
輴chūn
輵gé
輶yóu
輷hōng
輸shū
輹fù
輺zī
輻fú
輼wēn
輽bèn
輾zhǎn
輿yú
轀wēn
轁tāo
轂gǔ
轃zhēn
轄xiá
轅yuán
轆lù
轇jiāo
轈cháo
轉zhuǎn
轊wèi
轋hún
轌xuě
轍zhé
轎jiào
轏zhàn
轐bú
轑lǎo
轒fén
轓fān
轔lín
轕gé
轖sè
轗kǎn
轘huán
轙yǐ
轚jí
轛zhuì
轜ér
轝yù
轞jiàn
轟hōng
轠léi
轡pèi
轢lì
轣lì
轤lú
轥lìn
车chē
轧yà
轨guǐ
轩xuān
轪dài
轫rèn
转zhuǎn
轭è
轮lún
软ruǎn
轰hōng
轱gū
轲kē
轳lú
轴zhóu
轵zhǐ
轶yì
轷hū
轸zhěn
轹lì
轺yáo
轻qīng
轼shì
载zài
轾zhì
轿jiào
辀zhōu
辁quán
辂lù
较jiào
辄zhé
辅fǔ
辆liàng
辇niǎn
辈bèi
辉huī
辊gǔn
辋wǎng
辌liáng
辍chuò
辎zī
辏còu
辐fú
辑jí
辒wēn
输shū
辔pèi
辕yuán
辖xiá
辗niǎn
辘lù
辙zhé
辚lín
辛xīn
辜gū
辝cí
辞cí
辟pì
辠zuì
辡biàn
辢là
辣là
辤cí
辥xuē
辦bàn
辧biàn
辨biàn
辩biàn
辪xuē
辫biàn
辬bān
辭cí
辮biàn
辯biàn
辰chén
辱rǔ
農nóng
辳nóng
辴chǎn
辵chuò
辶chuò
辷yī
辸réng
边biān
辺biān
辻shí
込yū
辽liáo
达dá
辿chān
迀gān
迁qiān
迂yū
迃yū
迄qì
迅xùn
迆yí
过guò
迈mài
迉qī
迊zā
迋wàng
迌tù
迍zhūn
迎yíng
迏dá
运yùn
近jìn
迒háng
迓yà
返fǎn
迕wù
迖dá
迗é
还hái
这zhè
迚dá
进jìn
远yuǎn
违wéi
连lián
迟chí
迠chè
迡nì
迢tiáo
迣zhì
迤yí
迥jiǒng
迦jiā
迧chén
迨dài
迩ěr
迪dí
迫pò
迬zhù
迭dié
迮zé
迯táo
述shù
迱tuó
迲qu
迳jìng
迴huí
迵dòng
迶yòu
迷mí
迸bèng
迹jì
迺nǎi
迻yí
迼jié
追zhuī
迾liè
迿xùn
退tuì
送sòng
适shì
逃táo
逄páng
逅hòu
逆nì
逇dùn
逈jiǒng
选xuǎn
逊xùn
逋bū
逌yōu
逍xiāo
逎qiú
透tòu
逐zhú
逑qiú
递dì
逓dì
途tú
逕jìng
逖tì
逗dòu
逘yǐ
這zhè
通tōng
逛guàng
逜wù
逝shì
逞chěng
速sù
造zào
逡qūn
逢féng
連lián
逤suò
逥huí
逦lǐ
逧gǔ
逨lái
逩bèn
逪cuò
逫jué
逬bèng
逭huàn
逮dǎi
逯lù
逰yóu
週zhōu
進jìn
逳yù
逴chuō
逵kuí
逶wēi
逷tì
逸yì
逹dá
逺yuǎn
逻luó
逼bī
逽nuò
逾yú
逿dàng
遀suí
遁dùn
遂suì
遃yǎn
遄chuán
遅chí
遆tí
遇yù
遈shí
遉zhēn
遊yóu
運yùn
遌è
遍biàn
過guò
遏è
遐xiá
遑huáng
遒qiú
道dào
達dá
違wéi
遖nán
遗yí
遘gòu
遙yáo
遚chòu
遛liú
遜xùn
遝tà
遞dì
遟chí
遠yuǎn
遡sù
遢tà
遣qiǎn
遤mǎ
遥yáo
遦guàn
遧zhāng
遨áo
適shì
遪cà
遫chì
遬sù
遭zāo
遮zhē
遯dùn
遰dì
遱lóu
遲chí
遳cuō
遴lín
遵zūn
遶rào
遷qiān
選xuǎn
遹yù
遺yí
遻è
遼liáo
遽jù
遾shì
避bì
邀yāo
邁mài
邂xiè
邃suì
還hái
邅zhān
邆téng
邇ěr
邈miǎo
邉biān
邊biān
邋lā
邌lí
邍yuán
邎yáo
邏luó
邐lǐ
邑yì
邒tíng
邓dèng
邔qǐ
邕yōng
邖shān
邗hán
邘yú
邙máng
邚rú
邛qióng
邜xī
邝kuàng
邞fū
邟kàng
邠bīn
邡fāng
邢xíng
那nà
邤xīn
邥shěn
邦bāng
邧yuán
邨cūn
邩huǒ
邪xié
邫bāng
邬wū
邭jù
邮yóu
邯hán
邰tái
邱qiū
邲bì
邳pī
邴bǐng
邵shào
邶bèi
邷wǎ
邸dǐ
邹zōu
邺yè
邻lín
邼kuāng
邽guī
邾zhū
邿shī
郀kū
郁yù
郂gāi
郃hé
郄qiè
郅zhì
郆jí
郇huán
郈hòu
郉xíng
郊jiāo
郋xí
郌guī
郍nuó
郎láng
郏jiá
郐kuài
郑zhèng
郒láng
郓yùn
郔yán
郕chéng
郖dòu
郗xī
郘lǚ
郙fǔ
郚wú
郛fú
郜gào
郝hǎo
郞láng
郟jiá
郠gěng
郡jùn
郢yǐng
郣bó
郤xì
郥bèi
郦lì
郧yún
部bù
郩xiáo
郪qī
郫pí
郬qīng
郭guō
郮zhōu
郯tán
郰zōu
郱píng
郲lái
郳ní
郴chēn
郵yóu
郶bù
郷xiāng
郸dān
郹jú
郺yōng
郻qiāo
郼yī
都dōu
郾yǎn
郿méi
鄀ruò
鄁bèi
鄂è
鄃shū
鄄juàn
鄅yǔ
鄆yùn
鄇hóu
鄈kuí
鄉xiāng
鄊xiāng
鄋sōu
鄌táng
鄍míng
鄎xī
鄏rǔ
鄐chù
鄑zī
鄒zōu
鄓yè
鄔wū
鄕xiāng
鄖yún
鄗hào
鄘yōng
鄙bǐ
鄚mào
鄛cháo
鄜fū
鄝liǎo
鄞yín
鄟zhuān
鄠hù
鄡qiāo
鄢yān
鄣zhāng
鄤màn
鄥qiāo
鄦xǔ
鄧dèng
鄨bì
鄩xún
鄪bì
鄫zēng
鄬wéi
鄭zhèng
鄮mào
鄯shàn
鄰lín
鄱pó
鄲dān
鄳méng
鄴yè
鄵cào
鄶kuài
鄷fēng
鄸méng
鄹zōu
鄺kuàng
鄻liǎn
鄼zàn
鄽chán
鄾yōu
鄿jī
酀yàn
酁chán
酂cuó
酃líng
酄huān
酅xī
酆fēng
酇zàn
酈lì
酉yǒu
酊dīng
酋qiú
酌zhuó
配pèi
酎zhòu
酏yǐ
酐gān
酑yú
酒jiǔ
酓yǎn
酔zuì
酕máo
酖zhèn
酗xù
酘dòu
酙zhēn
酚fēn
酛yuán
酜fu
酝yùn
酞tài
酟tiān
酠qiǎ
酡tuó
酢cù
酣hān
酤gū
酥sū
酦pò
酧chóu
酨zài
酩mǐng
酪lào
酫chuò
酬chóu
酭yòu
酮tóng
酯zhǐ
酰xiān
酱jiàng
酲chéng
酳yìn
酴tú
酵jiào
酶méi
酷kù
酸suān
酹lèi
酺pú
酻zuì
酼hǎi
酽yàn
酾shāi
酿niàng
醀wéi
醁lù
醂lǎn
醃yān
醄táo
醅pēi
醆zhǎn
醇chún
醈tán
醉zuì
醊zhuì
醋cù
醌kūn
醍tí
醎xián
醏dū
醐hú
醑xǔ
醒xǐng
醓tǎn
醔qiú
醕chún
醖yùn
醗pò
醘kē
醙sōu
醚mí
醛quán
醜chǒu
醝cuō
醞yùn
醟yòng
醠àng
醡zhà
醢hǎi
醣táng
醤jiàng
醥piǎo
醦chěn
醧yù
醨lí
醩zāo
醪láo
醫yī
醬jiàng
醭bú
醮jiào
醯xī
醰tán
醱fā
醲nóng
醳yì
醴lǐ
醵jù
醶yàn
醷yì
醸niàng
醹rú
醺xūn
醻chóu
醼yàn
醽líng
醾mí
醿mí
釀niàng
釁xìn
釂jiào
釃shāi
釄mí
釅yàn
釆biàn
采cǎi
釈shì
釉yòu
释shì
釋shì
里lǐ
重zhòng
野yě
量liàng
釐lí
金jīn
釒jīn
釓qiú
釔yǐ
釕liǎo
釖dāo
釗zhāo
釘dīng
釙pò
釚qiú
釛bā
釜fǔ
針zhēn
釞zhí
釟bā
釠luàn
釡fǔ
釢nǎi
釣diào
釤shàn
釥qiǎo
釦kòu
釧chuàn
釨zǐ
釩fǎn
釪huá
釫huá
釬hàn
釭gāng
釮qí
釯máng
釰rì
釱dì
釲sì
釳xì
釴yì
釵chāi
釶shī
釷tǔ
釸xī
釹nǚ
釺qiān
釻qiú
釼jiàn
釽pì
釾yé
釿jīn
鈀bǎ
鈁fāng
鈂chén
鈃xíng
鈄dǒu
鈅yuè
鈆qiān
鈇fū
鈈pī
鈉nà
鈊xīn
鈋é
鈌jué
鈍dùn
鈎gōu
鈏yǐn
鈐qián
鈑bǎn
鈒sà
鈓rén
鈔chāo
鈕niǔ
鈖fēn
鈗yǔn
鈘yǐ
鈙qín
鈚pī
鈛guō
鈜hóng
鈝yín
鈞jūn
鈟diào
鈠yì
鈡zhōng
鈢xǐ
鈣gài
鈤rì
鈥huǒ
鈦tài
鈧kàng
鈨yuán
鈩lú
鈪è
鈫qín
鈬duó
鈭zī
鈮nǐ
鈯tú
鈰shì
鈱mín
鈲gū
鈳kē
鈴líng
鈵bǐng
鈶sì
鈷gǔ
鈸bó
鈹pī
鈺yù
鈻sì
鈼zuó
鈽bū
鈾yóu
鈿tián
鉀jiǎ
鉁zhēn
鉂shǐ
鉃shì
鉄zhí
鉅jù
鉆chān
鉇shī
鉈shī
鉉xuàn
鉊zhāo
鉋bào
鉌hé
鉍bì
鉎shēng
鉏chú
鉐shí
鉑bó
鉒zhù
鉓chì
鉔zā
鉕pō
鉖tóng
鉗qián
鉘fú
鉙zhǎi
鉚liǔ
鉛qiān
鉜fú
鉝lì
鉞yuè
鉟pī
鉠yāng
鉡bàn
鉢bō
鉣jié
鉤gōu
鉥shù
鉦zhēng
鉧mǔ
鉨xǐ
鉩xǐ
鉪dì
鉫jiā
鉬mù
鉭tǎn
鉮huán
鉯yǐ
鉰sī
鉱kuàng
鉲kǎ
鉳běi
鉴jiàn
鉵tóng
鉶xíng
鉷hóng
鉸jiǎo
鉹chǐ
鉺èr
鉻luò
鉼bǐng
鉽shì
鉾móu
鉿jiā
銀yín
銁jūn
銂zhōu
銃chòng
銄xiǎng
銅tóng
銆mò
銇lèi
銈jī
銉yù
銊xù
銋rén
銌zùn
銍zhì
銎qióng
銏shàn
銐chì
銑xiǎn
銒xíng
銓quán
銔pī
銕tiě
銖zhū
銗xiàng
銘míng
銙kuǎ
銚yáo
銛xiān
銜xián
銝xiū
銞jūn
銟chā
銠lǎo
銡jí
銢pǐ
銣rú
銤mǐ
銥yī
銦yīn
銧guāng
銨ǎn
銩diū
銪yǒu
銫sè
銬kào
銭qián
銮luán
銯sī
銰āi
銱diào
銲hàn
銳ruì
銴shì
銵kēng
銶qiú
銷xiāo
銸zhé
銹xiù
銺zàng
銻tí
銼cuò
銽guā
銾hòng
銿zhōng
鋀tōu
鋁lǚ
鋂méi
鋃láng
鋄wàn
鋅xīn
鋆yún
鋇bèi
鋈wù
鋉sù
鋊yù
鋋chán
鋌dìng
鋍bó
鋎hàn
鋏jiá
鋐hóng
鋑cuān
鋒fēng
鋓chān
鋔wǎn
鋕zhì
鋖sī
鋗xuān
鋘huá
鋙yǔ
鋚tiáo
鋛kuàng
鋜zhuó
鋝lüè
鋞xíng
鋟qǐn
鋠shèn
鋡hán
鋢lüè
鋣yé
鋤chú
鋥zèng
鋦jū
鋧xiàn
鋨tiě
鋩máng
鋪pù
鋫lí
鋬pàn
鋭ruì
鋮chéng
鋯gào
鋰lǐ
鋱tè
鋲bīng
鋳zhù
鋴zhèn
鋵tū
鋶liǔ
鋷zuì
鋸jù
鋹chǎng
鋺yuǎn
鋻jiàn
鋼gāng
鋽diào
鋾táo
鋿cháng
錀lún
錁guǒ
錂líng
錃pī
錄lù
錅lí
錆qiāng
錇póu
錈juǎn
錉mín
錊zuì
錋péng
錌àn
錍pī
錎xiàn
錏yā
錐zhuī
錑lèi
錒kē
錓kōng
錔tà
錕kūn
錖dú
錗nèi
錘chuí
錙zī
錚zhēng
錛bēn
錜niè
錝zòng
錞chún
錟tán
錠dìng
錡qí
錢qián
錣zhuì
錤jī
錥yù
錦jǐn
錧guǎn
錨máo
錩chāng
錪tiǎn
錫xī
錬liàn
錭táo
錮gù
錯cuò
錰shù
錱zhēn
録lù
錳měng
錴lù
錵huā
錶biǎo
錷gá
錸lái
錹kěn
錺fāng
錻wu
錼nài
錽wàn
錾zàn
錿hǔ
鍀dé
鍁xiān
鍂piān
鍃huō
鍄liàng
鍅fǎ
鍆mén
鍇kǎi
鍈yīng
鍉dī
鍊liàn
鍋guō
鍌xiǎn
鍍dù
鍎tú
鍏wéi
鍐zōng
鍑fù
鍒róu
鍓jí
鍔è
鍕jūn
鍖chěn
鍗tí
鍘zhá
鍙hù
鍚yáng
鍛duàn
鍜xiá
鍝yú
鍞kēng
鍟shēng
鍠huáng
鍡wěi
鍢fù
鍣zhāo
鍤chā
鍥qiè
鍦shī
鍧hōng
鍨kuí
鍩tiǎn
鍪móu
鍫qiāo
鍬qiāo
鍭hóu
鍮tōu
鍯cōng
鍰huán
鍱yè
鍲mín
鍳jiàn
鍴duān
鍵jiàn
鍶sōng
鍷kuí
鍸hú
鍹xuān
鍺duǒ
鍻jié
鍼zhēn
鍽biān
鍾zhōng
鍿zī
鎀xiū
鎁yé
鎂měi
鎃pài
鎄āi
鎅jiè
鎆qian
鎇méi
鎈suǒ
鎉dá
鎊bàng
鎋xiá
鎌lián
鎍suǒ
鎎kài
鎏liú
鎐yáo
鎑yè
鎒nòu
鎓wēng
鎔róng
鎕táng
鎖suǒ
鎗qiāng
鎘lì
鎙shuò
鎚chuí
鎛bó
鎜pán
鎝dā
鎞bī
鎟sǎng
鎠gāng
鎡zī
鎢wū
鎣yíng
鎤huàng
鎥tiáo
鎦liú
鎧kǎi
鎨sǔn
鎩shā
鎪sōu
鎫wàn
鎬hào
鎭zhèn
鎮zhèn
鎯láng
鎰yì
鎱yuán
鎲tǎng
鎳niè
鎴xí
鎵jiā
鎶gē
鎷mǎ
鎸juān
鎹sòng
鎺zǔ
鎻suǒ
鎼xià
鎽fēng
鎾wēn
鎿ná
鏀lǔ
鏁suǒ
鏂ōu
鏃zú
鏄tuán
鏅xiū
鏆guàn
鏇xuàn
鏈liàn
鏉shòu
鏊ào
鏋mǎn
鏌mò
鏍luó
鏎bì
鏏wèi
鏐liú
鏑dí
鏒sǎn
鏓zǒng
鏔yí
鏕lù
鏖áo
鏗kēng
鏘qiāng
鏙cuī
鏚qī
鏛cháng
鏜tāng
鏝màn
鏞yōng
鏟chǎn
鏠fēng
鏡jìng
鏢biāo
鏣shù
鏤lòu
鏥xiù
鏦cōng
鏧lóng
鏨zàn
鏩jiàn
鏪cáo
鏫lí
鏬xià
鏭xī
鏮kāng
鏯shuǎng
鏰bèng
鏱zhang
鏲qian
鏳chēng
鏴lù
鏵huá
鏶jí
鏷pú
鏸huì
鏹qiǎng
鏺pō
鏻lín
鏼sè
鏽xiù
鏾sǎn
鏿chēng
鐀kuì
鐁sī
鐂liú
鐃náo
鐄huáng
鐅piě
鐆suì
鐇fán
鐈qiáo
鐉quān
鐊yáng
鐋tāng
鐌xiàng
鐍jué
鐎jiāo
鐏zūn
鐐liáo
鐑qiè
鐒láo
鐓duì
鐔xín
鐕zān
鐖jī
鐗jiǎn
鐘zhōng
鐙dèng
鐚yā
鐛yǐng
鐜duī
鐝jué
鐞nòu
鐟zān
鐠pǔ
鐡tiě
鐢fán
鐣chēng
鐤dǐng
鐥shàn
鐦kāi
鐧jiān
鐨fèi
鐩suì
鐪lǔ
鐫juān
鐬huì
鐭yù
鐮lián
鐯zhuó
鐰qiāo
鐱jiàn
鐲zhuó
鐳léi
鐴bì
鐵tiě
鐶huán
鐷yè
鐸duó
鐹guǒ
鐺dāng
鐻jù
鐼fén
鐽dá
鐾bèi
鐿yì
鑀ài
鑁zōng
鑂xùn
鑃diào
鑄zhù
鑅héng
鑆zhuì
鑇jī
鑈niè
鑉hé
鑊huò
鑋qīng
鑌bīn
鑍yīng
鑎kuì
鑏níng
鑐xū
鑑jiàn
鑒jiàn
鑓qiǎn
鑔chǎ
鑕zhì
鑖miè
鑗lí
鑘léi
鑙jī
鑚zuàn
鑛kuàng
鑜shǎng
鑝péng
鑞là
鑟dú
鑠shuò
鑡chuò
鑢lǜ
鑣biāo
鑤bào
鑥lǔ
鑦xian
鑧kuān
鑨lóng
鑩è
鑪lú
鑫xīn
鑬jiàn
鑭làn
鑮bó
鑯jiān
鑰yào
鑱chán
鑲xiāng
鑳jiàn
鑴xī
鑵guàn
鑶cáng
鑷niè
鑸lěi
鑹cuān
鑺qú
鑻pàn
鑼luó
鑽zuān
鑾luán
鑿záo
钀niè
钁jué
钂tǎng
钃zhú
钄lán
钅jīn
钆gá
钇yǐ
针zhēn
钉dīng
钊zhāo
钋pō
钌liǎo
钍tǔ
钎qiān
钏chuàn
钐shān
钑sà
钒fán
钓diào
钔mén
钕nǚ
钖yáng
钗chāi
钘xíng
钙gài
钚bù
钛tài
钜jù
钝dùn
钞chāo
钟zhōng
钠nà
钡bèi
钢gāng
钣bǎn
钤qián
钥yào
钦qīn
钧jūn
钨wū
钩gōu
钪kàng
钫fāng
钬huǒ
钭tǒu
钮niǔ
钯bǎ
钰yù
钱qián
钲zhēng
钳qián
钴gǔ
钵bō
钶kē
钷pǒ
钸bū
钹bó
钺yuè
钻zuān
钼mù
钽tǎn
钾jiǎ
钿diàn
铀yóu
铁tiě
铂bó
铃líng
铄shuò
铅qiān
铆mǎo
铇bào
铈shì
铉xuàn
铊tā
铋bì
铌ní
铍pī
铎duó
铏xíng
铐kào
铑lǎo
铒ěr
铓máng
铔yā
铕yǒu
铖chéng
铗jiá
铘yé
铙náo
铚zhì
铛dāng
铜tóng
铝lǚ
铞diào
铟yīn
铠kǎi
铡zhá
铢zhū
铣xǐ
铤dìng
铥diū
铦xiān
铧huá
铨quán
铩shā
铪hā
铫diào
铬gè
铭míng
铮zhēng
铯sè
铰jiǎo
铱yī
铲chǎn
铳chòng
铴tāng
铵ǎn
银yín
铷rú
铸zhù
铹láo
铺pù
铻wú
铼lái
铽tè
链liàn
铿kēng
销xiāo
锁suǒ
锂lǐ
锃zèng
锄chú
锅guō
锆gào
锇é
锈xiù
锉cuò
锊lüè
锋fēng
锌xīn
锍liǔ
锎kāi
锏jiǎn
锐ruì
锑tī
锒láng
锓qǐn
锔jū
锕ā
锖qiāng
锗zhě
锘nuò
错cuò
锚máo
锛bēn
锜qí
锝dé
锞kè
锟kūn
锠chāng
锡xī
锢gù
锣luó
锤chuí
锥zhuī
锦jǐn
锧zhì
锨xiān
锩juǎn
锪huō
锫péi
锬tán
锭dìng
键jiàn
锯jù
锰měng
锱zī
锲qiè
锳yīng
锴kǎi
锵qiāng
锶sī
锷è
锸chā
锹qiāo
锺zhōng
锻duàn
锼sōu
锽huáng
锾huán
锿āi
镀dù
镁měi
镂lòu
镃zī
镄fèi
镅méi
镆mò
镇zhèn
镈bó
镉gé
镊niè
镋tǎng
镌juān
镍niè
镎ná
镏liú
镐gǎo
镑bàng
镒yì
镓jiā
镔bīn
镕róng
镖biāo
镗tāng
镘màn
镙luó
镚bèng
镛yōng
镜jìng
镝dī
镞zú
镟xuàn
镠liú
镡chán
镢jué
镣liào
镤pú
镥lǔ
镦duì
镧lán
镨pǔ
镩cuān
镪qiāng
镫dèng
镬huò
镭léi
镮huán
镯zhuó
镰lián
镱yì
镲chǎ
镳biāo
镴là
镵chán
镶xiāng
長zhǎng
镸cháng
镹jiǔ
镺ǎo
镻dié
镼qū
镽liǎo
镾mí
长zhǎng
門mén
閁mà
閂shuān
閃shǎn
閄huò
閅mén
閆yán
閇bì
閈hàn
閉bì
閊shān
開kāi
閌kàng
閍bēng
閎hóng
閏rùn
閐sàn
閑xián
閒xián
間jiān
閔mǐn
閕xiā
閖shui
閗dòu
閘zhá
閙nào
閚zhān
閛pēng
閜xiǎ
閝líng
閞biàn
閟bì
閠rùn
閡ài
関guān
閣gé
閤gé
閥fá
閦chù
閧hòng
閨guī
閩mǐn
閪sē
閫kǔn
閬làng
閭lǘ
閮tíng
閯shà
閰jú
閱yuè
閲yuè
閳chǎn
閴qù
閵lìn
閶chāng
閷shài
閸kǔn
閹yān
閺wén
閻yán
閼è
閽hūn
閾yù
閿wén
闀hòng
闁bāo
闂hòng
闃qù
闄yǎo
闅wén
闆bǎn
闇àn
闈wéi
闉yīn
闊kuò
闋què
闌lán
闍dū
闎quán
闏fēng
闐tián
闑niè
闒tà
闓kǎi
闔hé
闕què
闖chuǎng
闗guān
闘dòu
闙qǐ
闚kuī
闛táng
關guān
闝piáo
闞kàn
闟xì
闠huì
闡chǎn
闢pì
闣dàng
闤huán
闥tà
闦wén
闧tā
门mén
闩shuān
闪shǎn
闫yán
闬hàn
闭bì
问wèn
闯chuǎng
闰rùn
闱wéi
闲xián
闳hóng
间jiān
闵mǐn
闶kāng
闷mèn
闸zhá
闹nào
闺guī
闻wén
闼tà
闽mǐn
闾lǘ
闿kǎi
阀fá
阁gé
阂hé
阃kǔn
阄jiū
阅yuè
阆láng
阇dū
阈yù
阉yān
阊chāng
阋xì
阌wén
阍hūn
阎yán
阏è
阐chǎn
阑lán
阒qù
阓huì
阔kuò
阕què
阖hé
阗tián
阘dá
阙quē
阚hǎn
阛huán
阜fù
阝fù
阞lè
队duì
阠xìn
阡qiān
阢wù
阣gài
阤zhì
阥yīn
阦yáng
阧dǒu
阨è
阩shēng
阪bǎn
阫péi
阬kēng
阭yǔn
阮ruǎn
阯zhǐ
阰pí
阱jǐng
防fáng
阳yáng
阴yīn
阵zhèn
阶jiē
阷chēng
阸è
阹qū
阺dǐ
阻zǔ
阼zuò
阽diàn
阾lǐng
阿ā
陀tuó
陁tuó
陂bēi
陃bǐng
附fù
际jì
陆lù
陇lǒng
陈chén
陉xíng
陊duò
陋lòu
陌mò
降jiàng
陎shū
陏duò
限xiàn
陑ér
陒guǐ
陓yū
陔gāi
陕shǎn
陖jùn
陗qiào
陘xíng
陙chún
陚fù
陛bì
陜xiá
陝shǎn
陞shēng
陟zhì
陠pū
陡dǒu
院yuàn
陣zhèn
除chú
陥xiàn
陦dǎo
陧niè
陨yǔn
险xiǎn
陪péi
陫fèi
陬zōu
陭yì
陮duì
陯lún
陰yīn
陱jū
陲chuí
陳chén
陴pí
陵líng
陶táo
陷xiàn
陸lù
陹shēng
険xiǎn
陻yīn
陼zhǔ
陽yáng
陾réng
陿xiá
隀chóng
隁yàn
隂yīn
隃shù
隄dī
隅yú
隆lóng
隇wēi
隈wēi
隉niè
隊duì
隋suí
隌ǎn
隍huáng
階jiē
随suí
隐yǐn
隑gài
隒yǎn
隓huī
隔gé
隕yǔn
隖wù
隗kuí
隘ài
隙xì
隚táng
際jì
障zhàng
隝dǎo
隞áo
隟xì
隠yǐn
隡sà
隢rǎo
隣lín
隤tuí
隥dèng
隦jiǎo
隧suì
隨suí
隩ào
險xiǎn
隫fén
隬nǐ
隭ér
隮jī
隯dǎo
隰xí
隱yǐn
隲zhì
隳huī
隴lǒng
隵xī
隶lì
隷lì
隸lì
隹zhuī
隺hú
隻zhī
隼sǔn
隽juàn
难nán
隿yì
雀què
雁yàn
雂qín
雃qiān
雄xióng
雅yǎ
集jí
雇gù
雈huán
雉zhì
雊gòu
雋juàn
雌cí
雍yōng
雎jū
雏chú
雐hū
雑zá
雒luò
雓yú
雔chóu
雕diāo
雖suī
雗hàn
雘wò
雙shuāng
雚guàn
雛chú
雜zá
雝yōng
雞jī
雟xī
雠chóu
雡liù
離lí
難nán
雤xué
雥zá
雦jí
雧jí
雨yǔ
雩yú
雪xuě
雫nǎ
雬fǒu
雭sè
雮mù
雯wén
雰fēn
雱pāng
雲yún
雳lì
雴chì
雵yāng
零líng
雷léi
雸án
雹báo
雺wù
電diàn
雼dàng
雽hù
雾wù
雿diào
需xū
霁jì
霂mù
霃chén
霄xiāo
霅zhà
霆tíng
震zhèn
霈pèi
霉méi
霊líng
霋qī
霌zhōu
霍huò
霎shà
霏fēi
霐hóng
霑zhān
霒yīn
霓ní
霔zhù
霕tún
霖lín
霗líng
霘dòng
霙yīng
霚wù
霛líng
霜shuāng
霝líng
霞xiá
霟hóng
霠yīn
霡mài
霢mài
霣yǔn
霤liù
霥mèng
霦bīn
霧wù
霨wèi
霩kuò
霪yín
霫xí
霬yì
霭ǎi
霮dàn
霯tèng
霰sǎn
霱yù
露lù
霳lóng
霴dài
霵jí
霶pāng
霷yáng
霸bà
霹pī
霺wéi
霻fēng
霼xì
霽jì
霾mái
霿méng
靀méng
靁léi
靂lì
靃huò
靄ǎi
靅fèi
靆dài
靇lóng
靈líng
靉ài
靊fēng
靋lì
靌bǎo
靍hè
靎hè
靏hè
靐bìng
靑qīng
青qīng
靓jìng
靔tiān
靕zhēn
靖jìng
靗chēng
靘qìng
静jìng
靚jìng
靛diàn
靜jìng
靝tiān
非fēi
靟fēi
靠kào
靡mí
面miàn
靣miàn
靤bào
靥yè
靦tiǎn
靧huì
靨yè
革gé
靪dīng
靫chá
靬qián
靭rèn
靮dí
靯dù
靰wù
靱rèn
靲qín
靳jìn
靴xuē
靵niǔ
靶bǎ
靷yǐn
靸sǎ
靹nà
靺mò
靻zǔ
靼dá
靽bàn
靾yì
靿yào
鞀táo
鞁bèi
鞂jiē
鞃hóng
鞄páo
鞅yāng
鞆bǐng
鞇yīn
鞈gé
鞉táo
鞊jié
鞋xié
鞌ān
鞍ān
鞎hén
鞏gǒng
鞐qiǎ
鞑dá
鞒qiáo
鞓tīng
鞔mán
鞕yìng
鞖suī
鞗tiáo
鞘qiào
鞙xuàn
鞚kòng
鞛běng
鞜tà
鞝shàng
鞞bǐng
鞟kuò
鞠jū
鞡la
鞢xiè
鞣róu
鞤bāng
鞥ēng
鞦qiū
鞧qiū
鞨hé
鞩qiào
鞪mù
鞫jū
鞬jiān
鞭biān
鞮dī
鞯jiān
鞰wēn
鞱tāo
鞲gōu
鞳tà
鞴bèi
鞵xié
鞶pán
鞷gé
鞸bì
鞹kuò
鞺tāng
鞻lóu
鞼guì
鞽qiáo
鞾xuē
鞿jī
韀jiān
韁jiāng
韂chàn
韃dá
韄hù
韅xiǎn
韆qiān
韇dú
韈wà
韉jiān
韊lán
韋wéi
韌rèn
韍fú
韎mèi
韏quàn
韐gé
韑wěi
韒qiào
韓hán
韔chàng
韕kuò
韖rǒu
韗yùn
韘shè
韙wěi
韚gé
韛bài
韜tāo
韝gōu
韞yùn
韟gāo
韠bì
韡wěi
韢suì
韣dú
韤wà
韥dú
韦wéi
韧rèn
韨fú
韩hán
韪wěi
韫yùn
韬tāo
韭jiǔ
韮jiǔ
韯xiān
韰xiè
韱xiān
韲jī
音yīn
韴zá
韵yùn
韶sháo
韷lè
韸péng
韹huáng
韺yīng
韻yùn
韼péng
韽ān
韾yīn
響xiǎng
頀hù
頁yè
頂dǐng
頃qǐng
頄kuí
項xiàng
順shùn
頇hān
須xū
頉yí
頊xū
頋ě
頌sòng
頍kuǐ
頎qí
頏háng
預yù
頑wán
頒bān
頓dùn
頔dí
頕dān
頖pàn
頗pō
領lǐng
頙chè
頚jǐng
頛lèi
頜hé
頝qiāo
頞è
頟é
頠wěi
頡xié
頢kuò
頣shěn
頤yí
頥yí
頦hái
頧duǐ
頨yǔ
頩pīng
頪lèi
頫fǔ
頬jiá
頭tóu
頮huì
頯kuí
頰jiá
頱luō
頲tǐng
頳chēng
頴yǐng
頵yūn
頶hú
頷hàn
頸jǐng
頹tuí
頺tuí
頻pín
頼lài
頽tuí
頾zī
頿zī
顀chuí
顁dìng
顂lài
顃tán
顄hàn
顅qiān
顆kē
顇cuì
顈xuǎn
顉qīn
顊yí
顋sāi
題tí
額é
顎è
顏yán
顐wèn
顑kǎn
顒yóng
顓zhuān
顔yán
顕xiǎn
顖xìn
顗yǐ
願yuàn
顙sǎng
顚diān
顛diān
顜jiǎng
顝kuī
類lèi
顟láo
顠piǎo
顡wài
顢mán
顣cù
顤yáo
顥hào
顦qiáo
顧gù
顨xùn
顩yǎn
顪huì
顫chàn
顬rú
顭méng
顮bīn
顯xiǎn
顰pín
顱lú
顲lǎn
顳niè
顴quán
页yè
顶dǐng
顷qǐng
顸hān
项xiàng
顺shùn
须xū
顼xū
顽wán
顾gù
顿dùn
颀qí
颁bān
颂sòng
颃háng
预yù
颅lú
领lǐng
颇pǒ
颈jǐng
颉jié
颊jiá
颋tǐng
颌hé
颍yǐng
颎jiǒng
颏kē
颐yí
频pín
颒huì
颓tuí
颔hàn
颕yǐng
颖yǐng
颗kē
题tí
颙yóng
颚è
颛zhuān
颜yán
额é
颞niè
颟mān
颠diān
颡sǎng
颢hào
颣lèi
颤chàn
颥rú
颦pín
颧quán
風fēng
颩biāo
颪guā
颫fú
颬xiā
颭zhǎn
颮biāo
颯sà
颰bá
颱tái
颲liè
颳guā
颴xuàn
颵shāo
颶jù
颷biāo
颸sī
颹wěi
颺yáng
颻yáo
颼sōu
颽kǎi
颾sōu
颿fān
飀liú
飁xí
飂liù
飃piāo
飄piāo
飅liú
飆biāo
飇biāo
飈biāo
飉liáo
飊biāo
飋sè
飌fēng
飍xiū
风fēng
飏yáng
飐zhǎn
飑biāo
飒sà
飓jù
飔sī
飕sōu
飖yáo
飗liú
飘piāo
飙biāo
飚biāo
飛fēi
飜fān
飝fēi
飞fēi
食shí
飠shí
飡cān
飢jī
飣dìng
飤sì
飥tuō
飦zhān
飧sūn
飨xiǎng
飩tún
飪rèn
飫yù
飬juàn
飭chì
飮yǐn
飯fàn
飰fàn
飱sūn
飲yǐn
飳tǒu
飴yí
飵zuò
飶bì
飷jiě
飸tāo
飹bǎo
飺cí
飻tiè
飼sì
飽bǎo
飾shì
飿duò
餀hài
餁rèn
餂tiǎn
餃jiǎo
餄jiá
餅bǐng
餆yáo
餇tóng
餈cí
餉xiǎng
養yǎng
餋juàn
餌ěr
餍yàn
餎le
餏xī
餐cān
餑bō
餒něi
餓è
餔bù
餕jùn
餖dòu
餗sù
餘yú
餙shì
餚yáo
餛hún
餜guǒ
餝shì
餞jiàn
餟zhuì
餠bǐng
餡xiàn
餢bù
餣yè
餤tán
餥fēi
餦zhāng
餧wèi
館guǎn
餩è
餪nuǎn
餫yùn
餬hú
餭huáng
餮tiè
餯huì
餰jiān
餱hóu
餲ài
餳táng
餴fēn
餵wèi
餶gǔ
餷chā
餸sòng
餹táng
餺bó
餻gāo
餼xì
餽kuì
餾liù
餿sōu
饀táo
饁yè
饂wēn
饃mó
饄táng
饅mán
饆bì
饇yù
饈xiū
饉jǐn
饊sǎn
饋kuì
饌zhuàn
饍shàn
饎chì
饏dàn
饐yì
饑jī
饒ráo
饓chēng
饔yōng
饕tāo
饖wèi
饗xiǎng
饘zhān
饙fēn
饚hài
饛méng
饜yàn
饝mó
饞chán
饟xiǎng
饠luó
饡zàn
饢náng
饣shí
饤dìng
饥jī
饦tuō
饧táng
饨tún
饩xì
饪rèn
饫yù
饬chì
饭fàn
饮yǐn
饯jiàn
饰shì
饱bǎo
饲sì
饳duò
饴yí
饵ěr
饶ráo
饷xiǎng
饸hé
饹le
饺jiǎo
饻xī
饼bǐng
饽bō
饾dòu
饿è
馀yú
馁něi
馂jùn
馃guǒ
馄hún
馅xiàn
馆guǎn
馇chā
馈kuì
馉gǔ
馊sōu
馋chán
馌yè
馍mó
馎bó
馏liú
馐xiū
馑jǐn
馒mán
馓sǎn
馔zhuàn
馕náng
首shǒu
馗kuí
馘guó
香xiāng
馚fén
馛bó
馜nǐ
馝bì
馞bó
馟tú
馠hān
馡fēi
馢jiān
馣ān
馤ài
馥fù
馦xiān
馧yūn
馨xīn
馩fén
馪pīn
馫xīn
馬mǎ
馭yù
馮féng
馯hàn
馰dí
馱tuó
馲zhé
馳chí
馴xún
馵zhù
馶zhī
馷pèi
馸xìn
馹rì
馺sà
馻yǔn
馼wén
馽zhí
馾dàn
馿lǘ
駀yóu
駁bó
駂bǎo
駃jué
駄tuó
駅yì
駆qū
駇wén
駈qū
駉jiōng
駊pǒ
駋zhāo
駌yuān
駍péi
駎zhòu
駏jù
駐zhù
駑nú
駒jū
駓pī
駔zǎng
駕jià
駖líng
駗zhěn
駘tái
駙fù
駚yǎng
駛shǐ
駜bì
駝tuó
駞tuó
駟sì
駠liú
駡mà
駢pián
駣táo
駤zhì
駥róng
駦téng
駧dòng
駨xūn
駩quān
駪shēn
駫jiōng
駬ěr
駭hài
駮bó
駯zhū
駰yīn
駱luò
駲zhōu
駳dàn
駴hài
駵liú
駶jú
駷sǒng
駸qīn
駹máng
駺láng
駻hàn
駼tú
駽xuān
駾tuì
駿jùn
騀ě
騁chěng
騂xīng
騃ái
騄lù
騅zhuī
騆zhōu
騇shè
騈pián
騉kūn
騊táo
騋lái
騌zōng
騍kè
騎qí
騏qí
騐yàn
騑fēi
騒sāo
験yàn
騔gé
騕yǎo
騖wù
騗piàn
騘cōng
騙piàn
騚qián
騛fēi
騜huáng
騝qián
騞huō
騟yú
騠tí
騡quán
騢xiá
騣zōng
騤kuí
騥róu
騦sī
騧guā
騨tuó
騩guī
騪sōu
騫qiān
騬chéng
騭zhì
騮liú
騯péng
騰téng
騱xí
騲cǎo
騳dú
騴yàn
騵yuán
騶zōu
騷sāo
騸shàn
騹qí
騺zhì
騻shuāng
騼lù
騽xí
騾luó
騿zhāng
驀mò
驁ào
驂cān
驃biāo
驄cōng
驅qū
驆bì
驇zhì
驈yù
驉xū
驊huá
驋bō
驌sù
驍xiāo
驎lín
驏zhàn
驐dūn
驑liú
驒tuó
驓céng
驔diàn
驕jiāo
驖tiě
驗yàn
驘luó
驙zhān
驚jīng
驛yì
驜yè
驝tuō
驞pīn
驟zhòu
驠yàn
驡lóng
驢lǘ
驣téng
驤xiāng
驥jì
驦shuāng
驧jú
驨xí
驩huān
驪lí
驫biāo
马mǎ
驭yù
驮tuó
驯xùn
驰chí
驱qū
驲rì
驳bó
驴lǘ
驵zǎng
驶shǐ
驷sì
驸fù
驹jū
驺zōu
驻zhù
驼tuó
驽nú
驾jià
驿yì
骀dài
骁xiāo
骂mà
骃yīn
骄jiāo
骅huá
骆luò
骇hài
骈pián
骉biāo
骊lí
骋chěng
验yàn
骍xīng
骎qīn
骏jùn
骐qí
骑qí
骒kè
骓zhuī
骔zōng
骕sù
骖cān
骗piàn
骘zhì
骙kuí
骚sāo
骛wù
骜ào
骝liú
骞qiān
骟shàn
骠biāo
骡luó
骢cōng
骣chǎn
骤zhòu
骥jì
骦shuāng
骧xiāng
骨gǔ
骩wěi
骪wěi
骫wěi
骬yú
骭gàn
骮yì
骯āng
骰tóu
骱jiè
骲bào
骳bèi
骴cī
骵tǐ
骶dǐ
骷kū
骸hái
骹qiāo
骺hóu
骻kuà
骼gé
骽tuǐ
骾gěng
骿pián
髀bì
髁kē
髂qià
髃yú
髄suǐ
髅lóu
髆bó
髇xiāo
髈bǎng
髉bó
髊cī
髋kuān
髌bìn
髍mó
髎liáo
髏lóu
髐xiāo
髑dú
髒zāng
髓suǐ
體tǐ
髕bìn
髖kuān
髗lú
高gāo
髙gāo
髚qiào
髛kāo
髜qiǎo
髝láo
髞sào
髟biāo
髠kūn
髡kūn
髢dí
髣fǎng
髤xiū
髥rán
髦máo
髧dàn
髨kūn
髩bìn
髪fà
髫tiáo
髬pī
髭zī
髮fà
髯rán
髰tì
髱bào
髲bì
髳máo
髴fú
髵ér
髶róng
髷qū
髸gōng
髹xiū
髺kuò
髻jì
髼péng
髽zhuā
髾shāo
髿suō
鬀tì
鬁lì
鬂bìn
鬃zōng
鬄dí
鬅péng
鬆sōng
鬇zhēng
鬈quán
鬉zōng
鬊shùn
鬋jiǎn
鬌tuǒ
鬍hú
鬎là
鬏jiū
鬐qí
鬑lián
鬒zhěn
鬓bìn
鬔péng
鬕mà
鬖sān
鬗mán
鬘mán
鬙sēng
鬚xū
鬛liè
鬜qiān
鬝qiān
鬞náng
鬟huán
鬠kuò
鬡níng
鬢bìn
鬣liè
鬤ráng
鬥dòu
鬦dòu
鬧nào
鬨hòng
鬩xì
鬪dòu
鬫hǎn
鬬dòu
鬭dòu
鬮jiū
鬯chàng
鬰yù
鬱yù
鬲gé
鬳yàn
鬴fǔ
鬵qín
鬶guī
鬷zōng
鬸liù
鬹guī
鬺shāng
鬻yù
鬼guǐ
鬽mèi
鬾jì
鬿qí
魀gà
魁kuí
魂hún
魃bá
魄pò
魅mèi
魆xū
魇yǎn
魈xiāo
魉liǎng
魊yù
魋tuí
魌qī
魍wǎng
魎liǎng
魏wèi
魐gān
魑chī
魒piāo
魓bì
魔mó
魕jǐ
魖xū
魗chǒu
魘yǎn
魙zhān
魚yú
魛dāo
魜rén
魝jié
魞bā
魟hóng
魠tuō
魡diào
魢jǐ
魣xù
魤é
魥è
魦shā
魧háng
魨tún
魩mò
魪jiè
魫shěn
魬bǎn
魭yuán
魮pí
魯lǔ
魰wén
魱hú
魲lú
魳zā
魴fáng
魵fén
魶nà
魷yóu
魸piàn
魹mó
魺hé
魻xiá
魼qū
魽hán
魾pī
魿líng
鮀tuó
鮁bō
鮂qiú
鮃píng
鮄fú
鮅bì
鮆cǐ
鮇wèi
鮈jū
鮉diāo
鮊bà
鮋yóu
鮌gǔn
鮍pī
鮎nián
鮏xīng
鮐tái
鮑bào
鮒fù
鮓zhǎ
鮔jù
鮕gū
鮖shí
鮗dōng
鮘dai
鮙tà
鮚jié
鮛shū
鮜hòu
鮝xiǎng
鮞ér
鮟àn
鮠wéi
鮡zhào
鮢zhū
鮣yìn
鮤liè
鮥luò
鮦tóng
鮧tǐ
鮨yì
鮩bìng
鮪wěi
鮫jiāo
鮬kū
鮭guī
鮮xiān
鮯gé
鮰huí
鮱lǎo
鮲fú
鮳kào
鮴xiū
鮵duó
鮶jūn
鮷tí
鮸miǎn
鮹shāo
鮺zhǎ
鮻suō
鮼qīn
鮽yú
鮾něi
鮿zhé
鯀gǔn
鯁gěng
鯂sū
鯃wú
鯄qiú
鯅shān
鯆pū
鯇huàn
鯈tiáo
鯉lǐ
鯊shā
鯋shā
鯌kào
鯍méng
鯎chéng
鯏lí
鯐zǒu
鯑xī
鯒yǒng
鯓shēn
鯔zī
鯕qí
鯖zhēng
鯗xiǎng
鯘něi
鯙chún
鯚jì
鯛diāo
鯜qiè
鯝gù
鯞zhǒu
鯟dōng
鯠lái
鯡fèi
鯢ní
鯣yì
鯤kūn
鯥lù
鯦jiù
鯧chāng
鯨jīng
鯩lún
鯪líng
鯫zōu
鯬lí
鯭měng
鯮zōng
鯯zhì
鯰nián
鯱hǔ
鯲yú
鯳dǐ
鯴shī
鯵shēn
鯶huàn
鯷tí
鯸hóu
鯹xīng
鯺zhū
鯻là
鯼zōng
鯽zéi
鯾biān
鯿biān
鰀huàn
鰁quán
鰂zéi
鰃wēi
鰄wēi
鰅yú
鰆chūn
鰇róu
鰈dié
鰉huáng
鰊liàn
鰋yǎn
鰌qiū
鰍qiū
鰎jiǎn
鰏bī
鰐è
鰑yáng
鰒fù
鰓sāi
鰔gǎn
鰕xiā
鰖tuǒ
鰗hú
鰘shì
鰙ruò
鰚xuān
鰛wēn
鰜qiàn
鰝hào
鰞wū
鰟fáng
鰠sāo
鰡liú
鰢mǎ
鰣shí
鰤shī
鰥guān
鰦zī
鰧téng
鰨tǎ
鰩yáo
鰪é
鰫yóng
鰬qián
鰭qí
鰮wēn
鰯ruò
鰰shén
鰱lián
鰲áo
鰳lè
鰴huī
鰵mǐn
鰶jì
鰷tiáo
鰸qū
鰹jiān
鰺shēn
鰻mán
鰼xí
鰽qiú
鰾biào
鰿jì
鱀jì
鱁zhú
鱂jiāng
鱃xiū
鱄zhuān
鱅yōng
鱆zhāng
鱇kāng
鱈xuě
鱉biē
鱊yù
鱋qū
鱌xiàng
鱍bō
鱎jiǎo
鱏xún
鱐sù
鱑huáng
鱒zūn
鱓shàn
鱔shàn
鱕fān
鱖guì
鱗lín
鱘xún
鱙miáo
鱚xǐ
鱛zēng
鱜xiāng
鱝fèn
鱞guān
鱟hòu
鱠kuài
鱡zéi
鱢sāo
鱣zhān
鱤gǎn
鱥guì
鱦yìng
鱧lǐ
鱨cháng
鱩léi
鱪shǔ
鱫ài
鱬rú
鱭jì
鱮xù
鱯hù
鱰shǔ
鱱lì
鱲liè
鱳lì
鱴miè
鱵zhēn
鱶xiǎng
鱷è
鱸lú
鱹guàn
鱺lí
鱻xiān
鱼yú
鱽dāo
鱾jǐ
鱿yóu
鲀tún
鲁lǔ
鲂fáng
鲃bā
鲄hé
鲅bà
鲆píng
鲇nián
鲈lú
鲉yóu
鲊zhǎ
鲋fù
鲌bà
鲍bào
鲎hòu
鲏pí
鲐tái
鲑guī
鲒jié
鲓kào
鲔wěi
鲕ér
鲖tóng
鲗zéi
鲘hòu
鲙kuài
鲚jì
鲛jiāo
鲜xiān
鲝zhǎ
鲞xiǎng
鲟xún
鲠gěng
鲡lí
鲢lián
鲣jiān
鲤lǐ
鲥shí
鲦tiáo
鲧gǔn
鲨shā
鲩huàn
鲪jūn
鲫jì
鲬yǒng
鲭qīng
鲮líng
鲯qí
鲰zōu
鲱fēi
鲲kūn
鲳chāng
鲴gù
鲵ní
鲶nián
鲷diāo
鲸jīng
鲹shēn
鲺shī
鲻zī
鲼fèn
鲽dié
鲾bī
鲿cháng
鳀tí
鳁wēn
鳂wēi
鳃sāi
鳄è
鳅qiū
鳆fù
鳇huáng
鳈quán
鳉jiāng
鳊biān
鳋sāo
鳌áo
鳍qí
鳎tǎ
鳏guān
鳐yáo
鳑páng
鳒jiān
鳓lè
鳔biào
鳕xuě
鳖biē
鳗mán
鳘mǐn
鳙yōng
鳚wèi
鳛xí
鳜guì
鳝shàn
鳞lín
鳟zūn
鳠hù
鳡gǎn
鳢lǐ
鳣zhān
鳤guǎn
鳥niǎo
鳦yǐ
鳧fú
鳨lì
鳩jiū
鳪bú
鳫yàn
鳬fǔ
鳭diāo
鳮jī
鳯fèng
鳰rù
鳱gān
鳲shī
鳳fèng
鳴míng
鳵bǎo
鳶yuān
鳷zhī
鳸hù
鳹qín
鳺fū
鳻bān
鳼wén
鳽jiān
鳾shī
鳿yù
鴀fǒu
鴁yāo
鴂jué
鴃jué
鴄pǐ
鴅huān
鴆zhèn
鴇bǎo
鴈yàn
鴉yā
鴊zhèng
鴋fāng
鴌fèng
鴍wén
鴎ōu
鴏dài
鴐gē
鴑rú
鴒líng
鴓miè
鴔fú
鴕tuó
鴖mín
鴗lì
鴘biǎn
鴙zhì
鴚gē
鴛yuān
鴜cí
鴝qú
鴞xiāo
鴟chī
鴠dàn
鴡jū
鴢yǎo
鴣gū
鴤zhōng
鴥yù
鴦yāng
鴧yù
鴨yā
鴩tiě
鴪yù
鴫tián
鴬yīng
鴭duī
鴮wū
鴯ér
鴰guā
鴱ài
鴲zhī
鴳yàn
鴴héng
鴵xiāo
鴶jiá
鴷liè
鴸zhū
鴹yáng
鴺tí
鴻hóng
鴼luò
鴽rú
鴾móu
鴿gē
鵀rén
鵁jiāo
鵂xiū
鵃zhōu
鵄chī
鵅luò
鵆héng
鵇nián
鵈ě
鵉luán
鵊jiá
鵋jì
鵌tú
鵍huān
鵎tuǒ
鵏bǔ
鵐wú
鵑juān
鵒yù
鵓bó
鵔jùn
鵕jùn
鵖bī
鵗xī
鵘jùn
鵙jú
鵚tū
鵛jīng
鵜tí
鵝é
鵞é
鵟kuáng
鵠hú
鵡wǔ
鵢shēn
鵣lài
鵤jiao
鵥pàn
鵦lù
鵧pí
鵨shū
鵩fú
鵪ān
鵫zhuó
鵬péng
鵭qín
鵮qiān
鵯bēi
鵰diāo
鵱lù
鵲què
鵳jiān
鵴jú
鵵tù
鵶yā
鵷yuān
鵸qí
鵹lí
鵺yè
鵻zhuī
鵼kōng
鵽duò
鵾kūn
鵿shēng
鶀qí
鶁jīng
鶂yì
鶃yì
鶄jīng
鶅zī
鶆lái
鶇dōng
鶈qī
鶉chún
鶊gēng
鶋jū
鶌jué
鶍yì
鶎zūn
鶏jī
鶐shù
鶑yīng
鶒chì
鶓miáo
鶔róu
鶕ān
鶖qiū
鶗tí
鶘hú
鶙tí
鶚è
鶛jiē
鶜máo
鶝fú
鶞chūn
鶟tú
鶠yǎn
鶡hé
鶢yuán
鶣piān
鶤kūn
鶥méi
鶦hú
鶧yīng
鶨chuàn
鶩wù
鶪jú
鶫dōng
鶬cāng
鶭fǎng
鶮hè
鶯yīng
鶰yuán
鶱xiān
鶲wēng
鶳shī
鶴hè
鶵chú
鶶táng
鶷xiá
鶸ruò
鶹liú
鶺jí
鶻gú
鶼jiān
鶽sǔn
鶾hàn
鶿cí
鷀cí
鷁yì
鷂yào
鷃yàn
鷄jī
鷅lì
鷆tián
鷇kòu
鷈tī
鷉tī
鷊yì
鷋tú
鷌mǎ
鷍xiāo
鷎gāo
鷏tián
鷐chén
鷑jí
鷒tuán
鷓zhè
鷔áo
鷕yǎo
鷖yī
鷗ōu
鷘chì
鷙zhì
鷚liù
鷛yōng
鷜lǘ
鷝bì
鷞shuāng
鷟zhuó
鷠yú
鷡wú
鷢jué
鷣yín
鷤tí
鷥sī
鷦jiāo
鷧yì
鷨huá
鷩bì
鷪yīng
鷫sù
鷬huáng
鷭fán
鷮jiāo
鷯liáo
鷰yàn
鷱gāo
鷲jiù
鷳xián
鷴xián
鷵tú
鷶mǎi
鷷zūn
鷸yù
鷹yīng
鷺lù
鷻tuán
鷼xián
鷽xué
鷾yì
鷿pì
鸀chǔ
鸁luó
鸂xī
鸃yí
鸄jī
鸅zé
鸆yú
鸇zhān
鸈yè
鸉yáng
鸊pì
鸋níng
鸌hù
鸍mí
鸎yīng
鸏méng
鸐dí
鸑yuè
鸒yù
鸓lěi
鸔bǔ
鸕lú
鸖hè
鸗lóng
鸘shuāng
鸙yuè
鸚yīng
鸛guàn
鸜qú
鸝lí
鸞luán
鸟niǎo
鸠jiū
鸡jī
鸢yuān
鸣míng
鸤shī
鸥ōu
鸦yā
鸧cāng
鸨bǎo
鸩zhèn
鸪gū
鸫dōng
鸬lú
鸭yā
鸮xiāo
鸯yāng
鸰líng
鸱chī
鸲qú
鸳yuān
鸴xué
鸵tuó
鸶sī
鸷zhì
鸸ér
鸹guā
鸺xiū
鸻héng
鸼zhōu
鸽gē
鸾luán
鸿hóng
鹀wú
鹁bó
鹂lí
鹃juān
鹄gǔ
鹅é
鹆yù
鹇xián
鹈tí
鹉wǔ
鹊què
鹋miáo
鹌ān
鹍kūn
鹎bēi
鹏péng
鹐qiān
鹑chún
鹒gēng
鹓yuān
鹔sù
鹕hú
鹖hé
鹗è
鹘gǔ
鹙qiū
鹚cí
鹛méi
鹜wù
鹝yì
鹞yào
鹟wēng
鹠liú
鹡jí
鹢yì
鹣jiān
鹤hè
鹥yī
鹦yīng
鹧zhè
鹨liù
鹩liáo
鹪jiāo
鹫jiù
鹬yù
鹭lù
鹮huán
鹯zhān
鹰yīng
鹱hù
鹲méng
鹳guàn
鹴shuāng
鹵lǔ
鹶jīn
鹷líng
鹸jiǎn
鹹xián
鹺cuó
鹻jiǎn
鹼jiǎn
鹽yán
鹾cuó
鹿lù
麀yōu
麁cū
麂jǐ
麃páo
麄cū
麅páo
麆zhù
麇jūn
麈zhǔ
麉jiān
麊mí
麋mí
麌yǔ
麍liú
麎chén
麏jūn
麐lín
麑ní
麒qí
麓lù
麔jiù
麕jūn
麖jīng
麗lì
麘xiāng
麙xián
麚jiā
麛mí
麜lì
麝shè
麞zhāng
麟lín
麠jīng
麡qí
麢líng
麣yán
麤cū
麥mài
麦mài
麧hé
麨chǎo
麩fū
麪miàn
麫miàn
麬fū
麭pào
麮qù
麯qū
麰móu
麱fū
麲xiàn
麳lái
麴qū
麵miàn
麶chi
麷fēng
麸fū
麹qū
麺miàn
麻má
麼me
麽mó
麾huī
麿mí
黀zōu
黁nún
黂fén
黃huáng
黄huáng
黅jīn
黆guāng
黇tiān
黈tǒu
黉hóng
黊huà
黋kuàng
黌hóng
黍shǔ
黎lí
黏nián
黐chī
黑hēi
黒hēi
黓yì
黔qián
黕dǎn
黖xì
黗tūn
默mò
黙mò
黚qián
黛dài
黜chù
黝yǒu
點diǎn
黟yī
黠xiá
黡yǎn
黢qū
黣měi
黤yǎn
黥qíng
黦yuè
黧lí
黨dǎng
黩dú
黪cǎn
黫yān
黬yán
黭yǎn
黮dǎn
黯àn
黰zhěn
黱dài
黲cǎn
黳yī
黴méi
黵zhǎn
黶yǎn
黷dú
黸lú
黹zhǐ
黺fěn
黻fú
黼fǔ
黽miǎn
黾mǐn
黿yuán
鼀cù
鼁qù
鼂cháo
鼃wā
鼄zhū
鼅zhī
鼆méng
鼇áo
鼈biē
鼉tuó
鼊bì
鼋yuán
鼌cháo
鼍tuó
鼎dǐng
鼏mì
鼐nài
鼑dǐng
鼒zī
鼓gǔ
鼔gǔ
鼕dōng
鼖fén
鼗táo
鼘yuān
鼙pí
鼚chāng
鼛gāo
鼜qì
鼝yuān
鼞tāng
鼟tēng
鼠shǔ
鼡shǔ
鼢fén
鼣fèi
鼤wén
鼥bá
鼦diāo
鼧tuó
鼨zhōng
鼩qú
鼪shēng
鼫shí
鼬yòu
鼭shí
鼮tíng
鼯wú
鼰jú
鼱jīng
鼲hún
鼳jú
鼴yǎn
鼵tū
鼶sī
鼷xī
鼸xiàn
鼹yǎn
鼺léi
鼻bí
鼼yào
鼽qiú
鼾hān
鼿wù
齀wù
齁hōu
齂xiè
齃è
齄zhā
齅xiù
齆wèng
齇zhā
齈nòng
齉nàng
齊qí
齋zhāi
齌jì
齍zī
齎jī
齏jī
齐qí
齑jī
齒chǐ
齓chèn
齔chèn
齕hé
齖yá
齗yín
齘xiè
齙bāo
齚zé
齛xiè
齜chái
齝chī
齞yǎn
齟jǔ
齠tiáo
齡líng
齢líng
齣chū
齤quán
齥xiè
齦kěn
齧niè
齨jiù
齩yǎo
齪chuò
齫yǔn
齬yǔ
齭chǔ
齮yǐ
齯ní
齰zé
齱zōu
齲qǔ
齳yǔn
齴yǎn
齵óu
齶è
齷wò
齸yì
齹cī
齺zōu
齻diān
齼chǔ
齽jìn
齾yà
齿chǐ
龀chèn
龁hé
龂yín
龃jǔ
龄líng
龅bāo
龆tiáo
龇zī
龈kěn
龉yǔ
龊chuò
龋qǔ
龌wò
龍lóng
龎páng
龏gōng
龐páng
龑yǎn
龒lóng
龓lǒng
龔gōng
龕kān
龖dá
龗líng
龘dá
龙lóng
龚gōng
龛kān
龜guī
龝qiū
龞biē
龟guī
龠yuè
龡chuī
龢hé
龣jué
龤xié
龥yù
鿃shǎn
鿍gàng
鿎tǎ
鿏mài
鿔gē
鿕dān
鿫ào
鿬tián
鿭nǐ
`
